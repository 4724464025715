<template>
  <div class="search-div">
    <div class="search-item search-text">
      <autocomplete :search="searchTarget"
                    :placeholder=targetName
                    :aria-label=targetName
                    :get-result-value="getResultValue"
                    @submit="handleSubmit"
                    :debounce-time="200"
                    auto-select
                    ref="autocomplete"
      >
        <template #result="{ result, props }" v-if="searchType === 'USER'">
          <li v-bind="props" class="autocomplete-result">
            <div>{{ result.userName + '/ ID : ' + result.userId }} </div>
          </li>
        </template>
      </autocomplete>
    </div>
    <div class="search-item search-type">
      <b-form-select
        v-model="searchData.category"
        :options="getSearchOptions()"
        id="search-type-select"
        style="height: 100%;"
      ></b-form-select>
    </div>

    <div class="search-item search-button">
      <b-button fill variant="primary" class="btn" @click="searchAction">검색</b-button>
    </div>
    <div class="search-item init-button" v-if="initButton">
      <b-button fill variant="primary" class="btn" @click="initData">초기화</b-button>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'

export default {
  props: {
    searchType: {
      type: String,
      required: false,
      default: 'USER',
    },
    initButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showDefaultValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Autocomplete
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      message: '',
      targetName: this.getTargetName(),
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      menuProps: {
        maxHeight: 200,
        offsetY: false,
        offsetOverflow: false,
        transition: true
      },

      searchData: {
        value: null,
        category: 'USERNAME',
        size: 0,
        search: null,
      },
    }
  },
  computed: {
  },
  methods: {
    init() {
      if (this.showDefaultValue) {
        const searchUser = this.$store.getters['User/searchUserState'];
        if (searchUser.name) {
          this.$refs.autocomplete.value = searchUser.name;
        }
      }

      if (this.searchType === 'USER') {
        this.searchData.category = 'USERNAME';
      } else if (this.searchType === 'ADMIN') {
        this.searchData.category = 'ACCOUNT_NAME';
      } else if (this.searchType === 'CUSTOMER') {
        this.searchData.category = 'CUSTOMER_NAME';
      } else if (this.searchType === 'DEVICE') {
        this.searchData.category = 'DEVICE_NAME';
      } else if (this.searchType === 'CONTENTS') {
        this.searchData.category = 'CONTENTS_NAME';
      }
    },
    getTargetName() {
      if (this.searchType === 'USER') {
        return '사용자 검색';
      } else if (this.searchType === 'ADMIN') {
        return '관리자 검색';
      } else if (this.searchType === 'CUSTOMER') {
        return '고객사 검색';
      } else if (this.searchType === 'DEVICE') {
        return '디바이스 검색';
      } else if (this.searchType === 'CONTENTS') {
        return '컨텐츠 검색';
      }
    },
    searchTarget(input) {
      const data = input.toString().replaceAll('\/', '').replaceAll('\\', '').replaceAll('%', '');
      if (data.length < 1) return [];

      if (this.isLoading) return;
      this.isLoading = true;

      if (this.searchType === 'USER') {
        return this.searchUser(data);
      } else if (this.searchType === 'ADMIN') {
        return this.searchAdmin(data);
      } else if (this.searchType === 'CUSTOMER') {
        return this.searchCustomer(data);
      } else if (this.searchType === 'DEVICE') {
        return this.searchDevice(data);
      } else if (this.searchType === 'CONTENTS') {
        return this.searchContents(data);
      }
    },
    getSearchOptions() {
      if (this.searchType === 'USER') {
        return [{ text: '이름', value: 'USERNAME'}, { text: '아이디', value: 'ID' }];
      } else if (this.searchType === 'ADMIN') {
        return [{ text: '이름', value: 'ACCOUNT_NAME'}, { text: '아이디', value: 'ID' }];
      } else if (this.searchType === 'CUSTOMER') {
        return [{ text: '고객사명', value: 'CUSTOMER_NAME'}];
      } else if (this.searchType === 'DEVICE') {
        return [{ text: '디바이스 이름', value: 'DEVICE_NAME'}, { text: 'UUID', value: 'DEVICE_UUID' }];
      } else if (this.searchType === 'CONTENTS') {
        return [{ text: '이름', value: 'CONTENTS_NAME'}];
      }
    },

    searchAdmin(value) {
      let category = this.searchData.category;
      return new Promise((resolve, reject) => {
        this.$axios.get(`/admin/search/like/${value}/${category}`)
        .then(res => {
          this.count = res.data.total;
          this.entries = res.data.adminList;
          resolve(this.entries);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => (this.isLoading = false));
      })
    },
    searchUser(value) {
      let category = this.searchData.category;
      return new Promise((resolve, reject) => {
        this.$axios.get(`/user/search/like/${value}/${category}`)
          .then(res => {
            // const { count, entries } = res.data;
            this.count = res.data.total;
            this.entries = res.data.users;
            resolve(this.entries);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => (this.isLoading = false));
      })
    },

    searchCustomer(value) {
      return new Promise((resolve, reject) => {
        this.$axios.get(`/customer/like/name/${value}`)
          .then(res => {
            // const { count, entries } = res.data;
            this.count = res.data.total;
            this.entries = res.data.customers;
            resolve(this.entries);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => (this.isLoading = false));
      })
    },
    searchDevice(value) {
      let category = this.searchData.category;
      return new Promise((resolve, reject) => {
        this.$axios.get(`/device/like/name/${value}/${category}`)
          .then(res => {
            // const { count, entries } = res.data;
            this.count = res.data.total;
            this.entries = res.data.machines;
            resolve(this.entries);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          })
          .finally(() => (this.isLoading = false));
      })
    },
    searchContents(value) {
      let category = this.searchData.category;

      return new Promise((resolve, reject) => {
        this.$axios.get(`/contents/search/like/${value}/${category}`)
        .then(res => {
          this.count = res.data.total;
          this.entries = res.data.contentsList;
          resolve(this.entries);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        })
        .finally(() => this.isLoading = false);
      });
    },

    getResultValue(value) {
      if (this.searchType === 'USER') {
        if (this.searchData.category === 'USERNAME') {
          return value.userName;
        } else if (this.searchData.category === 'ID') {
          return value.userId;
        }
      } else if (this.searchType === 'ADMIN') {
        if (this.searchData.category === 'ACCOUNT_NAME') {
          return value.accountName;
        } else if (this.searchData.category === 'ID') {
          return value.accountId;
        }
      } else if (this.searchType === 'CUSTOMER') {
        return value.customerName;
      } else if (this.searchType === 'DEVICE') {
        if (this.searchData.category === 'DEVICE_NAME') {
          return value.deviceName;
        } else if (this.searchData.category === 'DEVICE_UUID') {
          return value.deviceUuid;
        }
      } else if (this.searchType === 'CONTENTS') {
        return value.contentsName;
      }
    },
    handleSubmit(result) {
      const searchValue = this.$refs.autocomplete.value;
      this.searchData.value = result;
      this.searchData.size = 1;
      this.searchData.search = searchValue;
      this.$emit('selectedItem', this.searchData);
    },
    searchAction(e) {
      e.preventDefault();
      if (this.isLoading) {
        return false;
      }

      const searchValue = this.$refs.autocomplete.value;
      if (searchValue) {
        this.searchTarget(searchValue)
        .then(result => {
          if (result.length > 0) {
            if (result.length === 1) {
              if (this.searchType === 'USER') {
                if (this.searchData.category === 'USERNAME') {
                  this.$refs.autocomplete.value = result[0].userName;
                } else if (this.searchData.category === 'ID') {
                  this.$refs.autocomplete.value = result[0].userId;
                }
              }

              this.searchData.value = result[0];
              this.searchData.search = searchValue;
              this.searchData.size = result.length;
              this.$emit('selectedItem', this.searchData);
            } else {
              this.searchData.size = result.length;
              this.searchData.search = searchValue;
              this.$emit('selectedItem', this.searchData);
            }
          } else {
            this.searchData.size = 0;
            this.searchData.search = searchValue;
            this.$emit('selectedItem', this.searchData);
          }
        })
        .catch(error => {
          console.log('검색 오류', error);
        });
      }
    },
    initData() {
      this.searchData.value = null;
      this.searchData.size = 0;
      this.searchData.search = null;
      this.$refs.autocomplete.value = '';
      this.$emit('initSearch');
    }
  }
}
</script>

<style scoped lang="scss">
.search-div {
  @media (max-width: 1399px) {

  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.search-item {
  flex-basis: 70px;
}

.search-text {
  margin-right: 20px;
  flex-grow: 10;
}

.search-type {
  margin-right: 20px;
  flex-grow: 3;
  height: 50px;
}

.search-button {
  margin-right: 5px;

  .btn {
    width: 70px;
    height: 50px;
  }
}

.error-msg {
  flex-grow: 3;
}

.init-button {

  .btn {
    width: 70px;
    height: 50px;
  }
}
</style>
