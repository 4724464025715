<template>
  <div>
    <div id="show-overlay"></div>
    <!--<Loader />-->
    <div class="wrapper">
      <!-- Sidebar  -->
      <SaSideBar :items="verticalMenu" :horizontal="horizontal" :logo="logo" @toggle="sidebarMini" />
      <div id="content-page" class="content-page" :class="horizontal ? 'ml-0' : ''">
        <!-- TOP Nav Bar -->
        <NavBarStyle1 title="Dashboard" :homeURL="{ name: 'raonfit.super.dashboard' }" @toggle="sidebarMini" :logo="logo" :horizontal="horizontal">
          <template slot="responsiveRight">
            <ul class="navbar-nav ml-auto navbar-list">
              <li class="nav-item iq-full-screen">
                <a href="#" class="iq-waves-effect" id="btnFullscreen"><i class="ri-fullscreen-line"></i></a>
              </li>
            </ul>
          </template>
          <template slot="right">
            <ul class="navbar-list">
              <li>
                <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                  <img :src="userProfile" class="img-fluid rounded mr-3" alt="user">
                  <div class="caption">
                    <h6 class="mb-0 line-height">{{adminInfo.accountName}}</h6>
                    <span class="font-size-12">{{ $t('nav.user.available') }}</span>
                  </div>
                </a>
                <div class="iq-sub-dropdown iq-dropdown">
                  <div class="iq-card shadow-none m-0">
                    <div class="iq-card-body p-0 ">
                      <div class="bg-primary p-3">
                        <h5 class="mb-0 text-white line-height">{{adminInfo.accountName}}님 안녕하세요</h5>
                        <span class="text-white font-size-12">{{ $t('nav.user.available') }}</span>
                      </div>
                      <a href="#" class="iq-sub-card iq-bg-primary-success-hover" @click="goToEditProfile()">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-success">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">프로필 수정</h6>
                            <p class="mb-0 font-size-12">계정의 프로필을 수정합니다</p>
                          </div>
                        </div>
                      </a>
<!--                      <a href="#" class="iq-sub-card iq-bg-primary-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-primary">
                            <i class="ri-file-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">관리자 프로필</h6>
                            <p class="mb-0 font-size-12">관리자의 프로필 상세를 확인합니다</p>
                          </div>
                        </div>
                      </a>
                      <a href="#" class="iq-sub-card iq-bg-primary-success-hover">
                        <div class="media align-items-center">
                          <div class="rounded iq-card-icon iq-bg-success">
                            <i class="ri-profile-line"></i>
                          </div>
                          <div class="media-body ml-3">
                            <h6 class="mb-0 ">관리자 프로필 편집</h6>
                            <p class="mb-0 font-size-12">담당자의 프로필을 편집합니다</p>
                          </div>
                        </div>
                      </a>-->
                      <div class="d-inline-block w-100 text-center p-3">
                        <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">로그아웃<i class="ri-login-box-line ml-2"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </template>
        </NavBarStyle1>
        <!-- TOP Nav Bar END -->
        <div style="height: 1vh; width: 1vw;"></div>
        <transition name="router-anim" :enter-active-class="`animated faster ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated faster ${animated.exit}`">
          <router-view/>
        </transition>
        <FooterStyle1>
          <template v-slot:left>
            <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
            <li class="list-inline-item"><a href="#">Terms of Use</a></li>
          </template>
          <template v-slot:right>
            Copyright 2021 <a href="http://www.mobinus.co.kr/">Mobinus</a> All Rights Reserved.
          </template>
        </FooterStyle1>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from '../components/xray/loader/Loader'
import SaSideBar from '@/components/xray/sidebars/SaSideBar'
import NavBarStyle1 from '../components/xray/navbars/NavBarStyle1'
import SideBarItems from '@/router/SaSideBar'
import profile from '../assets/images/user/1.jpg'
import loader from '../assets/images/mobinus_logo_1.png'
import { xray } from '../config/pluginInit'
import { Users } from '../FackApi/api/chat'
import { mapGetters, mapActions } from 'vuex'
import { commons } from '@/Utils/Commons'

export default {
  name: 'SuperadminLayout',
  components: {
    Loader,
    SaSideBar,
    NavBarStyle1
  },
  created() {
    const adminInfo = this.$store.getters['Auth/AdminState'];

    if (adminInfo) {
      this.adminInfo = adminInfo;
    } else {
      const authToken = window.sessionStorage.getItem('Authorization');

      if (authToken) {
        this.adminInfo = commons.parseAuthTokenToAdminInfo(authToken);
        this.$store.dispatch('Auth/setAdminInfoAction', this.adminInfo);
      } else {
        this.$router.push({ name: 'raonfit.login' });
      }
    }

    if (this.adminInfo.role === 'ADMIN' || this.adminInfo.role === 'NORMAL') {
      this.$router.push({ name: 'raonfit.dashboard' });
    }
  },
  mounted () {
    // this.sidebarMini()
    this.updateRadio();
  },
  computed: {
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState',
    })
  },
  watch: {
  },
  // sidebarTicket
  data () {
    return {
      adminInfo: {},
      horizontal: false,
      mini: false,
      darkMode: false,
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      verticalMenu: SideBarItems,
      userProfile: profile,
      logo: loader,
      usersList: Users,
      rtl: false,
    }
  },
  methods: {
    updateRadio () {
      this.horizontal = this.$store.getters['Setting/horizontalMenuState']
      this.mini = this.$store.getters['Setting/miniSidebarState']
    },
    sidebarHorizontal () {
      this.$store.dispatch('Setting/horizontalMenuAction')
      this.updateRadio()
    },
    sidebarMini () {
      xray.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
      this.updateRadio()
    },
    reset () {
      this.changeColor({ primary: '#827af3', primaryLight: '#b47af3', bodyBgLight: '#efeefd', bodyBgDark: '#1d203f' })
      this.animated = { enter: 'zoomIn', exit: 'zoomOut' }
      this.light()
    },
    goToEditProfile() {
      this.$router.push(`/sa/myProfile/${this.adminInfo.accountId}`)
    },
    logout () {
      localStorage.removeItem('Authorization');
      this.$store.dispatch('Auth/removeAdminInfoAction');
      this.$router.push({ name: 'raonfit.login' })
    },
    ...mapActions({
    })
  }
}
</script>
<style>
@import url("../assets/css/custom.css");
</style>
