import axios from 'axios'

const domain = ""

axios.defaults.headers.common.Accept = 'application/json';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Content-Type'] = 'application/json';

if (process.env.VUE_APP_BASE_DOMAIN === undefined) {
  axios.defaults.baseURL = 'http://localhost:9000/api';
} else {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_DOMAIN + '/api';
}

export default axios.create({
  domain,
  // baseURL: '/v1',
  // You can add your headers here
})
