<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">관리자 {{adminInfo.newAdmin ? '추가' : '수정'}}</h4>
      </template>
      <template v-slot:body>
        <div class="form-box">
          <b-form>
            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="adminIdValid" class="detail-form-label">계정 아이디</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input v-if="adminInfo.newAdmin" id="adminIdValid" v-model.trim="adminInfo.accountId" :state="accountIdValidation" required></b-form-input>
                  <b-form-input v-else id="adminIdValid" v-model.trim="adminInfo.accountId" :state="accountIdValidation" required readonly></b-form-input>
                  <b-form-invalid-feedback id="adminIdValid" :state="accountIdValidation">아이디는 영문자로 4자 ~ 20자 이내여야 합니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="adminIdValid" :state="accountIdValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="nameValid" class="detail-form-label">담당자 이름</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="nameValid" v-model.trim="adminInfo.accountName" :state="nameValidation" required></b-form-input>
                  <b-form-invalid-feedback id="nameValid" :state="nameValidation">이름은 2글자 이상이어야 합니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="nameValid" :state="nameValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="emailValid" class="detail-form-label">이메일</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="emailValid" v-model.trim="adminInfo.email" type="email" :state="emailValidation" required></b-form-input>
                  <b-form-invalid-feedback id="emailValid" :state="emailValidation">이메일 형식이 올바르지 않습니다.</b-form-invalid-feedback>
                  <b-form-valid-feedback id="emailValid" :state="emailValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2" v-if="getAdminRole() === 'SUPER'">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">소속 고객사</label>
                  </div>
                </b-col>
                <b-col lg="2" v-if="getAdminRole() === 'SUPER'">
                  <b-form-select
                    v-model="adminInfo.customerId"
                    :options="customerList"
                    id="account-type-select"
                    :disabled="adminInfo.accountType === 'SUPER'"
                  ></b-form-select>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">계정 타입</label>
                  </div>
                </b-col>
                <b-col lg="2">
                  <b-form-select
                    v-model="adminInfo.accountType"
                    :options="getAccountTypeByRole()"
                    @change="changeAccountType"
                    id="account-type-select"
                  ></b-form-select>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">계정 상태</label>
                  </div>
                </b-col>
                <b-col lg="2">
                  <b-form-select
                    v-model="adminInfo.accountStatus"
                    :options="accountStatusOptions"
                    id="account-status-select"
                  ></b-form-select>
                </b-col>
              </b-row>
            </b-form-group>

            <hr>

            <div style="display: flex; justify-content: flex-end;">
              <b-button variant="primary" class="button-style" @click="upsertAdmin()">저장</b-button>
              <b-button variant="none" class="iq-bg-danger ml-3 button-style" @click="backToPage()">취소</b-button>
            </div>
          </b-form>
        </div>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import _ from "lodash";
import Swal from "sweetalert2";
import {commons} from "@/Utils/Commons";

export default {
  name: "AdminDetail",
  created() {
    if (this.$route.params && this.$route.params.id) {
      const params = this.$route.params;
      this.adminInfo = {newAdmin: false, ...params};
      console.log('AdminDetail created -> ', this.adminInfo);
    }
  },
  mounted() {
    if (this.getAdminRole() === 'SUPER') {
      this.getCustomerList();
    } else if(this.getAdminRole() === 'ADMIN') {
      const adminState = this.$store.getters['Auth/adminState'];
      this.adminInfo.customerId = adminState.customer;
    }
  },
  computed: {
    accountIdValidation() {
      if (this.adminInfo.accountId) {
        const accountIdReg = new RegExp(/^([A-Za-z0-9]){4,20}$/gm);
        return !!this.adminInfo.accountId.match(accountIdReg);
      } else {
        return false;
      }
    },
    nameValidation() {
      if (this.adminInfo.accountName) {
        return this.adminInfo.accountName.length >= 2 && this.adminInfo.accountName.length <= 20;
      } else {
        return false;
      }
    },
    emailValidation() {
      if (this.adminInfo.email) {
        const email = this.adminInfo.email;
        const emailReg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
        return !!email.match(emailReg);
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      adminInfo: {
        newAdmin: true,
        id: '',
        accountName: '',
        accountId: '',
        email: '',
        profileImgUrl: '',
        accountType: 'ADMIN',
        accountStatus: 'NORMAL',
        customerId: '',
      },

      accountStatusOptions: [
        {
          text: '정상', value: 'NORMAL'
        },
        {
          text: '계정 정지', value: 'BLOCK'
        },
        {
          text: '초기상태', value: 'INIT'
        }
      ],
      customerList: [],
    }
  },
  methods: {
    getAccountTypeByRole() {
      if (this.getAdminRole() === 'SUPER') {
        return [
          {
            text: '시스템관리자',
            value: 'SUPER',
          },
          {
            text: '상위관리자',
            value: 'ADMIN',
          },
          {
            text: '일반관리자',
            value: 'NORMAL'
          }
        ];
      } else if (this.getAdminRole() === 'ADMIN') {
        return [
          {
            text: '상위관리자',
            value: 'ADMIN',
          },
          {
            text: '일반관리자',
            value: 'NORMAL'
          }
        ]
      }
    },
    adminInfoValidation() {
      if (!this.adminInfo.accountName || !this.adminInfo.accountId || !this.adminInfo.email) {
        return false;
      }

      if (this.adminInfo.accountName.length < 2 || this.adminInfo.accountName.length > 20) {
        return false;
      }

      const accountIdReg = new RegExp(/^([A-Za-z0-9]){4,20}$/gm);
      if (!this.adminInfo.accountId.match(accountIdReg)) {
        return false;
      }

      const emailReg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
      if (!this.adminInfo.email.match(emailReg)) {
        return false;
      }

      if (this.adminInfo.accountType !== 'SUPER') {
        if (!this.adminInfo.customerId || this.adminInfo.customerId === 0) {
          return false;
        }
      }

      return true;
    },
    upsertAdmin() {
      console.log('관리자 추가', this.adminInfo);
      if (!this.adminInfoValidation()) {
        Swal.fire({
          text: '입력하지 않은 정보가 있습니다',
          icon: 'error',
          confirmButtonText: '확인',
        });
        return;
      }

      if (this.adminInfo.accountType !== 'SUPER' && this.adminInfo.customerId === 1000) {
        Swal.fire({
          text: '소속 고객사를 선택하셔야 합니다.',
          icon: 'error',
          confirmButtonText: '확인',
        });
        return;
      }

      Swal.fire({
        text: '관리자 정보를 저장합니다',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#089BAB',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: '저장',
        cancelButtonText: '취소',
      }).then(result => {
        if (result.value) {
          this.$axios.put('/admin/upsert', this.adminInfo)
            .then(result => {
              console.log(result);

              Swal.fire('', '관리자 정보를 저장하였습니다', 'success')
                .then(t => {
                  this.backToPage();
                })
                .catch(c => {
                  this.backToPage();
                });
            })
            .catch(error => {
              console.log(error.response, error.request, error.message);
              let errorText = '';
              if (error.message ===  '4005' || error.message === '4000') {
                errorText = '입력한 정보가 잘못되었습니다. 다시 시도해주세요.';
              } else if (error.message === '4010') {
                errorText = '정보를 변경할 권한이 없습니다';
              } else if (error.message === '4009') {
                errorText = '이미 사용중인 아이디가 있습니다 다른 아이디로 등록하세요'
              } else if (error.message === '4016') {
                errorText = '이미 있는 메일 주소이므로 저장할 수 없습니다'
              } else {
                errorText = '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해 주세요';
              }

              Swal.fire('관리자 정보 저장 오류', errorText, 'error');
            });
        }
      });
    },
    backToPage() {
      if (this.getAdminRole() === 'SUPER') {
        this.$router.push({name: 'raonfit.super.adminList'});
      } else {
        this.$router.push({name: 'raonfit.adminList'});
      }

    },

    /**
     *
     */
    getCustomerList() {
      this.$axios.get('/customer/list/all')
        .then(result => {
          if (result.data.total > 0) {
            const customerList = [];

            result.data.customers.forEach(c => {
              const customer = {
                value: c.id,
                text: c.customerName,
              };

              customerList.push(customer);
            });
            _.sortBy(customerList, function(c) { return c.text; });
            this.customerList = customerList;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },

    getAdminRole() {
      const authToken = window.sessionStorage.getItem('Authorization');
      if (authToken) {
        return commons.parseAuthTokenToAdminInfo(authToken).role;
      } else {
        return '';
      }
    },
    changeAccountType(value) {
      console.log(value);
      if (value === 'SUPER') {
        this.adminInfo.customerId = 1000;
      }
    }
  },
}
</script>

<style scoped>
.button-style {
  width: 5vw;
  height: 5vh;
}
</style>
