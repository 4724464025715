<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height search-card-div">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <UserSearch
              @selectedItem="selectedUserData"
              @initSearch="initSearch"
            ></UserSearch>
          </b-col>
          <b-col lg="2" style="flex-basis: 50%;">
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px;" @click="addUser()">사용자 추가</b-button>
          </b-col>
          <b-col lg="4" style="display: flex; align-items: center; justify-content: flex-end; flex-basis: 50%;">
            <b-form-file id="excelFile" v-model="usersExcelFile" ref="excelFileRef" @change="readFile" v-show="false"/>
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px; margin-right: 20px;" @click="excelDownload()">양식 다운로드</b-button>
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px;" @click="resetFile(); $refs.excelFileRef.$el.querySelector('input[type=file]').click()">
              <template v-if="excelUploading">
                <b-spinner small type="grow"></b-spinner>
                업로드 중...
              </template>
              <template v-else>
                엑셀 업로드
              </template>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">사용자 목록</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="12">
            <b-table striped hover
                     :items="userItems"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="userFilter"
                     :filter-included-fields="filterOn"
                     @filtered="onFiltered"
                     stacked="md"
                     show-empty
            >
              <template #cell(actions)="row">
                <b-button pill variant="primary" class="mr-1" @click="editUser(row.item, row.index, $event.target)">수정</b-button>
                <b-button pill variant="primary" class="mr-1" @click="deleteUser(row.item, row.index, $event.target)">삭제</b-button>
              </template>
              <template #cell(userId)="data">
                <a @click="`${goToHealthLog(data)}`" style="cursor:pointer;">{{ data.value }}</a>
              </template>
              <template #cell(userName)="data">
                <a @click="`${goToHealthLog(data)}`" style="cursor:pointer;">{{ data.value }}</a>
              </template>
              <template #empty="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>데이터가 없습니다.</h5>
                </div>
              </template>
              <template #emptyfiltered="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>검색 결과가 없습니다.</h5>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="ma-0 pa-3"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import IqCard from '@/components/xray/cards/iq-card'
import UserSearch from '@/views/Raonfit/template/Search'
import Swal from "sweetalert2";
import XLSX from 'xlsx';
import _ from "lodash";

export default {
  components: {
    IqCard,
    UserSearch,
  },
  mounted() {
    this.findAllUsers();
  },
  watch: {
  },
  data() {
    return {
      fields: [
        {
          key: 'userId',
          label: '사용자 ID',
          sortable: false,
        },
        {
          key: 'userName',
          label: '사용자 이름',
          sortable: true,
        },
        {
          key: 'gender',
          label: '성별',
          sortable: true,
          formatter: (value, key, item) => {
            return value === 'MALE' ? '남' : '여';
          }
        },
        {
          key: 'birthday',
          label: '생년월일',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'email',
          label: '메일 주소',
          sortable: false,
          formatter: (value, key, item) => {
            if (value) {
              return value;
            } else {
              return '-';
            }
          }
        },
        {
          key: 'signInDate',
          label: '가입일',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },

        {
          key: 'latestFitDate',
          label: '최근 운동일자',
          sortable: false,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'actions', label: ''
        }
      ],
      userItems: [],

      userFilter: null,
      filterOn: ['userName'],

      currentPage: 1,
      totalRows: 0,
      perPage: 10,

      usersExcelFile: null,
      excelUploading: false,
    }
  },
  methods: {
    selectedUserData(user) {
      if (user.size > 1) {
        if (user.category === 'USERNAME') {
          this.filterOn = ['userName'];
          this.userFilter = user.search;
        } else if (user.category === 'ID') {
          this.filterOn = ['userId']
          this.userFilter = user.search;
        }
      } else if (user.size === 1) {
        if (user.category === 'USERNAME') {
          this.filterOn = ['userName'];
          this.userFilter = user.value.userName;
        } else if (user.category === 'ID') {
          this.filterOn = ['userId']
          this.userFilter = user.value.userId;
        }
      } else {
        console.log('겸색 결과가 없습니다.');
        if (user.category === 'USERNAME') {
          this.filterOn = ['userName'];
          this.userFilter = user.search;
        } else if (user.category === 'ID') {
          this.filterOn = ['userId']
          this.userFilter = user.search;
        }
      }

      // this.userFilter = user.userName;
    },
    initSearch() {
      this.filterOn = [];
      this.userFilter = null;
    },
    findAllUsers() {
      this.$axios.get('/user/list/all')
        .then(result => {
          this.totalRows = result.data.total;
          this.userItems = result.data.users;
        })
        .catch(error => {

        });
    },
    addUser() {
      this.$router.push('/userDetail');
    },
    editUser(item, index, button) {
      const userInfo = {
        id: item.id,
        userId: item.userId,
        userName: item.userName,
        birthday: item.birthday.substring(0, 4) + '-' + item.birthday.substring(4, 6) + '-' + item.birthday.substring(6),
        gender: item.gender,
        email: item.email,
      }
      this.$router.push({name : 'raonfit.userDetail', params: userInfo});
    },
    deleteUser(item, index, button) {
      Swal.fire({
        text: item.userName + "님 정보를 삭제합니다",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#089BAB",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`/user/delete/${item.id}`)
          .then(result => {
            if (result.data === 'DELETED') {
              Swal.fire({html: '선택한 사용자 정보를 삭제하였습니다.', icon: 'success'})
              .then(t => {
                this.findAllUsers();
              }).catch(c => {
                this.findAllUsers();
              })
            }

          })
          .catch(error => {
            let text = error.message;
            Swal.fire('사용자 삭제 오류', text, 'error');
          })
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    goToHealthLog(data) {
      console.log('goToHealthLog ', data);
      const userData = data.item;

      const userInfo = {
        id: userData.id,
        userId: userData.userId,
        name: userData.userName,
      };

      this.$store.dispatch('User/saveSearchUserAction', userInfo);

      this.$router.push({name: 'raonfit.healthLog'});
    },

    resetFile() {
      this.usersExcelFile = null;
      this.$refs.excelFileRef.value = null;
    },

    readFile(event) {
      this.excelUploading = true;
      const value = event.target.files[0];
      if (value.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        value.type !== 'application/vnd.ms-excel'
      ) {
        Swal.fire('업로드 오류', '엑셀 파일만 업로드할 수 있습니다', 'error');
        this.excelUploading = false;
        return;
      }

      this.excelJsonData = [];
      const file = value;
      let reader = new FileReader();
      let result = {};

      reader.onload = (e) => {
        try {
          let data = reader.result;
          let workbook = XLSX.read(data, {type: 'binary'});
          workbook.SheetNames.forEach(sheetName => {
            if (sheetName === 'UserDataForm') {
              workbook.Sheets[sheetName].A1.w = 'userName';
              workbook.Sheets[sheetName].B1.w = 'userId';
              workbook.Sheets[sheetName].C1.w = 'email';
              workbook.Sheets[sheetName].D1.w = 'gender';
              workbook.Sheets[sheetName].E1.w = 'birthday';
              workbook.Sheets[sheetName].F1.w = 'agree';

              result = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            }
          });

          this.validExcelData(result);
        } catch (ex) {
          console.log('엑셀 오류', ex);
          this.excelUploading = false;
          return false;
        }
      }

      reader.readAsBinaryString(file);
    },

    validExcelData(excelData) {
      const userBulkData = [];
      for (let i = 0 ; i < excelData.length; i++) {
        const item = excelData[i];
        if (!item.userName || !item.userId || !item.email || !item.gender || !item.birthday || !item.agree) {
          Swal.fire((i+2) + '행', '엑셀에 비어있는 값이 있습니다.', 'error');
          this.excelUploading = false;
          return;
        }

        const userIdReg = new RegExp(/^[a-z0-9+]{4,20}$/g);
        const userId = item.userId.toString();
        if (!userId.match(userIdReg)) {
          Swal.fire((i+2) + '행', '계정 ID는 영문자 및 숫자로 4자 이상 20자 이내로 입력해야 합니다', 'error');
          this.excelUploading = false;
          return;
        }

        const userName = item.userName.toString();
        if (userName.length > 20) {
          Swal.fire((i+2) + '행', '회원 이름은 20자 이내로 입력해야 합니다', 'error');
          this.excelUploading = false;
          return;
        }

        const emailReg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
        if (!item.email.match(emailReg)) {
          Swal.fire((i+2) + '행', '이메일 형식이 올바르지 않습니다', 'error');
          this.excelUploading = false;
          return;
        }

        if (item.gender !== '남성' && item.gender !== '여성') {
          Swal.fire((i+2) + '행', '성별을 확인해주세요', 'error');
          this.excelUploading = false;
          return;
        }

        const birthdayStr = _.toString(item.birthday).replaceAll('-', '').replaceAll('/', '');
        const birthday = _.toNumber(birthdayStr);
        if (birthdayStr.length !== 8 || isNaN(birthday)) {
          Swal.fire((i+2) + '행', '생년월일을 확인해주세요', 'error');
          this.excelUploading = false;

          return;
        }

        if (item.agree !== 'Y') {
          Swal.fire((i+2) + '행', '이용약관에 동의해야합니다.', 'error');
          this.excelUploading = false;

          return;
        }

        userBulkData.push({
          newUser: true,
          id: 0,
          userId: userId,
          userName: userName,
          email: item.email,
          gender: item.gender === '남성' ? 'MALE' : 'FEMALE',
          birthday: birthday,
          agree: item.agree === 'Y',
        })
      }

      this.$axios.put('/user/bulk/register', userBulkData)
      .then(result => {
        if (result.data.length > 0) {
          Swal.fire('회원 업로드', `${result.data.length}명은 회원은 등록하지 못했습니다. <br> ${_.join(result.data, ', ')}`, "success")
          .finally(() => {
            this.findAllUsers();
            this.excelUploading = false;
          })
        } else {
          Swal.fire('회원 업로드', '회원 업로드를 성공하였습니다.', "success")
          .finally(() => {
            this.findAllUsers();
            this.excelUploading = false;
          })
        }
      })
      .finally(() => {
        this.excelUploading = false;
      })
    },

    excelDownload() {
      this.$axios.get('/user/bulk/excel/download', {
        responseType: 'blob',
      })
      .then(result => {
        const url = window.URL.createObjectURL(new Blob([result.data], { type: result.headers['content-type']}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'user_excel_form.xlsx');
        link.click();
        link.remove();
      })
      .catch(e => {
        console.log(e);
      })
    },
  }
}
</script>

<style scoped lang="scss">
.table-btn {
  @media (max-width: 1310px) {
    width: 50px;
    height: 30px;
    font-size: 0.8em;
    margin: 0 auto;
  }

  @media (min-width: 1311px) {
    width: 75px;
    height: 35px;
    font-size: 1em;
    margin-right: 0.25rem !important;
  }
}
</style>
