import Vue from 'vue'
import '@babel/polyfill'
import 'mutationobserver-shim'
import './Utils/fliter'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import VueApexCharts from 'vue-apexcharts'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'
import vuetify from '@/plugins/vuetify'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import VueMoment from 'vue-moment'
import VueHtml2Canvas from 'vue-html2canvas'
import axios from '@/config/axios'

global.Raphael = Raphael
Vue.config.productionTip = false

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)
Vue.use(Autocomplete)
Vue.use(VueMoment)
Vue.use(VueHtml2Canvas)

/**
 * Axios
 */
Vue.prototype.$axios = axios;
// Vue.use(axios);

axios.interceptors.request.use(
  config => {
    const token = window.sessionStorage.getItem('Authorization');
    if (token !== undefined && token !== null && token !== '') {
      config.headers.common['Authorization'] = window.sessionStorage.getItem('Authorization');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      throw new Error('Invalid User');
    } else if (error.response.status === 400 && error.response.data != null) {
      if (error.response.data === "Bad Credentials token") {
        router.push('/login')
      } else {
        throw new Error(error.response.data)
      }
    }
    return Promise.reject(error);
  }
);


let vm = new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
