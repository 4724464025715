<template>
  <div>
    <h1 class="mb-3">비밀번호 변경</h1>
    <p>비밀번호는 <strong>영문자 및 숫자를 반드시 포함하여 8자 이상</strong>으로 입력해주시기 바랍니다.</p>
    <div class="mt-4">

      <div class="form-group">
        <div style="margin-bottom: 20px;">
          <label for="password1">비밀번호</label>
          <input type="password" v-model="password" class="form-control mb-0" id="password1" placeholder="비밀번호를 입력하세요">
        </div>

        <div>
          <label for="password2">비밀번호 재입력</label>
          <input type="password" v-model="rePassword" class="form-control mb-0" id="password2" placeholder="한번 더 비밀번호를 입력하세요">
        </div>
      </div>

      <p v-if="valid.error" class="valid">{{ valid.errorMsg }}</p>

      <br>
      <div class="w-100" style="display: flex; justify-content: flex-end;">
        <button class="btn btn-primary" style="margin-right: 20px;" @click="changePassword()">비밀번호 변경</button>
        <button class="btn btn-primary" @click="goToLogin()">로그인 화면</button>
      </div>

    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ChangePassword",
  props: {
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query && this.$route.query.p) {
      this.p = this.$route.query.p;
    } else {
      this.$router.push({name: 'raonfit.login'});
    }
  },
  data() {
    return {
      password: '',
      rePassword: '',
      p: null,

      valid: {
        error: false,
        errorMsg: '',
      }
    }
  },
  methods: {
    passwordValidation() {
      const password = this.password;
      const pwReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
      return !!password.match(pwReg);
    },
    changePassword() {
      if (!this.password) {
        this.valid.error = true;
        this.valid.errorMsg = '비밀번호를 입력하세요.';
        return;
      }

      if (!this.rePassword) {
        this.valid.error = true;
        this.valid.errorMsg = '비밀번호를 재입력하세요.';
        return;
      }

      if (!this.passwordValidation()) {
        this.valid.error = true;
        this.valid.errorMsg = '비밀번호는 영문자, 숫자를 포함하여 8자 이상으로 입력하세요.';
        return;
      }

      if (this.password !== this.rePassword) {
        this.valid.error = true;
        this.valid.errorMsg = '재입력한 비밀번호가 다릅니다.';
        return;
      }

      this.valid.error = false;
      this.valid.errorMsg = '';

      const data = {
        password: this.password,
        auth: this.p
      }

      this.$axios.post('/admin/change/password', data)
      .then(result => {
        console.log(result);

        Swal.fire('', '비밀번호가 변경되었습니다. 로그인 페이지로 이동합니다.')
        .finally(() => {
          this.goToLogin();
        })
      })
      .catch(error => {
        console.log(error);
        if (error.message === '4005') {
          Swal.fire('', '등록되지 않은 계정입니다.');
        } else if (error.message === '4017') {
          Swal.fire('', '올바른 접근 경로가 아닙니다.');
        } else {
          Swal.fire('', '비밀번호 변경에 실패했습니다. 관리자에게 문의해주세요.');
        }
      });
    },
    goToLogin() {
      this.$router.push({name: 'raonfit.login'});
    }
  }
}
</script>

<style lang="scss" scoped>
.valid {
  color: red;
  font-size: 0.9em;
}
</style>
