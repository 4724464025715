<template>
  <div>
    <h1 class="mb-3">비밀번호 분실</h1>
    <p>비밀번호를 분실한 계정의 아이디와 이메일 주소를 입력하세요. <br>등록된 이메일 주소로 비밀번호 메일이 전송됩니다.</p>
    <div class="mt-4">

      <div class="form-group">
        <div style="margin-bottom: 20px;">
          <label for="accountId">아이디</label>
          <input type="text" v-model="id" class="form-control mb-0" id="accountId" placeholder="아이디를 입력하세요.">
        </div>

        <div>
          <label for="email">이메일</label>
          <input type="email" v-model="email" class="form-control mb-0" id="email" placeholder="이메일을 입력하세요.">
        </div>
      </div>

      <p v-if="valid.error" class="valid">{{ valid.errorMsg }}</p>

      <br>
      <div class="w-100" style="display: flex; justify-content: flex-end;">
        <button class="btn btn-primary" style="margin-right: 20px;" @click="resetPassword()">비밀번호 변경</button>
        <button class="btn btn-primary" @click="goToLogin()">로그인 화면</button>
      </div>

    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: 'ResetPassword',
  data() {
    return {
      id: '',
      email: '',
      valid: {
        error: false,
        errorMsg: '',
      },
    }
  },
  methods: {
    resetPassword() {
      if (!this.id) {
        this.valid.error = true;
        this.valid.errorMsg = '아이디를 입력하세요.';
        return;
      }

      if (!this.email) {
        this.valid.error = true;
        this.valid.errorMsg = '이메일을 입력하세요.';
        return;
      }

      const emailReg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
      if (!this.email.match(emailReg)) {
        this.valid.error = true;
        this.valid.errorMsg = '올바른 이메일 형식이 아닙니다.';
        return;
      }

      this.valid.error = false;
      this.valid.errorMsg = '';

      const data = {
        id: this.id,
        email: this.email,
      }
      this.$axios.post('/admin/reset/password', data)
      .then(result => {
        console.log(result);
        Swal.fire('', '비밀번호 변경 메일을 보냈습니다. 메일을 확인해주세요.')
        .finally(() => {
          this.goToLogin();
        });
      })
      .catch(error => {
        console.log(error.response, error.request, error.message);
        if (error.message === '4005') {
          Swal.fire('', '등록되지 않은 아이디 또는 이메일입니다.');
        } else if (error.message === '4018') {
          Swal.fire('', '차단된 계정은 초기화할 수 없습니다.');
        } else {
          Swal.fire('', '메일 전송에 실패하였습니다. \n관리자에게 연락해주세요.');
        }
      })
    },
    goToLogin() {
      this.$router.push({name: 'raonfit.login'})
    }
  },
}
</script>

<style lang="scss" scoped>
.valid {
  color: red;
  font-size: 0.9em;
}
</style>
