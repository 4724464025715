<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <UserSearch
              :searchType="'ADMIN'"
              @selectedItem="selectedItem"
              @initSearch="initSearch"
            ></UserSearch>
          </b-col>
          <b-col>
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px;" @click="addAdmin()">관리자 추가</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">관리자 목록</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="12">
            <b-table striped hover
                     :items="adminItems"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="adminFilter"
                     :filter-included-fields="filterOn"
                     @filtered="onFiltered"
                     stacked="md"
                     show-empty
            >
              <template #cell(actions)="row">
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="editAdmin(row.item, row.index, $event.target)" class="mr-1">수정</b-button>
                <b-button v-if="isShowDeleteButton(row.item)" pill variant="primary" style="width: 75px; height: 35px;" @click="deleteAdmin(row.item, row.index, $event.target)" class="mr-1">삭제</b-button>
              </template>
              <template #empty="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>데이터가 없습니다.</h5>
                </div>
              </template>
              <template #emptyfiltered="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>검색 결과가 없습니다.</h5>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="ma-0 pa-3"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import IqCard from '@/components/xray/cards/iq-card'
import UserSearch from '@/views/Raonfit/template/Search'
import Swal from "sweetalert2";
import {commons} from "@/Utils/Commons";

export default {
  components: {
    IqCard,
    UserSearch,
  },
  props: {

  },
  created() {
    console.log('params -> ', this.$route);
    if (this.$route.params.selectCustomer) {
      this.filterOn = ['customerName'];
      this.adminFilter = this.$route.params.selectCustomer;
    }
  },
  mounted() {
    this.findAdminList();
  },
  data() {
    return {
      fields: [
        {
          key: 'customerName',
          label: '소속 고객사',
          sortable: true,
        },
        {
          key: 'accountId',
          label: '계정 ID',
          sortable: false,
        },
        {
          key: 'accountName',
          label: '담당자명',
          sortable: true,
        },
        {
          key: 'email',
          label: '메일 주소',
          sortable: false,
        },
        {
          key: 'accountType',
          label: '관리자타입',
          sortable: true,
          formatter: (value, key, item) => {
            if (value === 'NORMAL') {
              return '일반관리자';
            } else if (value === 'ADMIN') {
              return '상위관리자';
            } else if (value === 'SUPER') {
              return '슈퍼관리자';
            } else {
              return '';
            }
          }
        },
        {
          key: 'signInDate',
          label: '등록일',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },

        {
          key: 'recentLoginDate',
          label: '최근 접속일자',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'actions', label: ''
        }
      ],
      adminItems: [],

      adminFilter: null,
      filterOn: ['accountName'],

      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  methods: {
    selectedItem(admin) {
      if (admin.category === 'ACCOUNT_NAME') {
        this.filterOn = ['accountName'];
        this.adminFilter = admin.search;
      } else if (admin.category === 'ID') {
        this.filterOn = ['accountId'];
        this.adminFilter = admin.search;
      }
    },
    initSearch() {
      this.adminFilter = null;
      this.filterOn = [];
    },
    findAdminList() {
      this.$axios.get('/admin/list/all')
        .then(result => {
          this.totalRows = result.data.total;
          this.adminItems = result.data.adminList;
          console.log(this.adminItems);
        })
        .catch(error => {
          console.log(error);
        });
    },
    addAdmin() {
      if (this.getAdminRole() === 'SUPER') {
        this.$router.push({name: 'raonfit.super.adminDetail'});
      } else if (this.getAdminRole() === 'ADMIN') {
        this.$router.push({name: 'raonfit.adminDetail'});
      }
    },
    editAdmin(item, index, button) {
      if (this.getAdminRole() === 'SUPER') {
        this.$router.push({name : 'raonfit.super.adminDetail', params: item});
      } else if (this.getAdminRole() === 'ADMIN') {
        this.$router.push({name : 'raonfit.adminDetail', params: item});
      }
    },
    deleteAdmin(item, index, button) {
      Swal.fire({
        text: item.accountName + " 관리자 정보를 삭제합니다",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#089BAB",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`/admin/delete/${item.id}`)
            .then(result => {
              if (result.data === 'DELETED') {
                Swal.fire({html: '선택한 관리자 정보를 삭제하였습니다.', icon: 'success'})
                  .then(t => {
                    this.findAdminList();
                  }).catch(c => {
                    this.findAdminList();
                  })
              }

            })
            .catch(error => {
              let text = error.message;
              if (error.message === '4010') {
                Swal.fire('관리자 삭제 오류', '삭제 권한이 없습니다.', 'error');
              }

            })
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getMyEmail() {
      const authToken = window.sessionStorage.getItem('Authorization');
      return commons.parseAuthTokenToAdminInfo(authToken).email;
    },
    getAdminRole() {
      const authToken = window.sessionStorage.getItem('Authorization');
      if (authToken) {
        return commons.parseAuthTokenToAdminInfo(authToken).role;
      } else {
        return '';
      }
    },
    isShowDeleteButton(item) {
      if (item.email === this.getMyEmail()) {
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>

</style>
