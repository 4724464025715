export default {
  adminInfoCommit(state, data) {
    state.adminInfo = data;
  },
  adminNameCommit(state, data) {
    state.adminInfo.accountName = data.accountName;
    state.adminInfo.email = data.email;
  },
  // addUserCommit (state, data) {
  //   state.users.push(data)
  // },
  activePageCommit (state, data) {
    state.activePage = data
  },
}
