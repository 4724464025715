<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">사용자 {{userInfo.newUser ? '추가' : '수정'}}</h4>
      </template>
      <template v-slot:body>
        <div class="form-box">
          <b-form>
            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="userIdValid" class="detail-form-label">계정 ID</label>
                  </div>
                </b-col>
                <b-col lg="8">
                  <b-form-input v-if="userInfo.newUser" id="userIdValid" v-model.trim="userInfo.userId" :state="userIdValidation" required></b-form-input>
                  <b-form-input v-else id="userIdValid" v-model.trim="userInfo.userId" :state="userIdValidation" required readonly></b-form-input>
                  <b-form-invalid-feedback id="userIdValid" :state="userIdValidation">ID는 영소문자 및 숫자만 포함하여 4자 ~ 20자 이내여야 합니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="userIdValid" :state="userIdValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="nameValid" class="detail-form-label">이름</label>
                  </div>
                </b-col>
                <b-col lg="8">
                  <b-form-input id="nameValid" v-model.trim="userInfo.userName" :state="userNameValidation" required></b-form-input>
                  <b-form-invalid-feedback id="nameValid" :state="userNameValidation">이름은 2글자 이상이어야 합니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="nameValid" :state="userNameValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="emailValid" class="detail-form-label">이메일</label>
                  </div>
                </b-col>
                <b-col lg="8">
                  <b-form-input id="emailValid" v-model.trim="userInfo.email" type="email" :state="emailValidation" required></b-form-input>
                  <b-form-invalid-feedback id="emailValid" :state="emailValidation">이메일 형식이 올바르지 않습니다.</b-form-invalid-feedback>
                  <b-form-valid-feedback id="emailValid" :state="emailValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row style="display: flex; align-items: center;">
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">성별</label>
                  </div>
                </b-col>
                <b-col lg="2">
                  <div>
                    <b-form-radio inline v-model="userInfo.gender" :name="genderList.man.name" :value="genderList.man.value">{{ genderList.man.label }}</b-form-radio>
                    <b-form-radio inline v-model="userInfo.gender" :name="genderList.woman.name" :value="genderList.woman.value" >{{ genderList.woman.label }}</b-form-radio>
                  </div>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">생년월일</label>
                  </div>
                </b-col>
                <b-col lg="4">
                  <b-form-datepicker
                    id="birthdayValid"
                    v-model="userInfo.birthday"
                    locale="ko-KR"
                    :hide-header="true"
                    size="sm"
                    :state="!!userInfo.birthday"
                    placeholder="생년월일을 입력하세요"
                    style="display:flex; align-items: center;"
                  ></b-form-datepicker>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2" v-if="userInfo.newUser">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">이용약관 동의 여부</label>
                  </div>
                </b-col>
                <b-col lg="2" v-if="userInfo.newUser">
                  <div>
                    <b-checkbox class="custom-switch-color" :color="agreeItem.color" v-model="userInfo.agree" name="check-button" inline>
                      {{ agreeItem.label }}
                    </b-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>

            <hr>

            <div class="div-flex-align-right">
              <b-button variant="primary" class="button-style" @click="upsertUser()">저장</b-button>
              <b-button variant="none" class="iq-bg-danger ml-3 button-style" @click="backToPage()">취소</b-button>
            </div>
          </b-form>
        </div>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "UserDetail",
  created() {
    if (this.$route.params && this.$route.params.id) {
      const params = this.$route.params;
      this.userInfo = { newUser: false, password: '', ...params };
    }
  },
  mounted() {
    const element = document.getElementById('birthdayValid__value_');
    element.style.setProperty('margin-right', '-50px');
  },
  data() {
    return {
      userInfo: {
        newUser: true,
        id: '',
        userId: '',
        userName: '',
        password: '',
        email: '',
        gender: 'MALE',
        birthday: '',
        agree: true,
      },
      genderList: {
        man: {
          label: '남성',
          value: 'MALE',
        },
        woman: {
          label: '여성',
          value: 'FEMALE'
        },
      },

      agreeItem: {
        label: '동의',
        color: 'primary',
      },
    }
  },
  computed: {
    userIdValidation() {
      if (this.userInfo.userId) {
        const userIdReg = new RegExp(/^[A-Za-z0-9+]{4,20}$/g);
        return !!this.userInfo.userId.match(userIdReg);
      } else {
        return false;
      }
    },
    // passwordValidation() {
    //   if (!this.userInfo.newUser) {
    //     return true;
    //   }
    //   const password = this.userInfo.password;
    //   const pwReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    //   return !!password.match(pwReg);
    // },
    userNameValidation() {
      if (this.userInfo.userName) {
        return this.userInfo.userName.length >= 2 && this.userInfo.userName.length <= 20;
      } else {
        return false;
      }
    },
    emailValidation() {
      if (this.userInfo.email) {
        const email = this.userInfo.email;
        const emailReg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
        return !!email.match(emailReg);
      } else {
        return false;
      }
    },
  },
  methods: {
    cleanData() {
      this.userInfo = {
        newUser: true,
        id: '',
        userId: '',
        userName: '',
        email: '',
        gender: 'MALE',
        birthday: '',
        agree: true,
      };

    },
    userInfoValidation() {
      if (!this.userInfo.userId || !this.userInfo.userName || !this.userInfo.email) {
        return false;
      }

      if (!this.userInfo.birthday) {
        return false;
      }

      return true;
    },
    upsertUser() {
      if (!this.userInfoValidation()) {
        Swal.fire({
          text: '모든 정보를 입력해야합니다',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#089BAB',
          confirmButtonText: '확인',
        });

        return false;
      }

      const userIdReg = new RegExp(/^[a-z0-9+]{4,20}$/g);
      if (!this.userInfo.userId.match(userIdReg)) {
        Swal.fire({
          text: '아이디는 영문 및 숫자만 입력해야 합니다.',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#089BAB',
          confirmButtonText: '확인',
        });

        return false;
      }

      const birthday = _.join(_.split(this.userInfo.birthday, '-'), '');
      if (birthday.length !== 8) {
        Swal.fire({
          text: '생년월일을 정확히 입력해주세요',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#089BAB',
          confirmButtonText: '확인',
        });

        return false;
      }

      const data = {
        ...this.userInfo,
        birthday: birthday,
      };


      Swal.fire({
        text: '사용자 정보를 저장합니다',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#089BAB',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: '저장',
        cancelButtonText: '취소',
      }).then(result => {
        if (result.value) {
          this.$axios.put('/user/upsert', data)
          .then(result => {

            Swal.fire('', '사용자 정보를 저장하였습니다', 'success')
            .then(t => {
              this.goToUserList();
            })
            .catch(c => {
              this.goToUserList();
            });
          })
          .catch(error => {
            console.log(error.response, error.request, error.message);
            let errorText = '';
            if (error.message ===  '4000') {
              errorText = '정보를 정확히 입력해주세요';
            } else if (error.message === '4008') {
              errorText = '중복된 ID입니다. 다른 ID로 등록해주세요.';
            } else {
              errorText = '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해 주세요';
            }

            Swal.fire('사용자 정보 저장 오류', errorText, 'error');
          });
        }
      });
    },
    backToPage() {
      this.cleanData();
      this.$router.back();
    },
    goToUserList() {
      this.cleanData();
      this.$router.push({name: 'raonfit.userManagement'});
    },
  }
}
</script>

<style scoped lang="scss">
.button-style {
  width: 5vw;
  height: 5vh;
}
</style>
