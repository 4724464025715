<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">디바이스 {{machineInfo.newMachine ? '추가' : '수정'}}</h4>
      </template>
      <template v-slot:body>
        <div class="form-box">
          <b-form>
            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="nameValid" class="detail-form-label">디바이스명</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-col lg="3">
                    <b-form-select
                      v-model="machineInfo.deviceCategoryId"
                      :options="deviceCategoryOptions"
                      @change="changeDevice"
                      id="deviceCategorySelect">
                    </b-form-select>
                  </b-col>
                  <b-form-invalid-feedback id="nameValid" :state="nameValidation">디바이스명은 필수항목입니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="nameValid" :state="nameValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="uuidValid" class="detail-form-label">장비 UUID</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="uuidValid" v-model.trim="machineInfo.deviceUuid" :state="uuidValidation" required></b-form-input>
                  <b-form-invalid-feedback id="uuidValid" :state="uuidValidation">장비 UUID는 필수항목입니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="uuidValid" :state="uuidValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="apiKeyValid" class="detail-form-label">서버 통신 API 키</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="apiKeyValid" v-model="apiKey" :state="apiKeyValidation" readonly></b-form-input>
                  <b-form-invalid-feedback id="apiKeyValid" :state="apiKeyValidation">API Key는 필수항목입니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="apiKeyValid" :state="apiKeyValidation"></b-form-valid-feedback>
                </b-col>
                <b-col lg="1">
                  <b-button variant="primary" class="button-style" @click="generateApiKey()">키 생성</b-button>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">공급사</label>
                  </div>
                </b-col>

                <b-col lg="3">
                  <b-form-input v-model.trim="machineInfo.supplier"></b-form-input>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">공급일</label>
                  </div>
                </b-col>

                <b-col lg="3">
                  <b-form-input type="date" v-model="machineInfo.supplyDate"></b-form-input>
                </b-col>

              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="apPeriodValid" class="detail-form-label">무상 AS기간(개월)</label>
                  </div>
                </b-col>

                <b-col lg="3">
                  <b-form-input id="apPeriodValid" type="number" v-model.number="machineInfo.asPeriod" :state="asPeriodValidation" placeholder="AS기간을 개월 수로 입력해 주세요."></b-form-input>
                  <b-form-invalid-feedback id="apPeriodValid" :state="asPeriodValidation">0또는 1개월 이상의 정수로 입력해주세요</b-form-invalid-feedback>
                  <b-form-valid-feedback id="apPeriodValid" :state="asPeriodValidation"></b-form-valid-feedback>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="contactValid" class="detail-form-label">공급사 연락처</label>
                  </div>
                </b-col>

                <b-col lg="3">
                  <b-form-input id="contactValid" v-model.trim="machineInfo.supplierContact" :state="contactValidation"></b-form-input>
                  <b-form-invalid-feedback id="contactValid" :state="contactValidation">연락처는 20자 이내로 입력해야합니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="contactValid" :state="contactValidation"></b-form-valid-feedback>
                </b-col>

              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">고객사 선택</label>
                  </div>
                </b-col>
                <b-col lg="3">
                  <b-form-select
                    v-model="selectedCustomer"
                    :options="customerList"
                    id="selectCustomer">
                  </b-form-select>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">디바이스 사용여부</label>
                  </div>
                </b-col>

                <b-col lg="3">
                  <b-form-select
                    v-model="machineInfo.enabled"
                    :options="enableOptions"
                    id="selectEnabled">
                  </b-form-select>
                </b-col>
              </b-row>
            </b-form-group>

            <br>

            <b-form-group v-if="machineInfo.hasContents">
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">컨텐츠 선택</label>
                  </div>
                </b-col>
                <b-col lg="4" style="background-color: #BBBBBB20; border-radius: 20px; padding: 30px 20px; margin-left: 20px; margin-bottom: 20px; ">
                  <b-form-checkbox-group
                    v-model="selectedContents"
                    :options="contentsList"
                    :state="selectedContentsState"
                    name="checkbox-validation">
                    <b-form-invalid-feedback :state="selectedContentsState">1개 이상의 컨텐츠를 선택하세요.</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="selectedContentsState"></b-form-valid-feedback>
                  </b-form-checkbox-group>
                </b-col>
              </b-row>
            </b-form-group>

            <hr>

            <div style="display: flex; justify-content: flex-end;">
              <b-button variant="primary" class="button-style" @click="upsertMachine()">저장</b-button>
              <b-button variant="none" class="iq-bg-danger ml-3 button-style" @click="backToPage()">취소</b-button>
            </div>
          </b-form>
        </div>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "MachineDetail",
  created() {
    if (this.$route.params && this.$route.params.id) {
      const params = this.$route.params;
      this.machineInfo = { newDevice: false, ...params };

      this.selectedCustomer = params.customerId;
      const contentsIdArr = [];
      params.contentsInDeviceDtoList.forEach(p => {
        contentsIdArr.push(p.contentsId);
      });

      this.machineInfo.contentsId = contentsIdArr;
      this.selectedContents = contentsIdArr;
      this.apiKey = '등록된 API 키는 암호화되어 있습니다.';
      this.machineInfo.apiKey = 'REGISTERED';
      this.machineInfo.changeApiKey = false;
      this.machineInfo.supplyDate = this.$moment(params.supplyDate).valid() ? this.$moment(params.supplyDate, 'YYYYMMDD').format('YYYY-MM-DD') : '';
    } else {
      this.apiKey = '키 생성 버튼을 눌러서 API키를 생성하세요.';
    }
  },
  mounted() {
    this.getCustomerList();
    this.getContentsList();
    this.getDeviceCategoryList();
  },
  data() {
    return {
      machineInfo: {
        newDevice: true,
        id: '',
        deviceName: '',
        deviceCategoryId: '',
        deviceUuid: '',
        apiKey: '',
        changeApiKey: false,
        registerDate: '',
        enabled: true,
        customerId: '',
        customerName: '',
        contentsId: [],
        hasContents: true,

        supplier: '',
        supplyDate: '',
        asPeriod: 0,
        supplierContact: ''
      },

      showContents: true,
      apiKey: '',

      selectedCustomer: null,
      customerList: [],

      selectedContents: [],
      contentsList: [],

      enableOptions: [{text: '사용', value: true}, {text: '사용안함', value: false}],

      deviceCategoryOptions: [],
    }
  },
  computed: {
    nameValidation() {
      return !!this.machineInfo.deviceCategoryId;
    },
    uuidValidation() {
      return !!this.machineInfo.deviceUuid;
    },
    apiKeyValidation() {
      return !!this.machineInfo.apiKey;
    },
    selectedContentsState() {
      return this.selectedContents.length >= 1;
    },
    asPeriodValidation() {
      if (this.machineInfo.asPeriod === 0) {
        return true;
      }

      try {
        let number = parseInt(this.machineInfo.asPeriod);
        if (!number || isNaN(number) || !Number.isInteger(number)) {
          return false;
        } else {
          if (number < 0) {
            return false;
          }
        }

        return true;
      } catch (e) {
        return false;
      }
    },
    contactValidation() {
      if (this.machineInfo.supplierContact) {
        if (this.machineInfo.supplierContact.length > 20) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  },
  watch: {
    selectedCustomer(value) {
      this.machineInfo.customerId = value;
    },
    selectedContents(value) {
      this.machineInfo.contentsId = value;
    },
  },
  methods: {
    upsertValidation() {
      if (!this.machineInfo.deviceCategoryId || !this.machineInfo.deviceUuid || !this.machineInfo.apiKey) return false;
      if (!this.machineInfo.customerId) return false;

      return true;
    },
    upsertMachine() {
      if (!this.upsertValidation()) {
        Swal.fire('', '필수 정보를 입력해주세요', 'warning');
        return;
      }

      if (this.machineInfo.hasContents) {
        if (this.machineInfo.contentsId.length < 1 ) {
          Swal.fire('', '한개 이상의 컨텐츠를 선택해주세요', 'warning');
          return;
        }
      } else {

      }

      const data = {
        ...this.machineInfo,
        supplyDate: this.machineInfo.supplyDate.replaceAll('-', ''),
      }

      Swal.fire({
        text: '디바이스 정보를 저장합니다',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#089BAB',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: '저장',
        cancelButtonText: '취소',
      }).then(result => {
        if (result.value) {
          this.$axios.put('/device/upsert', data)
            .then(result => {
              Swal.fire('', '디바이스 정보를 저장하였습니다', 'success')
                .then(t => {
                  this.goToMachineList();
                })
                .catch(c => {
                  this.goToMachineList();
                });
            })
            .catch(error => {
              let errorText = '';
              if (error.message ===  '4012') {
                errorText = '장비 UUID가 중복되었습니다. 다시 입력해주세요.';
              } else {
                errorText = '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해 주세요';
              }

              Swal.fire('디바이스 정보 저장 오류', errorText, 'error');
            });
        }
      })
    },
    backToPage() {
      this.$router.back();
    },
    goToMachineList() {
      this.$router.push({name: 'raonfit.super.deviceList'});
    },

    /**
     * API
     */
    getCustomerList() {
      this.$axios.get('/customer/list/all')
      .then(result => {
        if (result.data.total > 0) {
          const customerList = [];

          result.data.customers.forEach(c => {
            const customer = {
              value: c.id,
              text: c.customerName,
            };

            customerList.push(customer);
          });
          _.sortBy(customerList, function(c) { return c.text; });
          this.customerList = customerList;
        }
      })
      .catch(error => {
        console.log(error);
      })
    },
    getContentsList() {
      this.$axios.get('/contents/list/all')
      .then(result => {
        if (result.data.total > 0) {
          const contentsList = [];
          result.data.contentsList.forEach(c => {
            const contents = {
              value: c.contentsId,
              text: c.contentsName,
            };

            contentsList.push(contents);
          });

          this.contentsList = _.sortBy(contentsList, function(c) { return c.text; });
        }
      })
    },
    getDeviceCategoryList() {
      this.$axios.get('/device/category/list')
      .then(result => {
        console.log('-> ', result);
        const data = result.data;
        const arr = [];
        data.forEach(d => {
          arr.push({
            text: d.name,
            value: d.deviceCategoryId,
            hasContents: d.hasContents,
          })
        });

        this.deviceCategoryOptions = arr;
      });
    },
    generateApiKey() {
      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const length = 20;
      let result = '';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      this.machineInfo.apiKey = result;
      this.machineInfo.changeApiKey = true;
      this.apiKey = result;
    },
    changeDevice(value) {
      this.deviceCategoryOptions.forEach(item => {
        if (item.value === value) {
          console.log('selected ', item);
          this.machineInfo.hasContents = item.hasContents;
          this.showContents = item.hasContents;

          if (!item.hasContents) {
            this.machineInfo.contentsId = [];
            this.selectedContents = [];
          }
        }
      });

    },
  }
}
</script>

<style scoped lang="scss">
.button-style {
  width: 100px;
  height: 45px;
}

</style>
