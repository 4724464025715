<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <Search
              :search-type="'CONTENTS'"
              @selectedItem="selectedItem"
              @initSearch="initSearch"
            ></Search>
          </b-col>
          <b-col>
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px;" @click="addContents()">컨텐츠 추가</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">컨텐츠 목록</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="12">
            <b-table striped hover
                     :items="contentsList"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="contentsFilter"
                     :filter-included-fields="filterOn"
                     @filtered="onFiltered"
                     stacked="md"
                     show-empty
            >
              <template #cell(actions)="row">
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="editContents(row.item, row.index, $event.target)" class="mr-1">수정</b-button>
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="deleteContents(row.item, row.index, $event.target)" class="mr-1">삭제</b-button>
              </template>
              <template #empty="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>데이터가 없습니다.</h5>
                </div>
              </template>
              <template #emptyfiltered="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>검색 결과가 없습니다.</h5>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="ma-0 pa-3"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import IqCard from '@/components/xray/cards/iq-card'
import Search from '@/views/Raonfit/template/Search'
import Swal from "sweetalert2";

export default {
  name: "ContentsList",
  components: {
    IqCard,
    Search,
  },
  mounted() {
    this.findAllContentsList();
  },
  data() {
    return {
      fields: [
        {
          key: 'contentsId',
          label: '컨텐츠 관리 ID',
          sortable: true,
        },
        {
          key: 'contentsName',
          label: '컨텐츠 이름',
          sortable: true,
        },
        {
          key: 'version',
          label: '컨텐츠 버전',
          sortable: true,
        },
        {
          key: 'registerDate',
          label: '등록일자',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'actions', label: ''
        }
      ],

      contentsList: [],
      contentsFilter: null,
      filterOn: ['contentsName'],

      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  methods: {
    findAllContentsList() {
      this.$axios.get('/contents/list/all')
        .then(result => {
          const data = result.data;
          if (data.total > 0) {
            this.totalRows = data.total;
            this.contentsList = data.contentsList;
          } else {
            this.totalRows = 0;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    selectedItem(contents) {
      if (contents.size === 1) {
        this.contentsFilter = contents.value.contentsName;
      } else {
        this.contentsFilter = contents.search;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    initSearch() {
      this.contentsFilter = null;
    },

    addContents() {
      const contentsInfo = { newContents: true };
      this.$router.push({name: 'raonfit.super.contentsDetail', params: contentsInfo});
    },
    editContents(item, index, button) {
      const contentsInfo = {
        newContents: false,
        ...item,
      };

      this.$router.push({name : 'raonfit.super.contentsDetail', params: contentsInfo});
    },
    deleteContents(item, index, button) {
      Swal.fire({
        text: item.contentsName + " 컨텐츠 정보를 삭제합니다",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#089BAB",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`/contents/delete/${item.contentsId}`)
            .then(result => {
              console.log(result);
              if (result.data === 'DELETED') {
                Swal.fire({html: '컨텐츠 정보를 삭제하였습니다.', icon: 'success'})
                  .then(t => {
                    this.findAllContentsList();
                  }).catch(c => {
                  this.findAllContentsList();
                })
              }

            })
            .catch(error => {
              let text = error.message;
              Swal.fire('운동기구 정보 삭제 오류', text, 'error');
            })
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
