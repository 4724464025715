import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
import Layout1 from '../layouts/Layout1'

/* Raonfit View */
import Dashboard from '@/views/Raonfit/Dashboard'
import HealthLog from '@/views/Raonfit/HealthLog'
import FitData from '@/views/Raonfit/FitData'
import PersonalCoaching from '@/views/Raonfit/PersonalCoaching'
import UserManagement from "@/views/Raonfit/UserManagement";
import UserDetail from "@/views/Raonfit/UserDetail";
import Login from "@/views/Raonfit/Login";
import AuthLayout from "@/views/Raonfit/AuthLayout";
import AdminList from "@/views/Raonfit/admin/AdminList";
import AdminDetail from "@/views/Raonfit/admin/AdminDetail";
import SaDashboard from "@/views/Raonfit/superAdmin/SaDashboard";
import SuperadminLayout from "@/layouts/SuperadminLayout";
import CustomerList from "@/views/Raonfit/superAdmin/CustomerList";
import CustomerDetail from "@/views/Raonfit/superAdmin/CustomerDetail";
import MachineList from "@/views/Raonfit/superAdmin/MachineList";
import MachineDetail from "@/views/Raonfit/superAdmin/MachineDetail";
import AdminProfile from "@/views/Raonfit/AdminProfile";
import ResetPassword from "@/views/Raonfit/template/ResetPassword";
import ChangePassword from "@/views/Raonfit/template/ChangePassword";
import ContentsList from "@/views/Raonfit/superAdmin/ContentsList";
import ContentsDetail from "@/views/Raonfit/superAdmin/ContentsDetail";
import NoticeDetail from "@/views/Raonfit/superAdmin/NoticeDetail";
import NoticeList from "@/views/Raonfit/superAdmin/NoticeList";

/* Raonfit SystemAdmin View */

Vue.use(VueRouter)

const raonfitRoutes = (prop, mode) => [
  {
    path: '/',
    name: 'raonfit.dashboard',
    meta: {dark: mode, auth: true, name: 'Dashboard'},
    component: Dashboard
  },
  {
    path: 'healthLog',
    name: 'raonfit.healthLog',
    meta: {dark: mode, auth: true, name: 'Health Log'},
    component: HealthLog
  },
  {
    path: 'fitData',
    name: 'raonfit.fitData',
    meta: {dark: mode, auth: true, name: 'Fit Data'},
    component: FitData
  },
  {
    path: 'coaching',
    name: 'raonfit.coaching',
    meta: {dark: mode, auth: true, name: 'Coaching'},
    component: PersonalCoaching
  },
  {
    path: 'userManagement',
    name: 'raonfit.userManagement',
    meta: {dark: mode, auth: true, name: 'userManagement'},
    component: UserManagement
  },
  {
    path: 'userDetail',
    name: 'raonfit.userDetail',
    meta: {dark: mode, auth: true, name: 'userDetail'},
    component: UserDetail
  },
  {
    path: 'adminList',
    name: 'raonfit.adminList',
    meta: {dark: mode, auth: true, name: 'adminList'},
    component: AdminList
  },
  {
    path: 'adminDetail',
    name: 'raonfit.adminDetail',
    meta: {dark: mode, auth: true, name: 'adminDetail'},
    component: AdminDetail
  },
  {
    path: 'myProfile/:adminId',
    name: 'raonfit.profile',
    props: true,
    meta: {dark: mode, auth: true, name: 'adminProfile'},
    component: AdminProfile
  },
]

const raonfitSystemAdminRoutes = (prop, mode) => [
  {
    path: 'dashboard',
    name: 'raonfit.super.dashboard',
    meta: {dark: mode, auth: true, name: 'saDashboard'},
    component: SaDashboard
  },
  {
    path: 'customer/list',
    name: 'raonfit.super.customerList',
    meta: {dark: mode, auth: true, name: 'saCustomerList'},
    component: CustomerList
  },
  {
    path: 'customer/detail',
    name: 'raonfit.super.customerDetail',
    meta: {dark: mode, auth: true, name: 'saCustomerDetail'},
    component: CustomerDetail
  },
  {
    path: 'admin/list',
    name: 'raonfit.super.adminList',
    meta: {dark: mode, auth: true, name: 'saAdminList'},
    component: AdminList
  },
  {
    path: 'admin/detail',
    name: 'raonfit.super.adminDetail',
    meta: {dark: mode, auth: true, name: 'saAdminDetail'},
    component: AdminDetail
  },

  {
    path: 'machine/list',
    name: 'raonfit.super.deviceList',
    meta: {dark: mode, auth: true, name: 'saDeviceList'},
    component: MachineList
  },
  {
    path: 'machine/detail',
    name: 'raonfit.super.deviceDetail',
    meta: {dark: mode, auth: true, name: 'saDeviceDetail'},
    component: MachineDetail
  },

  {
    path: 'contents/list',
    name: 'raonfit.super.contentsList',
    meta: {dark: mode, auth: true, name: 'saContentsList'},
    component: ContentsList
  },
  {
    path: 'contents/detail',
    name: 'raonfit.super.contentsDetail',
    meta: {dark: mode, auth: true, name: 'saContentsDetail'},
    component: ContentsDetail
  },
  {
    path: 'myProfile/:adminId',
    name: 'raonfit.sa.profile',
    props: true,
    meta: {dark: mode, auth: true, name: 'saAdminProfile'},
    component: AdminProfile
  },
  {
    path: 'notice/list',
    name: 'raonfit.super.noticeList',
    meta: {dark: mode, auth: true, name: 'saNoticeList'},
    component: NoticeList
  },
  {
    path: 'notice/detail',
    name: 'raonfit.super.noticeDetail',
    meta: {dark: mode, auth: true, name: 'saNoticeDetail'},
    component: NoticeDetail
  },
]

const raonfitAuthRoutes = (prop, mode = false) => [
  {
    path: 'login',
    name: 'raonfit.login',
    meta: {dark: mode, auth: true},
    component: Login
  },
  {
    path: 'reset/password',
    name: 'raonfit.reset.password',
    meta: {dark: mode, auth: true},
    component: ResetPassword
  },
  {
    path: 'change/password',
    name: 'raonfit.change.password',
    meta: {dark: mode, auth: true},
    props: (route) => ({query: route.query.p}),
    component: ChangePassword
  }
]

const routes = [
  /* 추가 */
  {
    path: '/',
    name: 'dashboard',
    component: Layout1,
    meta: {auth: true},
    children: raonfitRoutes('dashboard')
  },
  {
    path: '/sa',
    name: 'superadminDashboard',
    component: SuperadminLayout,
    meta: {auth: true},
    children: raonfitSystemAdminRoutes(),
  },
  {
    path: '/a',
    name: 'auth',
    component: AuthLayout,
    meta: {auth: true},
    children: raonfitAuthRoutes()
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
