<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">사용자 요소별 데이터</h4>
          </template>
          <template v-slot:body>
            <UserSearch
              @selectedItem="selectedUserData"
            ></UserSearch>

            <b-table striped hover
                     :items="userItems"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="userFilter"
                     :filter-included-fields="filterOn"
                     @filtered="onFiltered"
                     stacked="md"
                     show-empty
            ></b-table>

            <!--
            <b-table-simple responsive>
              <b-thead>
                <b-th>이름</b-th>
                <b-th>적정 체중</b-th>
                <b-th>체중조절</b-th>
                <b-th>지방조절</b-th>
                <b-th>근육조절</b-th>
                <b-th>BMI상태</b-th>
                <b-th>체지방률 상태</b-th>
                <b-th>세포내수분</b-th>
                <b-th>기초대사량</b-th>
                <b-th>복부지방률</b-th>
                <b-th>내장지방레벨</b-th>
                <b-th>비만도</b-th>
                <b-th>상체균형</b-th>
                <b-th>하체균형</b-th>
                <b-th>상하균형</b-th>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
                <b-tr>
                  <b-th>김라온</b-th>
                  <b-td>59</b-td>
                  <b-td>+6</b-td>
                  <b-td>+4</b-td>
                  <b-td>+7</b-td>
                  <b-td>19.6</b-td>
                  <b-td>8.4</b-td>
                  <b-td>40</b-td>
                  <b-td>20</b-td>
                  <b-td>0.64</b-td>
                  <b-td>3</b-td>
                  <b-td>1</b-td>
                  <b-td>균형</b-td>
                  <b-td>약한불균형</b-td>
                  <b-td>심한불균형</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            -->
            <div class="mt-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                class="ma-0 pa-3"
                align="center"
              ></b-pagination>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import UserSearch from '@/views/Raonfit/template/Search'

export default {
  name: 'PersonalCoaching',
  components: {
    UserSearch
  },
  mounted() {
    this.$axios.get('')
    .then(result => {
    })
    .catch(error => {

    });
  },
  data() {
    return {
      fields: [
        {
          key: 'userName',
          label: '이름',
          sortable: true,
        },
        {
          key: 'properWeight',
          label: '적정 체중',
          sortable: true,
        },
        {
          key: 'weightControl',
          label: '체중조절',
          sortable: true,
        },
        {
          key: 'fatControl',
          label: '지방조절',
          sortable: true,
        },
        {
          key: 'muscleControl',
          label: '근육조절',
          sortable: true,
        },
        {
          key: 'bmiState',
          label: 'BMI상태',
          sortable: true,
        },
        {
          key: 'bfPercentStatus',
          label: '체지방률 상태',
          sortable: true,
        },
        {
          key: 'imMoisture',
          label: '세포내수분',
          sortable: true,
        },
        {
          key: 'bm',
          label: '기초대사량',
          sortable: true,
        },
        {
          key: 'whr',
          label: '복부지방률',
          sortable: true,
        },
        {
          key: 'vfl',
          label: '내장지방레벨',
          sortable: true,
        },
        {
          key: 'obesity',
          label: '비만도',
          sortable: true,
        },
        // {
        //   key: 'ubb',
        //   label: '상체균형',
        //   sortable: true,
        // },
        // {
        //   key: 'lbb',
        //   label: '하체균형',
        //   sortable: true,
        // },
        // {
        //   key: 'ulbb',
        //   label: '상하균형',
        //   sortable: true,
        // },
        {
          key: 'measureDatetime',
          label: '측정일자',
          sortable: true,
          formatter: (value) => {
            return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6);
          }
        },
      ],



      userItems: [],
      userFilter: null,
      filterOn: ['userName'],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  methods: {
    selectedUserData (user) {
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;


      this.currentPage = 1;
    },
  }
}
</script>

<style scoped>

</style>
