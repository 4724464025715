<template>
  <b-container fluid>
    <b-row>
      <b-col lg="9">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">회원 가입 및 운동 현황</h4>
          </template>
          <template v-slot:body class="iq-card-body">
            <MultiLineChart
              v-if="fitDataLoaded"
              :chart-data="userFitStatusData"
              :chart-labels="generateChartLabel(7)"
              :title="'운동한 회원 수'"
              :width="400"
              :height="150"
              :yLabel="'명'"
              :stepSize="2"
            ></MultiLineChart>
          </template>
        </iq-card>
      </b-col>

      <b-col md="12" lg="3">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">최근 변경사항</h4>
          </template>
          <template v-slot:body>
            <ul class="iq-timeline">
              <li v-for="(log, idx) in recentLogs">
                <div class="timeline-dots-fill"></div>
                <h6 class="float-left mb-2 text-dark">{{ log.actionTarget + ' ' + log.action }}</h6>
                <small class="float-right mt-1">{{ $moment(log.logDate, 'YYYYMMDDHHmmss').add(9, 'h').format('YYYY-MM-DD HH:mm:ss') }}</small>
                <div class="d-inline-block w-100">
                  <p>{{ log.customerName + '에서 ' + log.targetName + '를 ' + log.action + '하였습니다' }}</p>
                </div>
              </li>
            </ul>
          </template>
        </iq-card>
      </b-col>

    </b-row>
    <b-row>
      <b-col lg="7">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">디바이스별 이용시간</h4>
          </template>
          <template v-slot:body>
            <apexchart ref="mup" height="400" :options="machineUsagePartial" :series="machineUsagePartial.series"/>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="5">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">연령별 디바이스 사용시간</h4>
          </template>
          <template v-slot:body>
            <apexchart ref="mua" :options="machineUsageByAge" :series="machineUsageByAge.series" />
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">남녀별 디바이스 사용시간</h4>
          </template>
          <template v-slot:body>
            <apexchart ref="mug" height="400" :options="machineUsageByGender" :series="machineUsageByGender.series" />
          </template>
        </iq-card>
      </b-col>

      <b-col lg="8">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">남녀별 운동시간</h4>
          </template>
          <template v-slot:body>
            <MultiLineChart
              v-if="fitDataLoaded"
              :chart-data="exerciseByGender2"
              :chart-labels="generateChartLabel(7, 'MM-DD')"
              :title="'남녀별 운동시간'"
              :height="160"
              :stepSize="1"
            ></MultiLineChart>
<!--            <ApexChart element="exercise-gender" :chartOption="exerciseByGender"/>-->
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '@/config/pluginInit'
import IqCard from '@/components/xray/cards/iq-card'
import MultiLineChart from '@/views/Raonfit/template/MultiLineChart'
import _ from "lodash";

export default {
  name: 'Raonfit-Dashboard',
  components: { MultiLineChart, IqCard },
  mounted () {
    xray.index();
    this.getDashboardData();
    this.getRecentLogs();
  },
  data () {
    return {
      fitDataLoaded: false,
      userFitStatusData: [
        {
          id: 'exerciseCount',
          label: '운동한 회원 수',
          fill: true,
          backgroundColor: '#36A2EB3F',
          borderColor: '#36A2EB',
          data: [3, 9, 2, 10, 23, 24, 40]
        },
        {
          id: 'signedCount',
          label: '가입한 회원 수',
          fill: true,
          backgroundColor: '#126e822F',
          borderColor: '#126e82',
          data: [9, 12, 8, 4, 1, 7, 6]
        }
      ],

      recentLogs: [],
      machineUsageByGender: {
        noData: {
          text: '데이터 로딩 중..'
        },
        series: [],
        colors: ['#089bab', '#FC9F5B'],
        chart: {
          type: 'bar',
          height: 450,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          categories: []
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: false
        },
      },

      exerciseByGender2: [
        {
          id: 'man',
          label: '남',
          fill: false,
          backgroundColor: '#089bab3F',
          borderColor: '#089bab',
          data: this.generateChartData(5, 15, 30)
        },
        {
          id: 'woman',
          label: '여',
          fill: false,
          backgroundColor: '#FC9F5B3F',
          borderColor: '#FC9F5B',
          data: this.generateChartData(10, 30, 30)
        }
      ],

      exerciseByGender: {
        noData: {
          text: '데이터 로딩 중..'
        },
        series: [],
        colors: ['#089bab', '#FC9F5B'],
        chart: {
          type: 'bar',
          height: 450,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          type: 'datetime',
          categories: this.generateChartLabel(30, 'MM-DD')
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' 분'
            }
          }
        }
      },

      machineUsageByAge: {
        noData: {
          text: '데이터 로딩 중..'
        },
        series: [],
        colors: ['#02475e', '#687980', '#f3bda1', '#61c0bf', '#a799b7', '#999b84'],
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: -10,
              offsetY: 0
            }
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          categories: []
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' 분'
            }
          }
        }
      },

      machineUsagePartial: this.machineUsagePartialTemplate(),
    }
  },
  methods: {
    getDashboardData() {
      const start = this.$moment().subtract(6, 'days').format('YYYYMMDD').concat('000000');
      const end = this.$moment().format('YYYYMMDD').concat('235959');

      this.$axios.get(`/sa/dashboard/collect/data/${start}/${end}`)
      .then(result => {
        const data = result.data;

        // 일주일간 회원 가입 및 운동 현황
        this.setExerciseAndSignedData(data.statusCountList);
        // 디바이스별 이용시간
        this.setDeviceUsagePerDay(data.devices, data.deviceUsagePerDay);
        // 연령별, 남녀별 디바이스 사용시간
        this.setDeviceUsageBy(data.devices, data.deviceUsageList);

        // 남녀별 운동시간
        const keyDates = Object.keys(data.deviceUsagePerDay);
        this.setExercisePerGender(keyDates, data.genderPerDates);
      })
      .catch(error => {
        console.log(error);
      })
    },

    setExerciseAndSignedData(statusCountArr) {
      this.fitDataLoaded = true;
      const exerciseArr = [];
      const signedArr = [];

      statusCountArr.forEach(s => {
        exerciseArr.push(s.exerciseCount);
        signedArr.push(s.signedCount);
      });


      this.userFitStatusData.forEach(d => {
        if (d.id === 'exerciseCount') {
          d.data = exerciseArr;
        } else if (d.id === 'signedCount') {
          d.data = signedArr;
        }
      })
    },

    setDeviceUsagePerDay(devices, deviceUsagePerDay) {
      let keyDates = Object.keys(deviceUsagePerDay);
      const deviceUsagePerDayArr = [];
      devices.forEach(m => {
        deviceUsagePerDayArr.push({
          name: m.name,
          data: [],
        });
      })

      keyDates.forEach(date => {
        const arr = deviceUsagePerDay[date];

        arr.forEach(a => {
          deviceUsagePerDayArr.forEach(w => {
            if (a.machineName === w.name) {
              w.data.push(a.usage);
            }
          })
        })
      });

      this.$refs.mup.updateSeries(deviceUsagePerDayArr);
    },

    setDeviceUsageBy(devices, deviceUsageList) {
      let manArr = new Array(devices.length).fill(0);
      let womanArr = new Array(devices.length).fill(0);

      let ageArr1 = new Array(devices.length).fill(0);
      let ageArr2 = new Array(devices.length).fill(0);
      let ageArr3 = new Array(devices.length).fill(0);
      let ageArr4 = new Array(devices.length).fill(0);
      let ageArr5 = new Array(devices.length).fill(0);
      let ageArr6 = new Array(devices.length).fill(0);
      for (let i = 0; i < devices.length; i++) {
        const deviceName = devices[i].name;

        deviceUsageList.forEach(list => {
          if (list.deviceName === deviceName) {
            list.genderList.forEach(g => {
              if (g.genderType === 'MALE') {
                manArr[i] = g.usage;
              } else {
                womanArr[i] = g.usage;
              }
            });

            list.ageList.forEach(a => {
              if (a.ageUnits === 'TEEN_AND_YOUNGER') {
                ageArr1[i] = a.usage;
              } else if (a.ageUnits === 'TWENTIES') {
                ageArr2[i] = a.usage;
              } else if (a.ageUnits === 'THIRTIES') {
                ageArr3[i] = a.usage;
              } else if (a.ageUnits === 'FORTY') {
                ageArr4[i] = a.usage;
              } else if (a.ageUnits === 'FIFTIES') {
                ageArr5[i] = a.usage;
              } else if (a.ageUnits === 'OVER_SIXTEEN') {
                ageArr6[i] = a.usage;
              }
            });
          }
        });
      }

      const byGender = [{ name: '남', data: manArr }, { name: '여', data: womanArr }];


      const byAge = [{
        name: '10대 이하',
        data: ageArr1,
      }, {
        name: '20대',
        data: ageArr2,
      }, {
        name: '30대',
        data: ageArr3,
      }, {
        name: '40대',
        data: ageArr4,
      },{
        name: '50대',
        data: ageArr5,
      }, {
        name: '60대이상',
        data: ageArr6,
      }];

      this.$refs.mua.updateOptions({
        series: byAge,
        xaxis: {
          categories: devices.map(d => d.name),
        }
      });

      this.$refs.mug.updateOptions({
        series: byGender,
        xaxis: {
          categories: devices.map(d => d.name),
        }
      });
    },

    setExercisePerGender(dates, genderPerDates) {
      const manArr = new Array(dates.length).fill(0);
      const womanArr = new Array(dates.length).fill(0);

      for (let i = 0; i < dates.length; i++) {
        const date = dates[i];

        genderPerDates.forEach(gpd => {
          if (gpd.playDate === date) {
            gpd.genderList.forEach(g => {
              if (g.genderType === 'MALE') {
                manArr[i] = g.usage;
              } else {
                womanArr[i] = g.usage;
              }
            })
          }
        })
      }

      this.exerciseByGender2.forEach(item => {
        if (item.id === 'man') {
          item.data = manArr;
        } else {
          item.data = womanArr;
        }
      });
    },

    generateChartLabel (beforeDate = 7, format = 'YYYY-MM-DD') {
      const dateArr = [];
      for (let i = 1; i <= beforeDate; i++) {
        dateArr.push(this.$moment().subtract(beforeDate - i, 'days').format(format));
      }
      return dateArr;
    },

    generateChartData(min, max, size) {
      const dataArr = [];

      for (let i = 0; i < size; i++) {
        const randomNumber = Math.floor(Math.random() * max) + min;
        dataArr.push(randomNumber);
      }

      return dataArr;
    },

    getRecentLogs() {
      this.$axios.get('/sa/customer/recent/logs')
      .then(result => {
        if (result.data.length > 0) {
          this.recentLogs = result.data;
        }
      })
      .catch(error => {

      });
    },


    machineUsagePartialTemplate() {
      return {
        noData: {
          text: '데이터 로딩 중..'
        },
        series: [],
          colors: ['#C125929F', '#0A71D59F', '#5200AE9F', '#00AE8F9F', '#C8D65B9F', '#FFBB309F'],
          chart: {
          type: 'bar',
            height: 400,
            stacked: false,
            toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        title: {
          text: '일별 누적 운동시간'
        },
        responsive: [{
          options: {
            legend: {
              position: 'bottom',
              offsetX: 10,
              offsetY: 0
            },
          }
        }],
          plotOptions: {
          bar: {
            horizontal: false,
              columnWidth: '50%',
          }
        },
        tooltip: {
          enabled: true,
            x: {
            format: 'MM월 dd일'
          },
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              let total = 0;
              series.forEach(s => {
                const data = s[dataPointIndex];
                if (_.isNumber(data)) {
                  total += data;
                }
              });
              return value + '분 / 총 운동시간 ' + total + '분';
            },
          },
          style: {
            fontSize: '15px',
              fontFamily: 'NanumSquareR',
          },
        },
        xaxis: {
          categories: this.generateChartLabel(),
            labels: {
            formatter: function (value) {
              const convert = new Date(value);
              let date = convert.getDate();
              let month = convert.getMonth() + 1;
              let fullYear = convert.getFullYear();
              return month + '월 ' + date + '일';
            }
          }
        },
        // legend: {
        //   position: 'right',
        //   offsetY: 40
        // },
        dataLabels: {
          enabled: false
        },
      };
    }
  }
}
</script>

<style scoped lang="scss">
</style>
