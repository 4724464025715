<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="id" name="ID" rules="required" v-slot="{ errors }">
        <div class="form-group ">
          <label for="IDInput">아이디</label>
          <input type="text" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="IDInput" aria-describedby="idHelp"
                 tabindex="1"
                 v-model="user.id" placeholder="아이디를 입력하세요." required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="passwordInput">비밀번호</label>
          <router-link to="/a/reset/password" class="float-right" tabindex="-1">
            비밀번호를 잊으셨나요?
          </router-link>
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 tabindex="2"
                 v-model="user.password" placeholder="비밀번호를 입력하세요." required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>

      <div v-if="loginInvalid" class="login-invalid">
        <span>{{loginErrorMessage}}</span>
      </div>
      <div class="d-inline-block w-100 h-50">
        <button type="submit" class="btn btn-primary float-right">로그인</button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { commons } from '@/Utils/Commons'

export default {
  name: 'LoginForm',
  components: {  },
  props: ['id', 'password'],
  data: () => ({
    user: {
      id: '',
      password: ''
    },
    loginErrorMessage: '',
    loginInvalid: true,
  }),
  mounted () {
    this.user.id = this.$props.id
    this.user.password = this.$props.password
  },
  computed: {
  },
  methods: {
    onSubmit () {
      this.login();
    },
    login () {
      this.$axios.post('/auth/login', {id: this.user.id, password: this.user.password})
      .then(result => {
        this.loginInvalid = false;
        const authToken = result.data;
        window.sessionStorage.setItem('Authorization', authToken);

        // 정보 입력
        const adminInfo = commons.parseAuthTokenToAdminInfo(authToken);
        this.$store.dispatch('Auth/setAdminInfoAction', adminInfo);

        // 일반 고객의 계정과 시스템 관리자의 routing 별도
        if (adminInfo.role === 'ADMIN' || adminInfo.role === 'NORMAL') {
          this.$router.push({ name: 'raonfit.dashboard' });
        } else if (adminInfo.role === 'SUPER') {
          this.$router.push({ name: 'raonfit.super.dashboard' });
        } else {
          console.log(adminInfo);
        }
      })
      .catch(error => {
        console.log(error, error.message);

        if (error.message) {
          if (error.message.split('/')[0] === 'INIT') {
            this.$router.push({name: 'raonfit.change.password', query: {p: error.message.split('/')[1]}})
          }
        }

        this.loginInvalid = true;
        if ("Bad Credentials" === error.message) {
          this.loginErrorMessage = '아이디 또는 비밀번호가 틀립니다';
        } else if ("Invalid User" === error.message) {
          this.loginErrorMessage = '아이디 또는 비밀번호가 틀립니다';
        } else if ("BLOCK" === error.message) {
          this.loginErrorMessage = '차단된 계정입니다. 관리자에게 문의하세요.';
        } else if ("DORMANT" === error.message) {
          this.loginErrorMessage = '휴면계정이므로 로그인할 수 없습니다. 계정을 초기화하세요';
        } else {
          this.loginErrorMessage = '일시적인 오류로 로그인 할 수 없습니다';
        }
      });
    },
    parseToAdminInfo(decodeToken) {
      return {
        accountId: decodeToken.jti,
        accountName: decodeToken.accountName,
        role: decodeToken.role,
        customer: decodeToken.customer,
        email: decodeToken.email,
        profileImgUrl: decodeToken.profileImgUrl ? decodeToken.profileImgUrl : '',
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.login-invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
