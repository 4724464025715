<template>
  <div>
    <h1 class="mb-0">RaonFit</h1>
    <p>가입하신 아이디/비밀번호를 입력하세요.</p>
    <LoginForm :id="id" :password="password"></LoginForm>
  </div>
</template>

<script>
import LoginForm from "@/views/Raonfit/template/LoginForm";

export default {
  name: 'Login',
  components: { LoginForm },
  data: () => ({
    id: '',
    password: '',
  }),
  mounted () {
  },
  methods: {
  }
}
</script>
