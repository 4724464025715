<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="homeURL">
<!--        <img :src="logo" class="" alt="logo" style="object-fit: contain; height: 35px;">-->
        <span class="customer-name-style">{{adminInfo.customerName}}</span>
      </router-link>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <List :header="adminInfo.siteName" :items="items" :open="true" :horizontal="horizontal"/>
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import List from '../menus/ListStyle1'
export default {
  name: 'SideBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'raonfit.dashboard' }) },
    items: { type: Array },
    logo: { type: String, default: require('@/assets/images/mobinus_logo_1.png') },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true }
  },
  components: {
    List
  },
  created() {
    // this.$emit('toggle')
    const adminInfo = this.$store.getters['Auth/adminState'];
    this.adminInfo = adminInfo;
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    }
  },
  data () {
    return {
      adminInfo: {},
    }
  }
}
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');
</style>
