<script>
import { HorizontalBar } from 'vue-chartjs'

export default {
  extends: HorizontalBar,
  created () {
  },
  props: {
    chartData: {
      type: Array | Object,
      required: false
    },
    chartLabels: {
      type: String,
      required: true
    },
    barColor: {
      type: Object,
      required: true
    },
    labelMinMax: {
      type: Object,
      required: false
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler: 'setChartData',
    },
    barColor: {
      deep: true,
      handler: 'setBarColor',
    },
    labelMinMax: {
      deep: true,
      handler: 'setLabelMinMax',
    },
    chartLabels: {
      handler: 'setChartLabels',
    }
  },
  data () {
    return {
      value: '',
      percents: '',
      options: {
        title: {
          display: true,
          text: this.chartLabels
        },
        tooltips: {
          callbacks: {
            label: this.labelCallback,
            title: this.titleCallback,
          }
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false
              }
            }
          ],
          xAxes: [
            {
              type: 'linear',
              position: 'bottom',
              // labels: [0, 15, 25, 35, 45, 55, 65, 75, 85, 95, 105],
              // ticks: {
              //   min: 25,
              //   max: 205,
              // },
              gridLines: {
                display: true
              },
              ticks: {
                suggestedMax: this.labelMinMax.max,
                suggestedMin: this.labelMinMax.min
              }
            }
          ]
        },
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false
      },
      gradient: null
    }
  },
  mounted () {
    this.setChartData();
    this.setBarColor();
  },
  methods: {
    setChartData() {
      this.percents = this.chartData.percents;
      this.value = this.chartData.value;
      this.renderingChart();
    },
    setBarColor() {
      let ctx = this.$refs.canvas.getContext('2d');
      let gradient = ctx.createLinearGradient(0, 0, 500, 0);
      this.gradient = gradient;
      this.gradient.addColorStop(0, 'white');
      // this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
      this.gradient.addColorStop(1, this.barColor.color);
      ctx.fillStyle = gradient;
      this.renderingChart();
    },
    setLabelMinMax() {
      this.options.scales.xAxes[0].ticks.suggestedMax = this.labelMinMax.max;
      this.options.scales.xAxes[0].ticks.suggestedMin = this.labelMinMax.min;
      this.renderingChart();
    },
    setChartLabels() {
      this.options.title.text = this.chartLabels;
      this.renderingChart();
    },
    labelCallback (tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label || ''

      if (label) {
        label += ': '
      }

      label += ' / 비중 : ' + tooltipItem.value + '%';
      return label;
    },
    titleCallback (tooltipItem, data) {
      return '';
    },

    renderingChart() {
      this.renderChart({
        datasets: [
          {
            barPercentage: 0.5,
            categoryPercentage: 0.5,
            barThickness: 20,
            maxBarThickness: 20,
            borderColor: this.barColor.borderColor,
            borderWidth: 0.5,
            // backgroundColor: this.barColor.color,
            backgroundColor: this.gradient,
            label: this.chartLabels,
            data: [this.percents]
          }
        ]
      }, this.options);
    }
  }
}
</script>

<style scoped>

</style>
