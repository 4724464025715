<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">내 프로필 수정</h4>
      </template>
      <template v-slot:body>
        <b-form-group class="form-box">
          <b-form>
            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">계정 아이디</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="adminIdValid" v-model.trim="adminInfo.accountId" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="nameValid" class="detail-form-label">이름</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="nameValid" v-model.trim="adminInfo.accountName" :state="nameValidation" required></b-form-input>
                  <b-form-invalid-feedback id="nameValid" :state="nameValidation">이름은 2글자 이상이어야 합니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="nameValid" :state="nameValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="emailValid" class="detail-form-label">이메일</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="emailValid" v-model.trim="adminInfo.email" type="email" :state="emailValidation" required></b-form-input>
                  <b-form-invalid-feedback id="emailValid" :state="emailValidation">이메일 형식이 올바르지 않습니다.</b-form-invalid-feedback>
                  <b-form-valid-feedback id="emailValid" :state="emailValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">소속 고객사</label>
                  </div>
                </b-col>
                <b-col lg="2">
                  <b-form-input id="customerNameInput" v-model.trim="adminInfo.customerName" readonly></b-form-input>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">계정 타입</label>
                  </div>
                </b-col>
                <b-col lg="2">
                  <b-form-input id="adminIdValid" :value="parseAccountType()" readonly></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <b-row style="height: 10px; color: black; font-weight: bold; margin-top: 40px;">
              <b-col lg="2" style="display: flex; justify-content: flex-end;">
                <div>
                  비밀번호 변경
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="1">
              </b-col>
              <b-col lg="8" style="margin-bottom: 15px;">
                <hr>
              </b-col>
            </b-row>

            <b-form-group v-if="adminId">
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">변경할 비밀번호</label>
                  </div>
                </b-col>

                <b-col lg="7">
                  <b-form-input id="passwordValid" type="password" v-model="adminInfo.changePassword"  placeholder="비밀번호는 영문자 및 숫자를 반드시 포함하여 8자 이상으로 입력해주세요"></b-form-input>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">비밀번호 확인</label>
                  </div>
                </b-col>

                <b-col lg="7">
                  <b-form-input id="rePasswordValid" type="password" v-model="rePassword"  placeholder="비밀번호를 다시 입력해주세요"></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>

            <hr>

            <div style="display: flex; justify-content: flex-end;">
              <b-button variant="primary" class="button-style" @click="upsertAdmin()">정보 변경</b-button>
              <b-button variant="none" class="iq-bg-danger ml-3 button-style" @click="backToPage()">취소</b-button>
            </div>
          </b-form>
        </b-form-group>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import _ from "lodash";
import Swal from "sweetalert2";

export default {
  name: "AdminDetail",
  props: {
    adminId: {
      type: String,
      required: true,
    }
  },
  created() {
    if (this.$route.params && this.$route.params.adminId) {
      this.adminId = this.$route.params.adminId;
    } else {
      this.$router.push({name: 'raonfit.dashboard'});
    }
  },
  mounted() {
    this.getMyProfile();
  },
  computed: {
    accountIdValidation() {
      if (this.adminInfo.accountId) {
        const accountIdReg = new RegExp(/^([A-Za-z0-9]){4,20}$/gm);
        return !!this.adminInfo.accountId.match(accountIdReg);
      } else {
        return false;
      }
    },
    passwordValidation() {
      const pwReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
      return !!this.adminInfo.changePassword.match(pwReg);
    },
    nameValidation() {
      if (this.adminInfo.accountName) {
        return this.adminInfo.accountName.length >= 2 && this.adminInfo.accountName.length <= 20;
      } else {
        return false;
      }
    },
    emailValidation() {
      if (this.adminInfo.email) {
        const email = this.adminInfo.email;
        const emailReg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
        return !!email.match(emailReg);
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      adminInfo: {
        id: '',
        accountName: '',
        accountId: '',
        email: '',
        profileImgUrl: '',
        accountType: 'ADMIN',
        accountStatus: 'NORMAL',
        customerId: '',
        customerName: '',

        changePassword: '',
      },

      rePassword: '',

      accountStatusOptions: [
        {
          text: '정상', value: 'NORMAL'
        },
        {
          text: '계정 정지', value: 'BLOCK'
        },
      ],
      accountTypeOptions: [
        {
          text: '상위관리자',
          value: 'ADMIN',
        },
        {
          text: '일반관리자',
          value: 'NORMAL'
        }
      ],
      customerList: [],
    }
  },
  methods: {
    adminInfoValidation() {
      if (!this.adminInfo.accountName || !this.adminInfo.accountId || !this.adminInfo.email) {
        return false;
      }

      if (this.adminInfo.accountName.length < 2 || this.adminInfo.accountName.length > 20) {
        return false;
      }

      const emailReg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
      if (!this.adminInfo.email.match(emailReg)) {
        return false;
      }

      return true;
    },
    parseAccountType() {
      if (this.adminInfo.accountType === 'SUPER') {
        return '시스템관리자';
      } else if (this.adminInfo.accountType === 'ADMIN') {
        return '상위관리자';
      } else {
        return '일반관리자';
      }
    },
    upsertAdmin() {
      console.log('관리자 추가', this.adminInfo);
      if (!this.adminInfoValidation()) {
        Swal.fire({
          text: '입력한 정보가 잘못되었습니다',
          icon: 'error',
          confirmButtonText: '확인',
        });
        return;
      }

      if (this.adminInfo.changePassword) {
        const password = this.adminInfo.changePassword;
        const pwReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
        if (!password.match(pwReg)) {
          Swal.fire({
            text: '비밀번호를 변경하려면 영문자, 숫자를 포함하여 8자 이상으로 입력해야 합니다',
            icon: 'error',
            confirmButtonText: '확인',
          });
          return;
        }

        if (this.adminInfo.changePassword !== this.rePassword) {
          Swal.fire({
            text: '입력한 비밀번호와 재입력한 비밀번호가 다릅니다.',
            icon: 'error',
            confirmButtonText: '확인',
          });
          return;
        }
      }

      const editAccountName = this.adminInfo.accountName;
      const editEmail = this.adminInfo.email;

      Swal.fire({
        text: '프로필 정보를 수정합니다',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#089BAB',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: '수정',
        cancelButtonText: '취소',
      }).then(result => {
        if (result.value) {
          this.$axios.put('/admin/upsert', this.adminInfo)
            .then(result => {
              console.log(result);

              Swal.fire('', '정보를 저장하였습니다', 'success')
                .then(t => {
                  const editData = {accountName: editAccountName, email: editEmail};
                  this.$store.dispatch('Auth/setAdminNameAction', editData);
                  this.backToPage();
                })
                .catch(c => {
                  this.backToPage();
                });
            })
            .catch(error => {
              console.log(error.response, error.request, error.message);
              let errorText = '';
              if (error.message ===  '4005' || error.message === '4000') {
                errorText = '입력한 정보가 잘못되었습니다. 다시 시도해주세요.';
              } else if (error.message === '4010') {
                errorText = '정보를 변경할 권한이 없습니다';
              } else if (error.message === '4009') {
                errorText = '이미 사용중인 아이디가 있습니다 다른 아이디로 등록하세요'
              } else if (error.message === '4016') {
                errorText = '이미 있는 메일 주소이므로 저장할 수 없습니다'
              } else {
                errorText = '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해 주세요';
              }

              Swal.fire('관리자 정보 저장 오류', errorText, 'error');
            });
        }
      });
    },
    backToPage() {
      if (this.adminInfo.accountType === 'SUPER') {
        this.$router.push({name: 'raonfit.super.dashboard'});
      } else {
        this.$router.push({name: 'raonfit.dashboard'});
      }
    },

    /**
     *
     */
    getMyProfile() {
      this.$axios.get('/admin/my/profile')
        .then(result => {
          console.log(result);
          const adminInfo = result.data;
          this.adminInfo = {
            ...adminInfo,
            changePassword: '',
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  },
}
</script>

<style scoped>
.button-style {
  width: 5vw;
  height: 5vh;
}
</style>
