<template>
  <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <li v-if="role === 'SUPER' || checkRole(item.permit_role)" v-for="(item,index) in items" :key="index" :class="item.is_heading ? 'iq-menu-title' :activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''">
      <i v-if="item.is_heading" class="ri-subtract-line" />
<!--      <span v-if="item.is_heading">{{ $t(item.name) }}</span>-->
      <div v-if="item.is_heading" class="line" style="border-bottom: 1px solid white; margin-top: -3px; margin-bottom: 25px; height: 30px;">
        <span v-if="item.is_heading" style="font-family: 'nanumsquareR'; font-size: 16px;">{{ header }}</span>
      </div>
      <router-link :to="item.link" v-if="!item.is_heading" :class="`iq-waves-effect ${activeLink(item) && item.children ? 'active' : activeLink(item) ? 'active' : ''}`" v-b-toggle="item.name">
        <i :class="item.icon" v-if="item.is_icon_class"/>
        <template v-else v-html="item.icon">
        </template>
        <span>{{ $t(item.name) }}</span>
        <i v-if="item.children" class="ri-arrow-right-s-line iq-arrow-right" />
        <small v-html="item.append" :class="item.append_class" />
      </router-link>
      <List v-if="item.children" :items="item.children" :open="item.link.name !== '' && activeLink(item) && item.children ? true : !!(item.link.name !== '' && activeLink(item))" :idName="item.name" :accordianName="`sidebar-accordion ${item.class_name}`" :className="`iq-submenu ${item.class_name}`" />
    </li>
  </b-collapse>
</template>
<script>
import List from './ListStyle1'
import { xray } from '@/config/pluginInit'
import {commons} from "@/Utils/Commons";
export default {
  name: 'List',
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu' },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' },
    header: { type: String, default: 'Raonfit' },
  },
  components: {
    List
  },
  mounted () {
    const authToken = window.sessionStorage.getItem('Authorization');
    const adminInfo = commons.parseAuthTokenToAdminInfo(authToken);
    this.role = adminInfo.role;
  },
  data() {
    return {
      role: '',
    }
  },
  methods: {
    checkRole(permitRole) {
      if (this.role === 'SUPER') return true;
      if (permitRole) {
        let permit = false;
        permitRole.forEach(r => {
          if (this.role === r) {
            permit = true;
          }
        });

        return permit;
      } else {
        return true;
      }
    },
    activeLink (item) {
      return xray.getActiveLink(item, this.$route.name);
    }
  }
}
</script>
