<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <UserSearch
              :search-type="'CUSTOMER'"
              @selectedItem="selectedItem"
              @initSearch="initSearch"
            ></UserSearch>
          </b-col>
          <b-col>
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px;" @click="addCustomer()">고객사 추가</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">고객사 목록</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="12">
            <b-table striped hover
                     :items="customers"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="customerFilter"
                     :filter-included-fields="filterOn"
                     @filtered="onFiltered"
                     stacked="md"
                     show-empty
            >
              <template #cell(enabled)="row">
                <div v-if="row.value === true">정상</div>
                <div v-else style="color: red">정지</div>
              </template>
              <template #cell(actions)="row">
                <b-button pill variant="primary" style="width: 100px; height: 35px;" @click="row.toggleDetails" class="mr-3">
                  {{ row.detailsShowing ? '상세 닫기' : '상세 보기' }}</b-button>
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="editCustomer(row.item, row.index, $event.target)" class="mr-1">수정</b-button>
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="deleteCustomer(row.item, row.index, $event.target)" class="mr-1">삭제</b-button>
              </template>

              <template #cell(memoIcon)="row">
                <div style="margin-top: -9px; float: right;">
                  <b-link v-if="row.item.memo" @click="$bvModal.show('customerMemo-' + row.item.id)" href="#"><i class="ri-file-list-line" style="font-size: 25px;"></i></b-link>
                </div>

                <b-modal style="width: 600px; border-radius: 10px;" :id="'customerMemo-' + row.item.id" centered scrollable :title="row.item.customerName + ' 메모'" ok-title="닫기" ok-only>
                  <textarea style="padding: 10px;" class="my-4" cols="60" rows="5" v-html="row.item.memo" readonly disabled></textarea>
                </b-modal>
              </template>

              <template #cell(customerName)="data">
                <a @click="`${goToAdminList(data)}`" style="cursor:pointer;">{{ data.value }}</a>
              </template>

              <template #row-details="row">
                <b-card>
                  <div class="device-contents-main">
                    <div class="device-contents-title" style="font-size: 1.3em; font-weight: bold; font-family: 'nanumsquareB'; color: #555555; margin-bottom: 10px;">
                      운영 품목 리스트
                    </div>

                    <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;">
                      <div class="device-contents-body"
                           v-for="(addInfo, idx) in row.item.additionalInfoList"
                           :key="idx"
                           style="width: 190px; padding: 10px 20px; background-color: #9999991F; border-radius: 30px; color: #666666; margin-bottom: 20px; font-size: 1.1em; margin-right: 15px;">
                        <div class="device-info">
                          <div style="font-weight: bold; font-size: 1.2em;">{{ addInfo.deviceName }}</div>
                          <div style="display: flex; justify-content: flex-start; align-items: center;">
                            <div style="flex-grow: 2.8">설치일</div>
                            <div style="flex-grow: 1">{{ addInfo.supplyDate ? $moment(addInfo.supplyDate, 'YYYYMMDD').format('YYYY.MM.DD') : '-' }}</div>
                          </div>
                          <div style="display: flex; justify-content: flex-start; align-items: center;">
                            <div style="flex-grow: 1.3">AS기간</div>
                            <div style="flex-grow: 1">{{ addInfo.remainAsPeriod }}일 남음</div>
                          </div>
                        </div>

                        <div class="contents-title" style="font-weight: bold; margin-top: 5px; margin-bottom: 5px;">컨텐츠 목록</div>
                        <div class="contents-list-info" style="display: flex; flex-wrap: wrap; flex-direction: column; justify-content: flex-start; align-items: flex-start; margin-bottom: 10px;">
                          <div v-for="(c, idx) in addInfo.contents" :key="'c' + idx">
                            <b-badge pill :variant="c.use ? 'primary' : 'secondary'"
                                     style="margin-right: 5px;">{{ c.name }}</b-badge>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </template>

              <template #empty="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>데이터가 없습니다.</h5>
                </div>
              </template>
              <template #emptyfiltered="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>검색 결과가 없습니다.</h5>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="ma-0 pa-3"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import IqCard from '@/components/xray/cards/iq-card'
import UserSearch from '@/views/Raonfit/template/Search'
import Swal from "sweetalert2";

export default {
  name: "CustomerList",
  components: {
    IqCard,
    UserSearch,
  },
  mounted() {
    this.findAllCustomers();
  },
  data() {
    return {
      fields: [
        {
          key: 'memoIcon', label: ''
        },
        {
          key: 'customerName',
          label: '고객사명',
          sortable: true,
        },
        {
          key: 'siteName',
          label: '사이트명',
          sortable: true,
          formatter: (value, key, item) => {
            return value ? value : '-';
          }
        },
        {
          key: 'address',
          label: '주소지',
          sortable: false,
        },
        {
          key: 'enabled',
          label: '상태',
          sortable: true,
          // formatter: (value, key, item) => {
          //   return value ? '정상' : '정지';
          // }
        },
        {
          key: 'registerDate',
          label: '등록일자',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'actions', label: ''
        }
      ],

      customers: [],
      customerFilter: null,
      filterOn: ['customerName'],

      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  methods: {
    findAllCustomers() {
      this.$axios.get('/customer/list/all')
      .then(result => {
        console.log(result);
        const res = result.data;
        if (res.total > 0) {
          this.totalRows = res.total;
          this.customers = res.customers;
        } else {
          this.totalRows = 0;
        }
      })
      .catch(err => {
        console.log(err);
      });
    },
    selectedItem(customer) {
      if (customer.size === 1) {
        this.customerFilter = customer.value.customerName;
      } else {
        this.customerFilter = customer.search;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    initSearch() {
      this.filterOn = [];
      this.customerFilter = null;
    },

    addCustomer() {
      const customerInfo = { newCustomer: true };
      this.$router.push({name: 'raonfit.super.customerDetail', params: customerInfo});
    },
    editCustomer(item, index, button) {
      const customerInfo = {
        newCustomer: false,
        id: item.id,
        customerName: item.customerName,
        address: item.address,
        bannerUrl: item.bannerUrl,
        logoUrl: item.logoUrl,
        enabled: item.enabled,
        siteName: item.siteName,
        memo: item.memo,
      };

      this.$router.push({name : 'raonfit.super.customerDetail', params: customerInfo});
    },
    deleteCustomer(item, index, button) {
      Swal.fire({
        title: "고객사 정보 삭제",
        text: "고객사를 삭제하면 정보를 복구할 수 없습니다. 또한 고객사 유저가 있을 경우에는 삭제할 수 없습니다",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#089BAB",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`/customer/delete/${item.id}`)
            .then(result => {
              console.log(result);
              if (result.data === 'DELETED') {
                Swal.fire({html: '선택한 고객사 정보를 삭제하였습니다.', icon: 'success'})
                  .then(t => {
                    this.findAllCustomers();
                  }).catch(c => {
                  this.findAllCustomers();
                })
              }

            })
            .catch(error => {
              let text = error.message;
              let errorText = '';
              if (error.message === '4015') {
                errorText = '고객사에 사용자가 있는 경우에는 삭제할 수 없습니다';
              }

              Swal.fire('고객사 삭제 오류', text, 'error');
            })
        }
      });
    },
    goToAdminList(data) {
      console.log(data);
      this.$router.push({name: 'raonfit.super.adminList', params: { selectCustomer: data.item.customerName}} );
    }
  }
}
</script>

<style scoped>

</style>
