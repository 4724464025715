<template>
  <div class="date-search-div">

    <div class="date-search-item">
      <label>시작일</label>
      <b-form-input id="SearchStartDate"
                    type="date"
                    v-model="period.start"
      ></b-form-input>
    </div>

    <div class="date-search-item">
      <label>종료일</label>
      <b-form-input id="SearchEndDate"
                    type="date"
                    v-model="period.end"
      ></b-form-input>
    </div>

    <div class="search-radio-items">
      <template v-for="(item, index) in selectPeriod">
        <b-form-radio inline v-model="selectedRadio" :name="item.name" :key="index" :value="item.value" @change="changeValue">{{ item.label }}</b-form-radio>
      </template>
    </div>

    <div v-if="searchButton" class="search-button">
      <b-button fill variant="primary" class="btn" @click="searchAction">검색</b-button>
    </div>

  </div>
</template>

<script>
export default {
  name: "DateSearch",
  props: {
    searchButton: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  mounted() {
    this.period.start = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
    this.period.end = this.$moment().format('YYYY-MM-DD');

    if (!this.searchButton) {
      this.changeValue('THIS_MONTH');
    }
  },
  watch: {
    selectedRadio(value) {
      if (value === 'THIS_MONTH') {
        this.period.start = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
        this.period.end = this.$moment().format('YYYY-MM-DD');
      } else if (value === '3_MONTH') {
        this.period.start = this.$moment().subtract(3, 'month').format('YYYY-MM-DD');
        this.period.end = this.$moment().format('YYYY-MM-DD');
      } else {
        this.period.start = this.$moment().subtract(2, 'month').format('YYYY-MM-DD');
        this.period.end = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
      }
    },
    period: {
      deep: true,
      handler: 'searchAction',
    }
  },
  data() {
    return {
      period: {
        start: '',
        end: '',
      },

      selectedRadio: 'THIS_MONTH',

      selectPeriod: [
        {
          name: 'period',
          label: '1개월',
          value: 'THIS_MONTH'
        },
        {
          name: 'period',
          label: '3개월',
          value: '3_MONTH'
        },
        {
          name: 'period',
          label: '지난달',
          value: 'LAST_MONTH'
        },
      ],
    }
  },
  methods: {
    searchAction() {
      this.$emit('searchByPeriod', this.period);
    },
    changeValue(value) {
      if (!this.searchButton) {
        this.$emit('searchByPeriod', this.period);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.date-search-div {
  @media (max-width: 1560px) {
    margin-left: 10px;

    .date-search-item {
      width: 100px;
      margin-right: 10px;
      flex-basis: 50px;
      flex-grow: 3;
    }

    .search-radio-items {
      width: 100px;
      margin-bottom: 5px;
      flex-basis: 50px;
      flex-grow: 5;
    }
  }

  display: flex;
  align-items: flex-end;
  margin-left: 40px;
}

.date-search-item {
  width: 200px;
  margin-right: 20px;
  flex-basis: 100px;
  flex-grow: 3;
}

.search-radio-items {
  width: 200px;
  margin-bottom: 10px;
  flex-basis: 100px;
  flex-grow: 5;
}

.search-button {
  flex-basis: 70px;

  .btn {
    width: 70px;
    height: 45px;
  }
}
</style>
