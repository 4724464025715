<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">컨텐츠 {{contentsInfo.newContents ? '추가' : '수정'}}</h4>
      </template>
      <template v-slot:body>
        <div class="form-box">
          <b-form>
            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="nameValid" class="detail-form-label">컨텐츠 이름</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="nameValid" v-model.trim="contentsInfo.contentsName" :state="nameValidation" required></b-form-input>
                  <b-form-invalid-feedback id="nameValid" :state="nameValidation">컨텐츠 이름은 필수항목입니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="nameValid" :state="nameValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label for="versionValid" class="detail-form-label">컨텐츠 버전</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="versionValid" v-model.trim="contentsInfo.version" :state="versionValidation" required></b-form-input>
                  <b-form-invalid-feedback id="versionValid" :state="versionValidation">컨텐츠 버전은 필수항목입니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="versionValid" :state="versionValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <hr>

            <div style="display: flex; justify-content: flex-end;">
              <b-button variant="primary" class="button-style" @click="upsertContents()">저장</b-button>
              <b-button variant="none" class="iq-bg-danger ml-3 button-style" @click="goToContentsList()">취소</b-button>
            </div>
          </b-form>
        </div>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "ContentsDetail",
  created() {
    console.log(this.$route);
    if (this.$route.params && this.$route.params.contentsId) {
      const params = this.$route.params;
      console.log('수정 -> ', params);
      this.contentsInfo = { newContents: false, ...params };
    }
  },
  mounted() {
  },
  data() {
    return {
      contentsInfo: {
        newContents: true,
        contentsId: '',
        contentsName: '',
        version: '',
      },
    }
  },
  computed: {
    nameValidation() {
      return !!this.contentsInfo.contentsName;
    },
    versionValidation() {
      return !!this.contentsInfo.version;
    },
  },
  methods: {
    upsertValidation() {
      return !(!this.contentsInfo.contentsName || !this.contentsInfo.version);

    },
    upsertContents() {
      if (this.contentsInfo.newContents === false && !this.contentsInfo.contentsId) return;
      if (!this.upsertValidation()) return;

      const data = {
        ...this.contentsInfo,
      }

      Swal.fire({
        text: '컨텐츠 정보를 저장합니다',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#089BAB',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: '저장',
        cancelButtonText: '취소',
      }).then(result => {
        if (result.value) {
          this.$axios.put('/contents/upsert', data)
            .then(result => {
              console.log(result);

              Swal.fire('', '컨텐츠 정보를 저장하였습니다', 'success')
                .then(t => {
                  this.goToContentsList();
                })
                .catch(c => {
                  this.goToContentsList();
                });
            })
            .catch(error => {
              console.log(error.response, error.request, error.message);
              let errorText = '';
              if (error.message ===  '4019') {
                errorText = '컨텐츠 이름이 중복되어 등록할 수 없습니다.';
              } else {
                errorText = '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해 주세요';
              }

              Swal.fire('컨텐츠 정보 저장 오류', errorText, 'error');
            });
        }
      })
    },
    backToPage() {
      this.$router.back();
    },
    goToContentsList() {
      this.$router.push({name: 'raonfit.super.contentsList'});
    },

  }
}
</script>

<style scoped lang="scss">
.button-style {
  width: 5vw;
  height: 5vh;
}

</style>
