<template>
  <div>
    <section class="sign-in-page">
      <b-container class="sign-in-page-bg mt-0 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white">
              <div class="sign-in-logo mb-5">
                <div class="logo-inner-div">
                  <img :src="logo" alt="logo" class="logo-image" style="margin: 0 auto; height: 60px; object-fit: contain;">
                </div>
              </div>
              <Slick :option="slickOptions">
                <div class="item">
<!--                  <img :src="require('../../assets/images/login/no_image.jpg')" class="img-fluid border-rounded mb-4"-->
<!--                       alt="logo">-->
                  <img src="https://cms.raonfit.com:8000/default/image01.png" class="img-fluid border-rounded mb-4"
                       style="width: 370px; height: 350px; object-fit: cover;" alt="logo">
                  <h4 class="mb-1 text-white">제주 번개레이싱</h4>
                  <br>
                </div>
                <div class="item">
                  <img src="https://cms.raonfit.com:8000/default/image02.png" class="img-fluid border-rounded mb-4"
                       style="width: 370px; height: 350px; object-fit: cover;" alt="logo">
                  <h4 class="mb-1 text-white">반응형 증강현실</h4>
                  <br>
                </div>
                <div class="item">
                  <img src="https://cms.raonfit.com:8000/default/image03.png" class="img-fluid border-rounded mb-4"
                       style="width: 370px; height: 350px; object-fit: cover;" alt="logo">
                  <h4 class="mb-1 text-white">미래 자동차 체험</h4>
                  <br>
                </div>
              </Slick>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/xray/loader/Loader'
import logo from '../../assets/images/raonfit-logo.png'
import {xray} from '../../config/pluginInit'
import bgImage from '../../assets/images/login/2.png'

export default {
  name: 'AuthLayout1',
  components: {
    Loader
  },
  mounted() {
    xray.index()
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
        dots: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>

<style lang="scss" scoped>
.border-rounded {
  border-radius: 15px;
}

.logo-inner-div {
  background-color: #FFFFFF;
  width: 230px;
  height: 80px;
  padding: 10px;
  border-radius: 20px;
  margin: 0 auto;
}

.logo-image {

}

</style>
