<template>
  <b-container fluid>
    <b-overlay
      :show="pdfLoading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <UserSearch
              @selectedItem="selectedUserData"
              :init-button="false"
              :show-default-value="true"
            ></UserSearch>
          </b-col>
          <b-col lg="6" style="display: inline-flex; justify-content: flex-end;" v-if="userInfo.id != null">
            <b-button variant="primary" class="mb-0 mr-3" style="font-size: 1em; height: 50px;" @click="goToFitData">
              운동분석
            </b-button>
            <b-button variant="primary" class="mb-0 mr-3" style="font-size: 1em; height: 50px;" @click="downloadToPdf">
              <template v-if="pdfLoading">
                <b-spinner small type="grow"></b-spinner>
                PDF 생성중...
              </template>
              <template v-else>
                <i class="far fa-file-pdf"></i>PDF 다운로드
              </template>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <div ref="healthLogBody" style="margin: 0 auto;">
      <div style="margin: 0 auto;">
        <iq-card class-name="iq-card-block iq-card-height" id="inbodyCard">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 인바디 데이터</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12">
                <b-row id="inbodyLabel" style="padding-top: 1rem; padding-left: 2rem;">
                  <b-col lg="6">
                    <div class="chart-label-div">
                      <div class="chart-label-inner-div"
                           :style="{backgroundColor: colorCode.LOWER_STANDARD_TRANSPARENT, borderColor: colorCode.LOWER_STANDARD}"></div>
                      <div>표준이하</div>
                      <div class="chart-label-inner-div"
                           :style="{backgroundColor: colorCode.STANDARD_TRANSPARENT, borderColor: colorCode.STANDARD}"></div>
                      <div>표준</div>
                      <div class="chart-label-inner-div"
                           :style="{backgroundColor: colorCode.UPPER_STANDARD_TRANSPARENT, borderColor: colorCode.UPPER_STANDARD}"></div>
                      <div>표준이상</div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="6">
                    <div>
                      <HorizontalBarChart :chart-data="inbodyData.weight"
                                          :chart-labels="inbodyData.weight.label"
                                          :bar-color=setBarColor(inbodyData.weight)
                                          :label-min-max="inbodyData.weight.minMax"
                                          :style="{height: barGraphHeight}"
                                          ref="inbody1"
                      ></HorizontalBarChart>
                    </div>

                    <div>
                      <HorizontalBarChart :chart-data="inbodyData.skeletalMuscleMask"
                                          :chart-labels="inbodyData.skeletalMuscleMask.label"
                                          :bar-color=setBarColor(inbodyData.skeletalMuscleMask)
                                          :label-min-max="inbodyData.skeletalMuscleMask.minMax"
                                          :style="{height: barGraphHeight}"
                                          ref="inbody2"
                      ></HorizontalBarChart>
                    </div>

                    <div>
                      <HorizontalBarChart :chart-data="inbodyData.bodyFatMass"
                                          :chart-labels="inbodyData.bodyFatMass.label"
                                          :bar-color=setBarColor(inbodyData.bodyFatMass)
                                          :label-min-max="inbodyData.bodyFatMass.minMax"
                                          :style="{height: barGraphHeight}"
                                          ref="inbody3"
                      ></HorizontalBarChart>
                    </div>
                  </b-col>

                  <b-col lg="6">
                    <div>
                      <HorizontalBarChart :chart-data="inbodyData.bodyMassIndex"
                                          :chart-labels="inbodyData.bodyMassIndex.label"
                                          :bar-color=setBarColor(inbodyData.bodyMassIndex)
                                          :label-min-max="inbodyData.bodyMassIndex.minMax"
                                          :style="{height: barGraphHeight}"
                                          ref="inbody4"
                      ></HorizontalBarChart>
                    </div>

                    <div>
                      <HorizontalBarChart :chart-data="inbodyData.percentBodyFat"
                                          :chart-labels="inbodyData.percentBodyFat.label"
                                          :bar-color=setBarColor(inbodyData.percentBodyFat)
                                          :label-min-max="inbodyData.percentBodyFat.minMax"
                                          :style="{height: barGraphHeight}"
                                          ref="inbody5"
                      ></HorizontalBarChart>
                    </div>

                    <div>
                      <HorizontalBarChart :chart-data="inbodyData.waistHipRatio"
                                          :chart-labels="inbodyData.waistHipRatio.label"
                                          :bar-color=setBarColor(inbodyData.waistHipRatio)
                                          :label-min-max="inbodyData.waistHipRatio.minMax"
                                          :style="{height: barGraphHeight}"
                                          ref="inbody6"
                      ></HorizontalBarChart>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>

        <b-row id="bodyChangeDiv">
          <b-col lg="12">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 신체변화 데이터</h4>
              </template>
              <template v-slot:body>
                <div>
                  <b-row>
                    <b-col lg="8" md="12" sm="12">
                      <DateSearch
                        @searchByPeriod="searchChangedBodyData"
                      ></DateSearch>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="12" ref="bodyChart">
                      <MultiAxisChart ref="amchart" :element="bodyChangeData.element" :option="bodyChangeData.bodyData"/>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>

        <b-row id="thirdRowHealthLog">
          <b-col lg="4">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 체성분 데이터</h4>
              </template>

              <template v-slot:body>
                <div id="bodyCompositeData" class="d-flex align-items-center justify-content-between">
                  <b-col md="6">
                    <div class="data-block mt-3">
                      <p class="mb-0">체중</p>
                      <h5>{{ bodyComposition.weight || 0 }} kg</h5>
                    </div>
                    <div class="data-block mt-3">
                      <div class="mb-0">내장지방레벨</div>
                      <h5>{{ bodyComposition.vfl }} Level</h5>
                    </div>
                    <div class="data-block mt-3">
                      <p class="mb-0">체지방</p>
                      <h5>{{ bodyComposition.bfm }} kg</h5>
                    </div>
                    <div class="data-block mt-3">
                      <p class="mb-0">체중 조절량</p>
                      <h5>{{ Math.abs(bodyComposition.wc) }} kg {{ bodyComposition.wc > 0 ? '증가 요함' : '감량 요함' }}</h5>
                    </div>
                    <div class="data-block mt-3">
                      <p class="mb-0">근육 조절량</p>
                      <h5>{{ Math.abs(bodyComposition.mc) }} kg {{ bodyComposition.mc > 0 ? '증가 요함' : '감량 요함' }}</h5>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="data-block mt-3">
                      <p class="mb-0">키</p>
                      <h5>{{ bodyComposition.height }} cm</h5>
                    </div>
                    <div class="data-block mt-3">
                      <p class="mb-0">기초대사량</p>
                      <h5>{{ bodyComposition.bmr }} Kcal</h5>
                    </div>
                    <div class="data-block mt-3">
                      <p class="mb-0">제지방량</p>
                      <h5>{{ bodyComposition.ffm }} kg</h5>
                    </div>
                    <div class="data-block mt-3">
                      <p class="mb-0">지방 조절량</p>
                      <h5>{{ Math.abs(bodyComposition.fc) }} kg {{ bodyComposition.fc > 0 ? '증가 요함' : '감량 요함' }}</h5>
                    </div>
                    <div class="data-block mt-3">
                      <p class="mb-0">복부지방률</p>
                      <h5>{{ bodyComposition.whr }} ratio</h5>
                    </div>
                  </b-col>
                </div>
                <!--              <div>-->
                <!--                <b-col lg="12">-->
                <!--                  <div class="data-block mt-3">-->
                <!--                    <p class="mb-0">인바디</p>-->
                <!--                    <h5>{{ bodyComposition.interpretation }} kg</h5>-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <!--              </div>-->
              </template>
            </iq-card>
          </b-col>


          <b-col lg="3">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 체성분 종합 점수</h4>
              </template>
              <template v-slot:body style="min-width: 300px;">
                <b-col lg="12">
                  <div id="totalChart" style="height: 333px; margin: 0 auto; display: flex; justify-content: center; align-items: center;">
                    <apexchart ref="ftrc" :options="fitTotalRadialChart" height="250"
                               :series="fitTotalRadialChart.series"/>
                  </div>
                </b-col>
              </template>
            </iq-card>
          </b-col>

          <b-col lg="5">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 체력 차트</h4>
              </template>
              <template v-slot:body>
                <b-col lg="12">
                  <div id="healthRadioChart" class="health-chart">
                    <apexchart width="600" ref="uhro" :options="userHealthRadarOpt"
                               :series="userHealthRadarOpt.series"/>
                  </div>
                </b-col>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </div>
    </div>
    </b-overlay>

    <vue-html2pdf
      v-if="pdfShowLayout"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="userInfo.name + '님의 체력분석'"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1100px"
      ref="html2Pdf"
    >
      <section id="pdfContentsSection" slot="pdf-content" ref="pdfContents">
      </section>
    </vue-html2pdf>
  </b-container>
</template>

<script>
import {xray} from '@/config/pluginInit'
import HorizontalBarChart from '@/views/Raonfit/template/HorizontalBarChart'
import {commons} from '@/Utils/Commons'
import UserSearch from '@/views/Raonfit/template/Search'
import jsPDF from 'jspdf'
import IqCard from '@/components/xray/cards/iq-card'
import DateSearch from '@/views/Raonfit/template/DateSearch'
import MultiAxisChart from "@/views/Raonfit/template/MultiAxisChart";
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: 'Raonfit-HealthLog',
  components: {
    IqCard,
    HorizontalBarChart,
    UserSearch,
    DateSearch,
    MultiAxisChart,
    VueHtml2pdf,
  },
  props: {},
  created() {

  },
  mounted() {
    xray.index();

    this.periodStart = this.$moment().subtract(1, 'month').format('YYYY-MM-DD');
    this.periodEnd = this.$moment().format('YYYY-MM-DD');

    const searchUser = this.$store.getters['User/searchUserState'];
    if (searchUser && searchUser.id) {
      this.userInfo = {
        id: searchUser.id,
        userId: searchUser.userId,
        name: searchUser.name,
      }

      this.searchInbodyData();
      this.searchHealthChangeData();
    }
  },
  computed: {
    checkDateState() {
      if (this.periodStart > this.periodEnd) {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      userInfo: {
        id: null,
        name: '',
      },
      colorCode: commons.colorCode,
      period: 'last-month',
      loaded: false,
      barGraphHeight: '120px',
      periodStart: '',
      periodEnd: '',
      inbodyData: {
        weight: {
          minMax: {
            min: 0,
            max: 0
          },
          label: '체중(kg)',
          standardRange: {
            min: 85,
            max: 115
          },
          percents: 0,
          value: 0,
        },
        skeletalMuscleMask: {
          minMax: {
            min: 0,
            max: 0,
          },
          label: '골격근량(kg)',
          standardRange: {
            min: 90,
            max: 110
          },
          percents: 0,
          value: 0,
        },
        bodyFatMass: {
          minMax: {
            min: 0,
            max: 0,
          },
          label: '체지방량(kg)',
          standardRange: {
            min: 80,
            max: 160
          },
          percents: 0,
          value: 0,
        },
        bodyMassIndex: {
          minMax: {
            min: 0,
            max: 0,
          },
          label: 'BMI(kg/㎡)',
          standardRange: {
            min: 85,
            max: 115
          },
          percents: 0,
          value: 0,
        },
        percentBodyFat: {
          minMax: {
            min: 0,
            max: 0,
          },
          label: '체지방률(%)',
          standardRange: {
            min: 85,
            max: 115
          },
          percents: 0,
          value: 0,
        },
        waistHipRatio: {
          minMax: {
            min: 0,
            max: 0,
          },
          label: '복부지방률(%)',
          standardRange: {
            min: 0.3,
            max: 0.7
          },
          percents: 0,
          value: 0,
        }
      },
      items: [],
      labels: [],
      bodyChangeData: {
        element: 'chartdiv',
        type: 'mva',
        bodyData: {
          data: [{measureDate: '', weight: 0, smm: 0, bfm: 0, bmi: 0}]
        }
      },

      output: null,
      pdfLoading: false,

      // 체성분 데이터
      bodyComposition: {
        vfl: 0,
        wc: 0,
        mc: 0,
        height: 0,
        ffm: 0,
        bmr: 0,
        bfm: 0,
        fc: 0,
        whr: 0,
        weight: 0,
      },

      fitTotalRadialChart: {
        series: [0],
        colors: ['#0084ff'],
        chart: {
          id: 'fitTotalRadialChart',
          width: 50,
          type: 'radialBar'
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['종합 점수'],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: '10px',
              size: '80%'
            },
            dataLabels: {
              name: {
                offsetY: -20,
                fontSize: '16px'
              },
              value: {
                fontSize: '30px',
                show: true,
                formatter: function (val) {
                  return parseInt(val);
                }
              },
            }
          }
        },
      },

      userHealthRadarOpt: {
        series: [{
          name: '',
          data: [0, 0, 0, 0, 0]
        }],
        colors: ['#775DD0'],
        chart: {
          type: 'radar',
          height: 380,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          },
        },
        xaxis: {
          categories: ['심폐지구력', '균형감', '순발력', '유연성', '근력/근지구력'],
          labels: {
            show: true,
            style: {
              colors: ['#000000', '#000000', '#000000', '#000000', '#000000'],
              fontSize: '15px',
              fontFamily: 'NanumSquareR'
            }
          },
          tickAmount: 3,
        },
        yaxis: {
          show: false,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '15px',
            fontFamily: 'NanumSquareR',
          },
        },
        fill: {
          opacity: 0.3
        },
        dataLabels: {
          enabled: true
        },
        plotOptions: {
          radar: {
            strokeColor: '#111111'
          },
          size: 350,
        }
      },

      pdfShowLayout: false,

    }
  },
  watch: {},
  methods: {
    setBarColor(data) {
      const value = data.percents;
      const min = data.standardRange.min;
      const max = data.standardRange.max;

      if (value < min) {
        return {
          borderColor: this.colorCode.LOWER_STANDARD,
          color: this.colorCode.LOWER_STANDARD_TRANSPARENT
        }
      } else if (value >= min && value <= max) {
        return {
          borderColor: this.colorCode.STANDARD,
          color: this.colorCode.STANDARD_TRANSPARENT
        }
      } else {
        return {
          borderColor: this.colorCode.UPPER_STANDARD,
          color: this.colorCode.UPPER_STANDARD_TRANSPARENT
        }
      }
    },


    /**
     * 사용자 검색
     */
    selectedUserData(user) {
      this.userInfo = {
        id: user.value.id,
        userId: user.value.userId,
        name: user.value.userName,
      };

      this.$store.dispatch('User/saveSearchUserAction', this.userInfo);

      this.searchInbodyData();
      this.searchHealthChangeData();
    },

    searchInbodyData() {
      /*
       인바디 데이터 검색
       */
      const id = this.userInfo.id;
      if (id) {
        this.$axios.get(`/healthLog/inbody/user/${id}`)
          .then(result => {
            const data = result.data;

            if (!data.empty) {
              const healthChartData = data.healthChart;
              const inbodyDatum = data.inbodyDatum;
              const bodyComposition = data.bodyComposition;

              const measureDate = data.measureDate;
              const totalBodyScore = data.totalBodyScore;
              const userId = data.userId;
              const userName = data.userName;

              // inbody Data
              this.setInbodyData(inbodyDatum);
              this.setHealthChart(healthChartData);
              this.$refs.ftrc.updateSeries([totalBodyScore]);

              // 체성분
              this.bodyComposition = {
                ...bodyComposition,
              };


            }

          })
          .catch(error => {
            console.log(error);
          })
      }
    },

    searchChangedBodyData(period) {
      this.periodStart = period.start;
      this.periodEnd = period.end;

      if (this.userInfo.id) {
        this.searchHealthChangeData();
      }
    },

    setHealthChart(data) {
      /*
      체성분 데이터
      */
      const series = [];
      series.push(data.ce);
      series.push(data.balance);
      series.push(data.wits);
      series.push(data.flex);
      series.push(data.me);

      // this.userHealthRadarOpt.series[0].data = series;
      this.$refs.uhro.updateSeries([{data: series}]);
    },

    searchHealthChangeData() {
      /*
      신체변화 데이터
       */
      let startMoment = this.$moment(this.periodStart, 'YYYY-MM-DD');
      let endMoment = this.$moment(this.periodEnd, 'YYYY-MM-DD');
      let dateArr = [];

      let s = new Date(startMoment.format('YYYY-MM-DD'));
      let e = new Date(endMoment.format('YYYY-MM-DD'));

      while (s <= e) {
        dateArr.push(this.$moment(s).format('YYYYMMDD'));
        let newDate = s.setDate(s.getDate() + 1);
        s = new Date(newDate);
      }

      // const end = _.replace(this.periodEnd, '-', '');
      const start = this.periodStart.replaceAll('-', '');
      const end = this.periodEnd.replaceAll('-', '');
      const id = this.userInfo.id;
      this.$axios.get(`/healthLog/change/user/${id}/${start}/${end}`)
        .then(result => {
          const data = result.data;
          if (data.length > 0) {
            const array = [];
            dateArr.forEach(da => {
              const item = {
                measureDate: this.$moment(da, 'YYYYMMDD').format('YYYY-MM-DD'),
                smm: 0,
                bfm: 0,
                bmi: 0,
                weight: 0,
              };

              data.forEach(d => {
                if (da === d.measureDate) {
                  item.measureDate = this.$moment(d.measureDate, 'YYYYMMDD').format('YYYY-MM-DD-');
                  item.smm = d.smm;
                  item.bfm = d.bfm;
                  item.bmi = d.bmi;
                  item.weight = d.weight;
                }
              });

              array.push(item);
            });
            this.bodyChangeData.bodyData.data = array;
            return data;
          } else {
            return null;
          }
        })
        .catch(error => {
          console.log(error);
          return null;
        })
    },

    setInbodyData(data) {
      this.inbodyData = {
        weight: {
          minMax: {
            min: 55,
            max: 210,
          },
          // minMax: {
          //   min: data.minWeight,
          //   max: data.maxWeight,
          // },
          label: `체중 (${data.weight} kg)`,
          standardRange: {
            min: 85,
            max: 115
          },
          percents: data.weightPercent,
          value: data.weight,
        },
        skeletalMuscleMask: {
          minMax: {
            min: 70,
            max: 180,
          },
          // minMax: {
          //   min: data.minSmm,
          //   max: data.maxSmm,
          // },
          label: `골격근량 (${data.smm} kg)`,
          standardRange: {
            min: 90,
            max: 110
          },
          percents: data.smmPercent,
          value: data.smm,
        },
        bodyFatMass: {
          minMax: {
            min: 40,
            max: 300,
          },
          // minMax: {
          //   min: data.minBfm,
          //   max: data.maxBfm,
          // },
          label: `체지방량 (${data.bfm} kg)`,
          standardRange: {
            min: 80,
            max: 160
          },
          percents: data.bfmPercent,
          value: data.bfm,
        },
        bodyMassIndex: {
          minMax: {
            min: 10,
            max: 60,
          },
          // minMax: {
          //   min: data.minBmi,
          //   max: data.maxBmi,
          // },
          label: `BMI (${data.bmi} kg/㎡)`,
          standardRange: {
            min: 18.5,
            max: 25
          },
          percents: data.bmi,
          value: data.bmi,
        },
        percentBodyFat: {
          minMax: {
            min: 8,
            max: 58,
          },
          // minMax: {
          //   min: data.minPbf,
          //   max: data.maxPbf,
          // },
          label: `체지방률 (${data.pbf} %)`,
          standardRange: {
            min: 18,
            max: 28
          },
          percents: data.pbf,
          value: data.pbf
        },
        waistHipRatio: {
          minMax: {
            min: 0.55,
            max: 1.25,
          },
          // minMax: {
          //   min: data.minWhr,
          //   max: data.maxWhr,
          // },
          label: `복부지방률 (${data.whr} %)`,
          standardRange: {
            min: 0.75,
            max: 0.85,
          },
          percents: data.whr,
          value: data.whr,
        }
      }
    },

    goToFitData() {
      this.$router.push({name: 'raonfit.fitData'});
    },

    renderPdf() {
      while (this.$refs.pdfContents.hasChildNodes()) {
        this.$refs.pdfContents.removeChild(this.$refs.pdfContents.firstChild);
      }
      /*
      루트
      */
      let rootDiv = document.createElement('div');
      rootDiv.style.setProperty('padding', '0 20px 0 20px');

      /*
      페이징
      */
      let breakDiv = document.createElement('div');
      breakDiv.className = 'html2pdf__page-break';
      breakDiv.style.setProperty('margin', '20px');

      /*
      타이틀 DIV
      */
      let inbodyTitle = this.createTitleDiv(this.userInfo.name + '님의 인바디 데이터');
      let bodyChangeTitle = this.createTitleDiv(this.userInfo.name + '님의 신체변화 데이터');

      /*
      인바디 그래프
      */
      const canvasList = document.getElementsByClassName('chartjs-render-monitor');

      const inbodyLabel = document.getElementById('inbodyLabel').cloneNode(true);
      inbodyLabel.style.setProperty('margin', '0 0 30px 0');
      const inbody1 = canvasList[0].cloneNode(true);
      const inbody2 = canvasList[1].cloneNode(true);
      const inbody3 = canvasList[2].cloneNode(true);
      const inbody4 = canvasList[3].cloneNode(true);
      const inbody5 = canvasList[4].cloneNode(true);
      const inbody6 = canvasList[5].cloneNode(true);

      inbody1.style.setProperty('width', '800px');
      inbody1.style.setProperty('margin', '0 0 20px 50px');
      inbody1.getContext('2d').drawImage(canvasList[0], 0, 0);
      inbody2.style.setProperty('width', '800px');
      inbody2.style.setProperty('margin', '0 0 20px 50px');
      inbody2.getContext('2d').drawImage(canvasList[1], 0, 0);

      inbody3.style.setProperty('width', '800px');
      inbody3.style.setProperty('margin', '0 0 20px 50px');
      inbody3.getContext('2d').drawImage(canvasList[2], 0, 0);
      inbody4.style.setProperty('width', '800px');
      inbody4.style.setProperty('margin', '0 0 20px 50px');
      inbody4.getContext('2d').drawImage(canvasList[3], 0, 0);

      inbody5.style.setProperty('width', '800px');
      inbody5.style.setProperty('margin', '0 0 20px 50px');
      inbody5.getContext('2d').drawImage(canvasList[4], 0, 0);
      inbody6.style.setProperty('width', '800px');
      inbody6.style.setProperty('margin', '0 0 20px 50px');
      inbody6.getContext('2d').drawImage(canvasList[5], 0, 0);

      let inbodyBlock1 = document.createElement('div');
      inbodyBlock1.style.setProperty('display', 'flex');
      inbodyBlock1.style.setProperty('justify-content', 'space-between');
      inbodyBlock1.style.setProperty('margin', '0 0 20px 0');
      inbodyBlock1.append(inbody1, inbody4);

      let inbodyBlock2 = document.createElement('div');
      inbodyBlock2.style.setProperty('display', 'flex');
      inbodyBlock2.style.setProperty('justify-content', 'space-between');
      inbodyBlock2.style.setProperty('margin', '0 0 20px 0');
      inbodyBlock2.append(inbody2, inbody5);

      let inbodyBlock3 = document.createElement('div');
      inbodyBlock3.style.setProperty('display', 'flex');
      inbodyBlock3.style.setProperty('justify-content', 'space-between');
      inbodyBlock3.append(inbody3, inbody6);

      /*
      신체변화 그래프
      */
      const chartDiv = document.getElementById('chartdiv');
      const svg = chartDiv.querySelector('svg');
      if (svg) {
        svg.setAttribute('width', chartDiv.clientWidth);
        svg.setAttribute('height', chartDiv.clientHeight);
      }

      const bodyChangeChart = svg.cloneNode(true);

      let thirdRowDiv = document.createElement('div');
      thirdRowDiv.style.setProperty('display', 'flex');

      let div1 = document.createElement('div');
      let bodyCompositeData = document.getElementById('bodyCompositeData').cloneNode(true);
      bodyCompositeData.style.setProperty('height', '300px');
      bodyCompositeData.style.setProperty('width', '1000px');
      bodyCompositeData.style.setProperty('margin', '0 0 0 20px');

      let tr1Title = this.createTitleDiv(this.userInfo.name + '님의 체성분 데이터');
      tr1Title.style.setProperty('width', '1000px');
      div1.append(tr1Title, bodyCompositeData);

      const t = document.getElementById('apexchartsfitTotalRadialChart');
      t.firstChild.style.setProperty('overflow', 'visible');

      let div2 = document.createElement('div');
      div2.style.setProperty('width', '500px');
      let totalChartOrigin = document.getElementById('totalChart');
      let totalChartSvg = totalChartOrigin.querySelector('svg');
      totalChartSvg.setAttribute('width', '300');
      totalChartSvg.style.setProperty('overflow', 'visible');
      let totalChart = totalChartOrigin.cloneNode(true);
      totalChart.style.setProperty('margin', '-50px');
      totalChart.style.setProperty('padding', '0');

      let tr2Title = this.createTitleDiv(this.userInfo.name + '님의 종합점수');
      div2.append(tr2Title, totalChart);

      let div3 = document.createElement('div');
      div3.style.setProperty('width', '550px');
      let healthRadioChart = document.getElementById('healthRadioChart').cloneNode(true);
      healthRadioChart.style.setProperty('height', '250px');
      let tr3Title = this.createTitleDiv(this.userInfo.name + '님의 체력 차트');
      div3.append(tr3Title, healthRadioChart);

      thirdRowDiv.append(div2, div3);

      rootDiv.append(
        inbodyTitle.cloneNode(true),
        inbodyLabel.cloneNode(true),
        inbody1,
        inbody2,
        inbody3,
        breakDiv.cloneNode(true),
        inbodyTitle.cloneNode(true),
        inbodyLabel.cloneNode(true),
        inbody4,
        inbody5,
        inbody6,
        // inbodyBlock1,
        // inbodyBlock2,
        // inbodyBlock3,
        breakDiv.cloneNode(true),
        bodyChangeTitle,
        bodyChangeChart,
        breakDiv.cloneNode(true),
        div1,
        thirdRowDiv);

      this.$refs.pdfContents.appendChild(rootDiv);
      this.$refs.html2Pdf.generatePdf();
      this.pdfLoading = false;
    },


    async downloadToPdf() {
      if (this.pdfLoading) return;

      this.pdfLoading = true;
      this.pdfShowLayout = true;

      setTimeout(this.renderPdf, 500);



      /*
      this.printOptions();

      const el = this.$refs.healthLogBody;
      const options = {
        type: 'dataURL',
        scale: 1,
        width: el.clientWidth,
        height: el.clientHeight,
      }

      const _transform = el.style.transform;
      el.style.setProperty("transform", "none");
      await this.$html2canvas(el, options)
        .then((canvas) => {
          this.output = canvas;
        })
        .finally(() => {
          el.style.transform = _transform;
        });

      let doc = new jsPDF({
        'orientation': 'p',
        'unit': 'px',
        'format': [el.clientWidth, el.clientHeight],
      });

      doc.addImage(this.output, 'jpg', 0, 0, el.clientWidth, el.clientHeight);
      doc.save(this.userInfo.name + '님의 사용자분석.pdf');

       */
    },

    createTitleDiv(titleName) {
      /*
      타이틀 DIV
      */
      let titleDiv = document.createElement('div');
      titleDiv.style.setProperty('background-color', '#089bab9a');
      titleDiv.style.setProperty('color', '#ffffff');
      titleDiv.style.setProperty('height', '50px');
      titleDiv.style.setProperty('border-radius', '10px');
      // titleDiv.style.setProperty('font-size', '1em');
      titleDiv.style.setProperty('font-family', 'nanumsquareR');
      titleDiv.style.setProperty('margin', '20px');
      titleDiv.style.setProperty('padding', '10px 0 0 20px');

      let h4 = document.createElement('h4');
      h4.className = 'card-title';
      h4.textContent = titleName;
      titleDiv.append(h4);

      return titleDiv;
    },

    printOptions(type = 'before') {
      const chartDiv = document.getElementById('chartdiv');
      const svg = chartDiv.querySelector('svg');
      if (svg) {
        svg.setAttribute('width', chartDiv.clientWidth);
        svg.setAttribute('height', chartDiv.clientHeight);
      }
    },
  }
}
</script>

<style scoped lang="scss">
.div-flex-end-vcenter {
  display: flex;
  align-content: flex-end;
  align-items: center;
  width: 100%;
}

.chart-label-div {
  display: flex;
  justify-content: space-around;
  width: 330px;
}

.chart-label-inner-div {
  width: 50px;
  height: 20px;
  border-width: 2px;
  border-style: solid;
}

.health-chart {
  height: 333px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-top: 50px;
}
</style>
