<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartData: {
      type: Array | Object,
      required: false
    },
    chartLabels: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    yLabel: {
      type: String,
      required: false,
    },
    maxY: {
      type: Number,
      required: false,
    },
    stepSize: {
      type: Number,
      required: false,
      default: 10,
    }
  },
  watch: {
  },
  data () {
    return {
      data: null,
      options: {
        title: {
          display: false,
          text: this.title
        },
        tooltips: {
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }
        },
        legend: {
          display: true,
          position: 'top'
        },
        scales: {
          yAxes: [{
            ticks: {
              stepSize: this.stepSize,
              min: 0,
              max: this.maxY,
              callback: (value, index, values) => {
                if (this.yLabel) {
                  return `${value} ${this.yLabel}`;
                } else {
                  return `${value}`;
                }
              }
            }
          }]
        },
        responsive: true,
        maintainAspectRatio: true
      }
    }
  },
  mounted () {
    this.render();
  },
  methods: {
    render() {
      this.renderChart({
        labels: this.chartLabels,
        datasets: this.chartData,
      }, this.options);
    }
  }
}
</script>

<style scoped>

</style>
