import * as rules from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import Vue from 'vue'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
});

extend('required', {
  ...rules.required,
  message: field => {
    return `${field}는 반드시 입력해야 합니다`;
  }
})

extend('idOrPasswordInvalid', value => {
  console.log('idOrPasswordInvalid -> ', value);
  return value.valid;
});

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

