import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  adminInfo: {
    accountId: '',
    accountName: '',
    role: '',
    customer: '',
    email: '',
    logoUrl: '',
    customerName: '',
    siteName: '',
    daysOfJoining: 0,
  },
  activePage: {
    name: 'Dashboard',
    breadCrumb: [
      {
        html: '<i class="ri-home-4-line mr-1 float-left"></i>Home',
        to: { name: 'mini.dashboard.home-1' }
      },
      {
        text: '',
        href: '#'
      }
    ]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
