import jwt from 'jsonwebtoken'

export const commons = {
  colorCode: {
    STANDARD: '#11698E',
    LOWER_STANDARD: '#FED049',
    UPPER_STANDARD: '#E4BAD4',
    STANDARD_TRANSPARENT: '#11698E3F',
    LOWER_STANDARD_TRANSPARENT: '#FED0493F',
    UPPER_STANDARD_TRANSPARENT: '#E4BAD43F',
  },

  parseAuthTokenToAdminInfo(token) {
    const decodeToken = jwt.decode(token);
    return {
      accountId: decodeToken.jti,
      accountName: decodeToken.accountName,
      role: decodeToken.role,
      customer: decodeToken.customer,
      email: decodeToken.email,
      profileImgUrl: decodeToken.profileImgUrl ? decodeToken.profileImgUrl : '',
      logoUrl: decodeToken.logoUrl ? decodeToken.logoUrl : '',
      customerName: decodeToken.customerName,
      siteName: decodeToken.siteName ? decodeToken.siteName : 'Raonfit',
      daysOfJoining: decodeToken.daysOfJoining ? decodeToken.daysOfJoining : 0,
    };
  }
}

