<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <Search
              :search-type="'DEVICE'"
              @selectedItem="selectedItem"
              @initSearch="initSearch"
            ></Search>
          </b-col>
          <b-col>
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px;" @click="addMachine()">디바이스 추가</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">디바이스 목록</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="12">
            <b-table striped hover
                     :items="machines"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     :filter="machineFilter"
                     :filter-included-fields="filterOn"
                     @filtered="onFiltered"
                     stacked="md"
                     show-empty
            >
              <template #cell(enabled)="row">
                <div v-if="row.value === true">정상</div>
                <div v-else style="color: red">정지</div>
              </template>
              <template #cell(actions)="row">
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="editMachine(row.item, row.index, $event.target)" class="mr-1">수정</b-button>
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="deleteMachine(row.item, row.index, $event.target)" class="mr-1">삭제</b-button>
              </template>
              <template #empty="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>데이터가 없습니다.</h5>
                </div>
              </template>
              <template #emptyfiltered="scope">
                <div style="margin: 0 auto; text-align: center">
                  <h5>검색 결과가 없습니다.</h5>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="ma-0 pa-3"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import IqCard from '@/components/xray/cards/iq-card'
import Search from '@/views/Raonfit/template/Search'
import Swal from "sweetalert2";

export default {
  name: "MachineList",
  components: {
    IqCard,
    Search,
  },
  mounted() {
    this.findAllMachines();
  },
  data() {
    return {
      fields: [
        {
          key: 'customerName',
          label: '소유 고객사명',
          sortable: true,
        },
        {
          key: 'deviceName',
          label: '디바이스명',
          sortable: true,
        },
        {
          key: 'deviceUuid',
          label: '장비 UUID',
          sortable: false,
        },
        {
          key: 'enabled',
          label: '상태',
          sortable: true,
        },
        {
          key: 'supplier',
          label: '공급사',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return value;
            } else {
              return '-';
            }
          }
        },
        {
          key: 'supplyDate',
          label: '공급일자',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'contentsInDeviceDtoList',
          label: '컨텐츠명',
          sortable: false,
          formatter: (value, key, item) => {
            let format = '';
            value.forEach(v => {
              format += v.contentsName + " / ";
            });

            return format.substring(0, format.length - 3);
          }
        },
        {
          key: 'registerDate',
          label: '등록일자',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'actions', label: ''
        }
      ],

      machines: [],
      machineFilter: null,
      filterOn: ['deviceName'],

      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  methods: {
    findAllMachines() {
      this.$axios.get('/device/list/all')
        .then(result => {
          const res = result.data;
          if (res.total > 0) {
            this.totalRows = res.total;
            this.machines = res.machines;
          } else {
            this.totalRows = 0;
          }
        })
        .catch(err => {
          console.log(err);
        });


    },
    selectedItem(item) {
      if (item.size === 1) {
        if (item.category === 'DEVICE_NAME') {
          this.filterOn = ['deviceName'];
          this.machineFilter = item.value.deviceName;
        } else if (item.category === 'DEVICE_UUID') {
          this.filterOn = ['deviceUuid'];
          this.machineFilter = item.value.deviceUuid;
        }
      } else {
        if (item.category === 'DEVICE_NAME') {
          this.filterOn = ['deviceName'];
          this.machineFilter = item.search;
        } else if (item.category === 'DEVICE_UUID') {
          this.filterOn = ['deviceUuid'];
          this.machineFilter = item.search;
        }
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    initSearch() {
      this.filterOn = ['deviceName'];
      this.machineFilter = null;
    },

    addMachine() {
      const deviceInfo = { newDevice: true };
      this.$router.push({name: 'raonfit.super.deviceDetail', params: deviceInfo});
    },
    editMachine(item, index, button) {
      const deviceInfo = {
        newDevice: false,
        ...item,
      };

      this.$router.push({name : 'raonfit.super.deviceDetail', params: deviceInfo});
    },
    deleteMachine(item, index, button) {
      Swal.fire({
        // title: "사용자 정보 삭제",
        text: item.deviceName + " 디바이스 정보를 삭제합니다",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#089BAB",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`/device/delete/${item.id}`)
            .then(result => {
              if (result.data === 'DELETED') {
                Swal.fire({html: '선택한 디바이스의 정보를 삭제하였습니다.', icon: 'success'})
                  .then(t => {
                    this.findAllMachines();
                  }).catch(c => {
                  this.findAllMachines();
                })
              }

            })
            .catch(error => {
              let text = error.message;
              Swal.fire('디바이스 정보 삭제 오류', text, 'error');
            })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
