<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:body>
        <b-row>
          <b-col>
            <b-button pill variant="outline-primary" style="width: 120px; height: 50px;" @click="addNotice()">공지사항 작성</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">공지사항 목록</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="12">
            <b-table striped hover
                     :items="noticeList"
                     :fields="fields"
                     :current-page="currentPage"
                     :per-page="perPage"
                     stacked="md"
                     show-empty
            >
              <template #cell(actions)="row">
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="editNotice(row.item, row.index, $event.target)" class="mr-1">수정</b-button>
                <b-button pill variant="primary" style="width: 75px; height: 35px;" @click="deleteNotice(row.item, row.index, $event.target)" class="mr-1">삭제</b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="ma-0 pa-3"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import IqCard from '@/components/xray/cards/iq-card'
import Search from '@/views/Raonfit/template/Search'
import Swal from "sweetalert2";

export default {
  name: "NoticeList",
  components: {
    IqCard,
    Search,
  },
  mounted() {
    this.findAllNoticeList();
  },
  data() {
    return {
      fields: [
        {
          key: 'title',
          label: '공지사항 제목',
          sortable: true,
        },
        {
          key: 'writer',
          label: '작성자',
          sortable: true,
        },
        {
          key: 'writeDateTime',
          label: '작성일자',
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            if (value) {
              return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8) + ' ' + value.substring(8, 10) + ':' + value.substring(10, 12) + ':' + value.substring(12);
            } else {
              return '-';
            }
          }
        },
        {
          key: 'enabled',
          label: '게시여부',
          sortable: true,
          formatter: (value, key, item) => {
            if (value) {
              return '게시';
            } else {
              return '게시안함';
            }
          }
        },
        {
          key: 'actions', label: ''
        }
      ],

      noticeList: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  methods: {
    findAllNoticeList() {
      this.$axios.get('/notice/list/all')
        .then(result => {
          const data = result.data;
          if (data.total > 0) {
            this.totalRows = data.total;
            this.noticeList = data.noticeList;
          } else {
            this.totalRows = 0;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    addNotice() {
      const noticeInfo = { newNotice: true };
      this.$router.push({name: 'raonfit.super.noticeDetail', params: noticeInfo});
    },
    editNotice(item, index, button) {
      const noticeInfo = {
        newNotice: false,
        ...item,
      };

      this.$router.push({name : 'raonfit.super.noticeDetail', params: noticeInfo});
    },
    deleteNotice(item, index, button) {
      Swal.fire({
        text: "선택한 공지사항을 삭제합니다",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#089BAB",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`/notice/delete/${item.id}`)
            .then(result => {
              if (result.data === 'DELETED') {
                Swal.fire({html: '공지사항을 삭제하였습니다.', icon: 'success'})
                  .then(t => {
                    this.findAllNoticeList();
                  }).catch(c => {
                  this.findAllNoticeList();
                })
              }

            })
            .catch(error => {
              let text = error.message;
              Swal.fire('공지사항 삭제 오류', text, 'error');
            })
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
