<template>
  <!-- 운동기구도 같이 편집 -->
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">고객사 {{customerInfo.newCustomer ? '추가' : '수정'}}</h4>
      </template>
      <template v-slot:body>
        <div class="form-box">
          <b-form>
            <b-form-group>
              <b-row>
                <b-col lg="1">
                  <div class="detail-form-label-div">
                    <label for="customerNameValid" class="detail-form-label">고객사명</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="customerNameValid" v-model.trim="customerInfo.customerName" :state="customerNameValidation" required></b-form-input>
                  <b-form-invalid-feedback id="customerNameValid" :state="customerNameValidation">고객사명은 2자 ~ 30자 이내여야 합니다
                  </b-form-invalid-feedback>
                  <b-form-valid-feedback id="customerNameValid" :state="customerNameValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="1">
                  <div class="detail-form-label-div">
                    <label for="siteName" class="detail-form-label">사이트명</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="siteName" v-model.trim="customerInfo.siteName" :state="siteNameValidation" required></b-form-input>
                  <b-form-invalid-feedback id="siteNameValid" :state="siteNameValidation">사이트명은 30자 이내여야 합니다</b-form-invalid-feedback>
                  <b-form-valid-feedback id="siteNameValid" :state="siteNameValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="1">
                  <div class="detail-form-label-div">
                    <label for="addressValid" class="detail-form-label">주소</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-input id="addressValid" v-model.trim="customerInfo.address" :state="addressValidation" required></b-form-input>
                  <b-form-invalid-feedback id="addressValid" :state="addressValidation">주소는 반드시 입력해야 합니다
                  </b-form-invalid-feedback>
                  <b-form-valid-feedback id="addressValid" :state="addressValidation"></b-form-valid-feedback>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row>
                <b-col lg="1">
                  <div class="detail-form-label-div">
                    <label for="memoInput" class="detail-form-label">고객사 메모</label>
                  </div>
                </b-col>
                <b-col lg="7">
                  <b-form-textarea
                    id="memoInput"
                    placeholder="고객사에 대한 메모를 입력하세요."
                    rows="2"
                    v-model="customerInfo.memo"
                  ></b-form-textarea>
<!--                  <b-form-input id="memoInput" v-model.trim="customerInfo.memo"></b-form-input>-->
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group>
              <b-row style="align-items: center;">
                <b-col lg="1">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">로고 이미지 (150x150)</label>
                  </div>
                </b-col>
                <b-col lg="3" style="display: flex; align-items: center;">
                  <div style="flex-basis: content; flex-grow: 10; margin-right: 20px;">
                    <b-form-file id="file" v-model="logoFile" style="display: none" ref="logoFile"/>
                    <b-form-input id="file-input" class="settings-input" :value="customerInfo.logoName" readonly></b-form-input>
                  </div>
                  <div style="flex-grow: 1">
                    <img @click="$refs.logoFile.$el.querySelector('input[type=file]').click()"
                         style="width: 30px; height: 30px; cursor: pointer"
                         :src="require('@/assets/images/logo-upload-img.svg')"/>
                  </div>
                </b-col>

                <b-col lg="1">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">배너 이미지</label>
                  </div>
                </b-col>
                <b-col lg="3" style="display: flex; align-items: center;">
                  <div style="flex-basis: content; flex-grow: 10; margin-right: 20px;">
                    <b-form-file id="banner-file" v-model="bannerFile" style="display: none" ref="bannerFile"/>
                    <b-form-input id="banner-file-input" class="settings-input" :value="customerInfo.bannerName" readonly></b-form-input>
                  </div>
                  <div style="flex-grow: 1">
                    <img @click="$refs.bannerFile.$el.querySelector('input[type=file]').click()"
                         style="width: 30px; height: 30px; cursor: pointer"
                         :src="require('@/assets/images/logo-upload-img.svg')"/>
                  </div>
                </b-col>

                <b-col lg="2">
                  <div class="detail-form-label-div">
                    <label class="detail-form-label">고객사 상태관리</label>
                  </div>
                </b-col>
                <b-col lg="2">
                  <div>
                    <b-form-select
                      v-model="customerInfo.enabled"
                      :options="enableOptions"
                      id="selectEnabled"
                    ></b-form-select>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>

            <b-row>
              <b-col lg="1"></b-col>
              <b-col lg="3">
                <div v-if="customerInfo.logoUrl">
                  <p>기존에 업로드한 로고 이미지</p>
                  <img :src="customerInfo.logoUrl" style="width: 100%; height: 20vh; object-fit: contain;"/>
                </div>
              </b-col>

              <b-col lg="1"></b-col>
              <b-col lg="3">
                <div v-if="customerInfo.bannerUrl">
                  <p>기존에 업로드한 배너 이미지</p>
                  <img :src="customerInfo.bannerUrl" style="width: 100%; height: 20vh; object-fit: contain;"/>
                </div>
              </b-col>
            </b-row>

            <hr>

            <div style="display: flex; justify-content: flex-end;">
              <b-button variant="primary" class="button-style" @click="upsertCustomer()">저장</b-button>
              <b-button variant="none" class="iq-bg-danger ml-3 button-style" @click="backToPage()">취소</b-button>
            </div>
          </b-form>
        </div>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  name: "CustomerDetail",
  created() {
    if (this.$route.params && this.$route.params.id) {
      const params = this.$route.params;
      this.customerInfo = {newCustomer: false, ...params};
    }
  },
  mounted() {
    if (!this.customerInfo.newCustomer) {
      this.findCompanyMachines();
    }
  },
  data() {
    return {
      customerInfo: {
        newCustomer: true,
        id: '',
        customerName: '',
        siteName: '',
        address: '',
        bannerFile: null,
        bannerUrl: '',
        bannerName: '',
        logoFile: null,
        logoUrl: '',
        logoName: '',
        enabled: true,
        memo: '',
      },

      logoFile: null,
      logoFileMBSize: 0,

      bannerFile: null,
      bannerFileMBSize: 0,

      enableOptions: [{text: '사용', value: true}, {text: '사용안함', value: false}],

      machineList: [],
    }
  },
  watch: {
    logoFile: function(file) {
      this.logoFileMBSize = this.formatMBytes(file.size);
      this.customerInfo.logoFile = file;
      this.customerInfo.logoName = file.name;
    },
    bannerFile: function(file) {
      this.bannerFileMBSize = this.formatMBytes(file.size);
      this.customerInfo.bannerFile = file;
      this.customerInfo.bannerName = file.name;
    }
  },
  computed: {
    customerNameValidation() {
      if (this.customerInfo.customerName) {
        return this.customerInfo.customerName.length >= 2 && this.customerInfo.customerName.length <= 30;
      } else {
        return false;
      }
    },
    addressValidation() {
      return !!this.customerInfo.address;
    },
    siteNameValidation() {
      if (this.customerInfo.siteName) {
        if (this.customerInfo.siteName.length > 30) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    findCompanyMachines() {
      this.$axios.get(`/device/list/customer/${this.customerInfo.id}`)
      .then(result => {
        const machineList = result.data;
        const machineArray = [];
        machineList.forEach(m => {
          const machine = {
            id: m.managedMachineId,
            title: m.machineName,
          }

          machineArray.push(machine);
        });

        this.machineList = machineArray;
      })
      .catch(error => {

      });
    },

    customerInfoValidation() {
      if (!this.customerInfo.customerName || !this.customerInfo.address) {
        return false;
      }

      return true;
    },
    upsertCustomer() {
      if (!this.customerInfoValidation()) return;

      if (this.logoFileMBSize > 5.0) {
        Swal.fire({
          text: '5MB 이상의 로고 이미지는 업로드할 수 없습니다',
          icon: 'warning',
          confirmButtonColor: '#089BAB',
          confirmButtonText: '확인',
        }).then(result => {

        });

        return;
      }

      if (this.bannerFileMBSize > 5.0) {
        Swal.fire({
          text: '5MB 이상의 배너 이미지는 업로드할 수 없습니다',
          icon: 'warning',
          confirmButtonColor: '#089BAB',
          confirmButtonText: '확인',
        }).then(result => {

        });

        return;
      }


      const formData = new FormData();
      formData.append('customerRequestDto.newCustomer', this.customerInfo.newCustomer);
      formData.append('customerRequestDto.id', this.customerInfo.id);
      formData.append('customerRequestDto.customerName', this.customerInfo.customerName);
      formData.append('customerRequestDto.siteName', this.customerInfo.siteName);
      formData.append('customerRequestDto.address', this.customerInfo.address);
      formData.append('customerRequestDto.enabled', this.customerInfo.enabled);
      formData.append('customerRequestDto.memo', this.customerInfo.memo);
      formData.append('logoFile', this.customerInfo.logoFile);
      formData.append('bannerFile', this.customerInfo.bannerFile);

      Swal.fire({
        text: '고객사 정보를 저장합니다',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#089BAB',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: '저장',
        cancelButtonText: '취소',
      }).then(result => {
        if (result.value) {
          this.$axios.put('/customer/upsert', formData, { headers: {'Content-Type': 'multipart/form-data'}})
            .then(result => {
              Swal.fire('', '고객사 정보를 저장하였습니다', 'success')
                .then(t => {
                  this.goToCustomerList();
                })
                .catch(c => {
                  this.goToCustomerList();
                });
            })
            .catch(error => {
              let errorText = '';
              if (error.message === '4000') {
                errorText = '정보를 정확히 입력해주세요';
              } else if (error.message === '4008') {
                errorText = '중복된 ID입니다. 다른 ID로 등록해주세요.';
              } else {
                errorText = '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해 주세요';
              }

              Swal.fire('사용자 정보 저장 오류', errorText, 'error');
            });
        }
      })
    },
    backToPage() {
      this.$router.back();
    },
    goToCustomerList() {
      this.$router.push({name: 'raonfit.super.customerList'});
    },
    formatMBytes(bytes, decimals = 2) {
      if (bytes === 0) return 0;

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm));
    }
  }
}
</script>

<style scoped lang="scss">
.button-style {
  width: 5vw;
  height: 5vh;
}
</style>
