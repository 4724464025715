<template>
  <div :id="element" :style="'height: '+height+'px;'"></div>
</template>


<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4maps from '@amcharts/amcharts4/maps'
// eslint-disable-next-line camelcase
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
// eslint-disable-next-line camelcase

am4core.useTheme(am4themes_animated);

export default {
  name: "MultiAxisChart",
  props: {
    element: { type: String, default: 'am-chart' },
    // option: { type: (Object, Array), default: () => {} },
    option: { type: Object, default: () => {} },
    height: { type: Number, default: 500 }
  },
  watch: {
    option: {
      deep: true,
      handler: 'setData'
    }
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted () {
    this.chart = am4core.create(this.element, am4charts.XYChart)
    this.multipleValueAxes(this.chart)
  },

  methods: {
    setData() {
      this.chart.data = this.option.data
    },
    multipleValueAxes (chart) {
      console.log('multipleValueAxes ', chart);
      // Increase contrast by taking evey second color
      chart.colors.step = 3

      // Add data
      chart.data = this.option.data

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.minGridDistance = 100
      categoryAxis.dataFields.category = 'measureDate';

      // Create series
      function createAxisAndSeries (field, name, opposite) {
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

        // if (chart.yAxes.indexOf(valueAxis) !== 0) {
        //   valueAxis.syncWithAxis = chart.yAxes.getIndex(0)
        // }

        let series = chart.series.push(new am4charts.LineSeries())
        series.name = name;
        series.dataFields.categoryX = 'measureDate'
        series.dataFields.valueY = field
        series.strokeWidth = 2
        series.yAxis = valueAxis

        // series.dataFields.valueY = field
        // series.dataFields.dateX = 'measureDate'
        // series.strokeWidth = 2
        // series.yAxis = valueAxis
        // series.name = name
        series.tooltipText = '{name}: [bold]{valueY}[/]'
        series.tensionX = 0.8
        series.showOnInit = true

        valueAxis.renderer.line.strokeOpacity = 1
        valueAxis.renderer.line.strokeWidth = 2
        valueAxis.renderer.line.stroke = series.stroke
        valueAxis.renderer.labels.template.fill = series.stroke
        valueAxis.renderer.opposite = opposite

        // valueAxis.renderer.grid.template.strokeWidth = 0
        valueAxis.renderer.grid.template.strokeWidth = 0.3
      }

      createAxisAndSeries('weight', '체중', false)
      createAxisAndSeries('smm', '골격근량', true)
      createAxisAndSeries('bfm', '체지방률', true)
      createAxisAndSeries('bmi', 'BMI', true)

      // Add legend
      chart.legend = new am4charts.Legend()

      // Add cursor
      chart.cursor = new am4charts.XYCursor()
    }
  },
}
</script>

<style scoped>

</style>
