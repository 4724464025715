<template>
  <b-container fluid>
    <b-overlay
      :show="pdfLoading"
      spinner-variant="primary"
      spinner-type="grow"
      rounded="lg"
    >
    <iq-card class-name="iq-card-block iq-card-height search-row-div">
      <template v-slot:body>
        <b-row>
          <b-col lg="6">
            <DateSearch
              @searchByPeriod="searchChangedBodyData"
              :search-button="false"
            ></DateSearch>
          </b-col>
          <b-col lg="5" style="margin-top: 1.5em;">
            <UserSearch
              @selectedItem="selectedUserData"
              :show-default-value="true"
            ></UserSearch>
          </b-col>

          <b-col lg="1" style="display: flex; justify-content: flex-end; align-items: flex-end;"
                 v-if="userInfo.id != null">
            <b-button variant="primary" class="mb-2 mr-2" style="font-size: 1em; height: 50px;" @click="downloadToPdf">
              <template v-if="pdfLoading">
                <b-spinner small type="grow"></b-spinner>
                생성중...
              </template>
              <template v-else>
                <i class="far fa-file-pdf"></i>PDF
              </template>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <div ref="fitData" style="margin: 0 auto;">
      <div class="fitData-inner-div" style="margin: 0 auto;">
        <b-row>
          <b-col lg="12">
            <iq-card class-name="iq-card-block iq-card-height">
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 세부 운동분석</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col lg="12">
                    <tab-nav :tabs="true" id="fitData-Tab" align="end">
                      <tab-nav-items :active="true" id="time-tab" href="#time-tab-end" ariaControls="time" role="tab"
                                     :ariaSelected="true" :title="tabTitle.time"/>
                      <tab-nav-items :active="false" id="use-kcal-tab" href="#use-kcal-tab-end" ariaControls="use-kcal"
                                     role="tab" :ariaSelected="false" :title="tabTitle.useKcal"/>
                    </tab-nav>
                    <tab-content id="fitDataTabContent">
                      <tab-content-item :active="mlcTimeTab" id="time-tab-end" aria-labelled-by="time-tab">
                        <div>
                          <MultiLineChart
                            ref="mlcTime"
                            id="mlcTime"
                            v-if="dataLoaded"
                            :chart-data="exerciseTimeData"
                            :chart-labels="chartLabels"
                            :title="tabTitle.time"
                            :width="1600"
                          ></MultiLineChart>
                        </div>
                      </tab-content-item>
                      <tab-content-item :active="mlcKcalTab" id="use-kcal-tab-end" aria-labelled-by="use-kcal-tab">
                        <div>
                          <MultiLineChart
                            ref="mlcKcal"
                            id="mlcKcal"
                            v-if="dataLoaded"
                            :chart-data="useKcalData"
                            :chart-labels="chartLabels"
                            :title="tabTitle.useKcal"
                            :width="1600"
                          ></MultiLineChart>
                        </div>
                      </tab-content-item>
                    </tab-content>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12">
            <iq-card class-name="iq-card-block iq-card-height">
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 운동 분석</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col lg="12">
                    <tab-nav :tabs="true" id="fitData-Tab" align="end">
                      <tab-nav-items :active="true" id="fit1-tab" href="#fit1-tab-end" ariaControls="fit1" role="tab"
                                     :ariaSelected="true" title="심폐지구력"/>
                      <tab-nav-items :active="false" id="fit2-tab" href="#fit2-tab-end" ariaControls="fit2" role="tab"
                                     :ariaSelected="false" title="균형감"/>
                      <tab-nav-items :active="false" id="fit3-tab" href="#fit3-tab-end" ariaControls="fit3" role="tab"
                                     :ariaSelected="false" title="순발력"/>
                      <tab-nav-items :active="false" id="fit4-tab" href="#fit4-tab-end" ariaControls="fit4" role="tab"
                                     :ariaSelected="false" title="유연성"/>
                      <tab-nav-items :active="false" id="fit5-tab" href="#fit5-tab-end" ariaControls="fit5" role="tab"
                                     :ariaSelected="false" title="근력/근지구력"/>
                    </tab-nav>
                    <tab-content id="fitDataTabContent">
                      <tab-content-item :active="mlcCeTab" id="fit1-tab-end" aria-labelled-by="fit1-tab">
                        <div>
                          <MultiLineChart
                            v-if="dataLoaded"
                            ref="mlcCe"
                            :chart-data="ceData"
                            :chart-labels="chartLabels"
                            :width="1600"
                            :stepSize="1"
                          ></MultiLineChart>
                        </div>
                      </tab-content-item>
                      <tab-content-item :active="mlcBalanceTab" id="fit2-tab-end" aria-labelled-by="fit2-tab">
                        <div>
                          <MultiLineChart
                            v-if="dataLoaded"
                            ref="mlcBalance"
                            :chart-data="balanceData"
                            :chart-labels="chartLabels"
                            :width="1600"
                            :stepSize="1"
                          ></MultiLineChart>
                        </div>
                      </tab-content-item>
                      <tab-content-item :active="mlcWitsTab" id="fit3-tab-end" aria-labelled-by="fit3-tab">
                        <div>
                          <MultiLineChart
                            v-if="dataLoaded"
                            ref="mlcWits"
                            :chart-data="witsData"
                            :chart-labels="chartLabels"
                            :width="1600"
                            :stepSize="1"
                          ></MultiLineChart>
                        </div>
                      </tab-content-item>
                      <tab-content-item :active="mlcFlexTab" id="fit4-tab-end" aria-labelled-by="fit4-tab">
                        <div>
                          <MultiLineChart
                            v-if="dataLoaded"
                            ref="mlcFlex"
                            :chart-data="flexData"
                            :chart-labels="chartLabels"
                            :width="1600"
                            :stepSize="1"
                          ></MultiLineChart>
                        </div>
                      </tab-content-item>
                      <tab-content-item :active="mlcMeTab" id="fit5-tab-end" aria-labelled-by="fit5-tab">
                        <div>
                          <MultiLineChart
                            v-if="dataLoaded"
                            ref="mlcMe"
                            :chart-data="meData"
                            :chart-labels="chartLabels"
                            :width="1600"
                            :stepSize="1"
                          ></MultiLineChart>
                        </div>
                      </tab-content-item>
                    </tab-content>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>

        <iq-card class-name="iq-card-block iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ userInfo.name ? userInfo.name + '님의' : '' }} 기간별 운동 분석</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12">
                <b-table-simple id="fitAnalysis" responsive>
                  <b-thead>
                    <b-th>사용자명</b-th>
                    <b-th>총 운동시간</b-th>
                    <b-th>일평균 운동시간</b-th>
                    <b-th>총 소모칼로리</b-th>
                    <b-th>일평균 소모칼로리</b-th>
                    <b-th v-for="(device, idx) in periodFitData.device" :key="'d' + idx">{{ device.name }} 사용시간</b-th>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-th>{{ userInfo.name }}</b-th>
                      <b-td>{{ periodFitData.totalExerciseTime }} 분</b-td>
                      <b-td>{{ periodFitData.averDailyExerciseTime }} 분</b-td>
                      <b-td>{{ periodFitData.totalBurnedCal }} Kcal</b-td>
                      <b-td>{{ periodFitData.averDailyBurnedCal }} Kcal</b-td>
                      <b-td v-for="(device, idx) in periodFitData.device" :key="'t' + idx">{{ device.usageTime }} 분
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </div>
    </div>
    </b-overlay>

    <vue-html2pdf
      v-if="pdfShowLayout"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="userInfo.name + '님의 운동분석'"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1100px"
      ref="html2Pdf"
    >
      <section id="pdfContentsSection" slot="pdf-content" ref="pdfContents">
      </section>
    </vue-html2pdf>
  </b-container>
</template>

<script>
import {xray} from '@/config/pluginInit'
import {commons} from '@/Utils/Commons'
import UserSearch from '@/views/Raonfit/template/Search'
import IqCard from '@/components/xray/cards/iq-card'
import MultiLineChart from '@/views/Raonfit/template/MultiLineChart'
import DateSearch from '@/views/Raonfit/template/DateSearch'
import VueHtml2pdf from 'vue-html2pdf';

export default {
  name: 'Raonfit-FitData',
  components: {
    IqCard,
    UserSearch,
    MultiLineChart,
    DateSearch,
    VueHtml2pdf,
  },
  created() {
  },
  mounted() {
    xray.index();

    this.periodStart = this.$moment().subtract(1, 'month').format('YYYY-MM-DD')
    this.periodEnd = this.$moment().format('YYYY-MM-DD')

    const searchUser = this.$store.getters['User/searchUserState'];
    if (searchUser) {
      this.userInfo = {
        id: searchUser.id,
        name: searchUser.name,
      }

      this.searchFitAnalysisData();
    }

  },
  computed: {
    checkDateState() {
      if (this.periodStart > this.periodEnd) {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      userInfo: {
        id: '',
        name: '',
      },
      colorCode: commons.colorCode,
      period: 'last-month',
      loaded: false,
      periodStart: '',
      periodEnd: '',
      tabTitle: {
        time: '운동시간(분)',
        useKcal: '소모칼로리(Kcal)',
      },
      dataLoaded: false,

      exerciseTimeData: [],
      useKcalData: [],
      ceData: [],
      meData: [],
      flexData: [],
      witsData: [],
      balanceData: [],

      chartLabels: [],


      periodFitData: {
        totalExerciseTime: 0,
        averDailyExerciseTime: 0,
        totalBurnedCal: 0,
        averDailyBurnedCal: 0,
        device: [
          // { name: '', usageTime: 0, }
        ],
      },

      output: null,
      pdfLoading: false,

      mlcTimeTab: true,
      mlcKcalTab: false,
      mlcCeTab: true,
      mlcBalanceTab: false,
      mlcWitsTab: false,
      mlcFlexTab: false,
      mlcMeTab: false,

      pdfShowLayout: false
    }
  },
  methods: {
    generateChartLabel() {
      let firstDate = new Date()
      const beforeDate = 14
      firstDate.setDate(firstDate.getDate() - beforeDate)
      let dateArr = []

      for (let i = 0; i < beforeDate; i = i + 2) {
        let newDate = new Date()
        newDate.setDate(firstDate.getDate() + i)
        dateArr.push(this.$moment(newDate).format('YYYY-MM-DD'))
      }

      return dateArr
    },
    generateChartData() {
      let firstDate = new Date()
      const beforeDate = 7

      firstDate.setDate(firstDate.getDate() - beforeDate)

      let data1 = 60
      let data2 = 30
      let data3 = 90
      let data4 = 20
      let data5 = 40
      let data6 = 80

      let data1Arr = []
      let data2Arr = []
      let data3Arr = []
      let data4Arr = []
      let data5Arr = []
      let data6Arr = []

      for (let i = 0; i < beforeDate; i++) {
        data1 += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10)
        data2 += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10)
        data3 += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10)
        data4 += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10)
        data5 += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10)
        data6 += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10)

        data1Arr.push(data1)
        data2Arr.push(data2)
        data3Arr.push(data3)
        data4Arr.push(data4)
        data5Arr.push(data5)
        data6Arr.push(data6)
      }

      // 단위 추가
      return [
        {
          label: '라온로잉',
          fill: false,
          backgroundColor: '#206a5d',
          borderColor: '#206a5d3F',
          data: data1Arr
        },
        {
          label: '라온스키',
          fill: false,
          backgroundColor: '#ffcc29',
          borderColor: '#ffcc293F',
          data: data2Arr
        },
        {
          label: '라온밸런스',
          fill: false,
          backgroundColor: '#f58634',
          borderColor: '#f586343F',
          data: data3Arr
        },
        {
          label: '라온댄스',
          fill: false,
          backgroundColor: '#ff8882',
          borderColor: '#ff88823F',
          data: data4Arr
        },
        {
          label: '라온바이크',
          fill: false,
          backgroundColor: '#9dbeb9',
          borderColor: '#9dbeb93F',
          data: data5Arr
        },
        {
          label: '라온보드',
          fill: false,
          backgroundColor: '#bfcba8',
          borderColor: '#bfcba83F',
          data: data6Arr
        }
      ]
    },
    pickColor(i) {
      const backgroundColors = ['#206a5d', '#ffcc29', '#f58634', '#ff8882', '#9dbeb9', '#bfcba8'];
      const borderColors = ['#206a5d3F', '#ffcc293f', '#f586343f', '#ff88823f', '#9dbeb93f', '#bfcba83f']
      return {
        backgroundColor: backgroundColors[i],
        borderColor: borderColors[i],
      }
    },

    selectedUserData(user) {
      this.userInfo = {
        id: user.value.id,
        userId: user.value.userId,
        name: user.value.userName,
      };

      this.$store.dispatch('User/saveSearchUserAction', this.userInfo);

      this.searchFitAnalysisData();
    },
    searchChangedBodyData(period) {
      this.periodStart = period.start;
      this.periodEnd = period.end;
    },
    searchFitAnalysisData() {
      this.dataLoaded = false;
      if (this.periodStart && this.periodEnd && this.userInfo.id) {
        this.$axios.get(`/fitData/user/analysis/${this.userInfo.id}/${this.periodStart.replaceAll('-', '')}/${this.periodEnd.replaceAll('-', '')}`)
          .then(result => {
            if (result.data.total > 0) {
              this.dataLoaded = true;
              const data = result.data;

              const dates = data.measureDates;
              const machineNames = data.machineNames;

              const exerciseTimeArr = [];
              const useKcalArr = [];

              const ceArr = [];
              const meArr = [];
              const flexArr = [];
              const witsArr = [];
              const balanceArr = [];

              machineNames.forEach((m, idx) => {
                let pickColor = this.pickColor(idx);
                exerciseTimeArr.push({
                  label: m,
                  fill: false,
                  data: [],
                  ...pickColor
                });

                useKcalArr.push({
                  label: m,
                  fill: false,
                  data: [],
                  ...pickColor
                });

                ceArr.push({
                  label: m,
                  fill: false,
                  data: [],
                  ...pickColor
                });

                meArr.push({
                  label: m,
                  fill: false,
                  data: [],
                  ...pickColor
                });

                flexArr.push({
                  label: m,
                  fill: false,
                  data: [],
                  ...pickColor
                });

                witsArr.push({
                  label: m,
                  fill: false,
                  data: [],
                  ...pickColor
                });

                balanceArr.push({
                  label: m,
                  fill: false,
                  data: [],
                  ...pickColor
                });
              });

              dates.forEach(date => {
                const fitData = data.fitAnalysis[date];

                machineNames.forEach(m => {
                  const et = fitData.exerciseTime[m];
                  const uk = fitData.useKcal[m];
                  const ce = fitData.ce[m];
                  const me = fitData.me[m];
                  const flex = fitData.flex[m];
                  const wits = fitData.wits[m];
                  const balance = fitData.balance[m];

                  exerciseTimeArr.forEach(a => {
                    if (a.label === m) {
                      a.data.push(et);
                    }
                  });

                  useKcalArr.forEach(a => {
                    if (a.label === m) {
                      a.data.push(uk);
                    }
                  });

                  ceArr.forEach(a => {
                    if (a.label === m) {
                      a.data.push(ce);
                    }
                  });

                  meArr.forEach(a => {
                    if (a.label === m) {
                      a.data.push(me);
                    }
                  });

                  flexArr.forEach(a => {
                    if (a.label === m) {
                      a.data.push(flex);
                    }
                  });

                  witsArr.forEach(a => {
                    if (a.label === m) {
                      a.data.push(wits);
                    }
                  });

                  balanceArr.forEach(a => {
                    if (a.label === m) {
                      a.data.push(balance);
                    }
                  });


                });
              });

              const dateArr = [];
              dates.forEach(d => {
                const formatDate = d.substring(0, 4)
                  .concat('-')
                  .concat(d.substring(4, 6))
                  .concat('-')
                  .concat(d.substring(6));

                dateArr.push(formatDate);
              });
              this.chartLabels = dateArr;

              this.exerciseTimeData = exerciseTimeArr;
              this.useKcalData = useKcalArr;
              this.ceData = ceArr;
              this.meData = meArr;
              this.flexData = flexArr;
              this.witsData = witsArr;
              this.balanceData = balanceArr;

              const periodData = data.exerciseAnalysis;
              this.periodFitData.totalExerciseTime = periodData.totalExerciseTime;
              this.periodFitData.totalBurnedCal = periodData.totalUseKcal;
              this.periodFitData.averDailyExerciseTime = periodData.exerciseTimePerDay;
              this.periodFitData.averDailyBurnedCal = periodData.useKcalPerDay;
              this.periodFitData.device = [];
              periodData.devices.forEach(d => {
                this.periodFitData.device.push({
                  name: d.name,
                  usageTime: d.useTime,
                });
              });
            }
          })
          .catch(error => {

          })
      }
    },

    expandGraph(expand) {
      if (expand) {
        this.mlcTimeTab = true;
        this.mlcKcalTab = true;
        this.mlcCeTab = true;
        this.mlcBalanceTab = true;
        this.mlcWitsTab = true;
        this.mlcFlexTab = true;
        this.mlcMeTab = true;
      } else {
        this.mlcTimeTab = true;
        this.mlcKcalTab = false;
        this.mlcCeTab = true;
        this.mlcBalanceTab = false;
        this.mlcWitsTab = false;
        this.mlcFlexTab = false;
        this.mlcMeTab = false;
      }
    },

    renderPdf() {
      while (this.$refs.pdfContents.hasChildNodes()) {
        this.$refs.pdfContents.removeChild(this.$refs.pdfContents.firstChild);
      }
      /*
        루트
      */
      let rootDiv = document.createElement('div');
      rootDiv.style.setProperty('padding', '0 20px 0 20px');

      /*
      페이징
      */
      let breakDiv = document.createElement('div');
      breakDiv.className = 'html2pdf__page-break';
      breakDiv.style.setProperty('margin', '20px');

      let timeTitle = this.createTitleDiv(this.userInfo.name + '님의 운동시간(분)');
      let kcalTitle = this.createTitleDiv(this.userInfo.name + '님의 소모칼로리(Kcal)');
      let ceTitle = this.createTitleDiv(this.userInfo.name + '님의 심폐지구력');
      let balanceTitle = this.createTitleDiv(this.userInfo.name + '님의 균형감');
      let witsTitle = this.createTitleDiv(this.userInfo.name + '님의 순발력');
      let flexTitle = this.createTitleDiv(this.userInfo.name + '님의 유연성');
      let meTitle = this.createTitleDiv(this.userInfo.name + '님의 근력/근지구력');
      let analyzeTitle = this.createTitleDiv(this.userInfo.name + '님의 기간별 운동 분석');

      const canvasList = document.getElementsByClassName('chartjs-render-monitor');

      let chart1 = document.createElement('div');
      let chart2 = document.createElement('div');
      let chart3 = document.createElement('div');
      let chart4 = document.createElement('div');
      let chart5 = document.createElement('div');
      let chart6 = document.createElement('div');
      let chart7 = document.createElement('div');

      if (canvasList.length > 0) {
        chart1 = canvasList[0].cloneNode(true);
        chart2 = canvasList[1].cloneNode(true);
        chart3 = canvasList[2].cloneNode(true);
        chart4 = canvasList[3].cloneNode(true);
        chart5 = canvasList[4].cloneNode(true);
        chart6 = canvasList[5].cloneNode(true);
        chart7 = canvasList[6].cloneNode(true);

        chart1.style.setProperty('width', '1000px');
        chart1.style.setProperty('height', '280px');
        chart1.getContext('2d').drawImage(canvasList[0], 0, 0);
        chart2.style.setProperty('width', '1000px');
        chart2.style.setProperty('height', '280px');
        chart2.getContext('2d').drawImage(canvasList[1], 0, 0);

        chart3.style.setProperty('width', '1000px');
        chart3.style.setProperty('height', '280px');
        chart3.getContext('2d').drawImage(canvasList[2], 0, 0);
        chart4.style.setProperty('width', '1000px');
        chart4.style.setProperty('height', '280px');
        chart4.getContext('2d').drawImage(canvasList[3], 0, 0);

        chart5.style.setProperty('width', '1000px');
        chart5.style.setProperty('height', '280px');
        chart5.getContext('2d').drawImage(canvasList[4], 0, 0);
        chart6.style.setProperty('width', '1000px');
        chart6.style.setProperty('height', '280px');
        chart6.getContext('2d').drawImage(canvasList[5], 0, 0);

        chart7.style.setProperty('width', '1000px');
        chart7.style.setProperty('height', '280px');
        chart7.getContext('2d').drawImage(canvasList[6], 0, 0);
      }

      let fitAnalysis = document.getElementById('fitAnalysis').cloneNode(true);

      rootDiv.append(
        timeTitle,
        chart1,
        kcalTitle,
        chart2,
        breakDiv.cloneNode(true),
        ceTitle,
        chart3,
        balanceTitle,
        chart4,
        breakDiv.cloneNode(true),
        witsTitle,
        chart5,
        flexTitle,
        chart6,
        breakDiv.cloneNode(true),
        meTitle,
        chart7,
        analyzeTitle,
        fitAnalysis
     );

      this.$refs.pdfContents.appendChild(rootDiv);
      this.$refs.html2Pdf.generatePdf();

      this.pdfLoading = false;
      this.expandGraph(false);
    },

    /**
     * PDF 다운로드
     */
    async downloadToPdf() {
      if (this.pdfLoading) return;

      this.pdfLoading = true;
      this.pdfShowLayout = true;

      this.expandGraph(true);


      setTimeout(this.renderPdf, 500);

      // this.expandGraph(false);
    },

    createTitleDiv(titleName) {
      /*
      타이틀 DIV
      */
      let titleDiv = document.createElement('div');
      titleDiv.style.setProperty('background-color', '#089bab9a');
      titleDiv.style.setProperty('color', '#ffffff');
      titleDiv.style.setProperty('height', '50px');
      titleDiv.style.setProperty('border-radius', '10px');
      // titleDiv.style.setProperty('font-size', '1em');
      titleDiv.style.setProperty('font-family', 'nanumsquareR');
      titleDiv.style.setProperty('margin', '20px');
      titleDiv.style.setProperty('padding', '10px 0 0 20px');

      let h4 = document.createElement('h4');
      h4.className = 'card-title';
      h4.textContent = titleName;
      titleDiv.append(h4);

      return titleDiv;
    },

    printOptions(type = 'before') {
      const chartDiv = document.getElementById('line-chart');
      try {
        const svg = chartDiv.querySelector('svg');
        if (svg) {
          svg.setAttribute('width', chartDiv.clientWidth);
          svg.setAttribute('height', chartDiv.clientHeight);
        }
        return true;
      } catch {
        return false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.search-row-div {
  @media (min-width: 1000px) and (max-width: 1250px) {
    .row > div {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

</style>
