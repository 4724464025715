import Vue from 'vue';
import moment from 'moment';
export const SECOND = 1000;
export const HOUR = 12;

Vue.filter('reverse', function (value) {
  // slice to make a copy of array, then reverse the copy
  return value.slice().reverse()
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).fromNow()
  }
});

export function getHourTime (h) {
  return h >= 12 ? 'PM' : 'AM'
}

export function getZeroPad (n) {
  return (parseInt(n, 10) >= 10 ? '' : '0') + n
}
