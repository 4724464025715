<template>
  <div class="clock-root-div">
    <div class="date">
      <div class="date-year" v-text="years"></div>
      <div class="date-month" v-text="months"></div>
      <div class="date-day" v-text="days"></div>
    </div>

    <div class="clock" v-if="hourtime !== '' && showTime">
      <div class="clock-hours">
        <span class="clock-hourtime" v-text="hourtime"></span>
        <span v-text="hours"></span>
      </div>
      <div class="clock-minutes" v-text="minutes"></div>
      <div class="clock-seconds" v-text="seconds"></div>
    </div>
  </div>
</template>

<script>
import {SECOND, HOUR, getHourTime, getZeroPad} from '@/Utils/fliter';

export default {
  props: {
    showTime: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  data() {
    return {
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      hourtime: '',
    };
  },
  mounted() {
    this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
  },
  beforeDestroy() {
    window.clearTimeout(this.$options.timer);
  },
  methods: {
    updateDateTime() {
      const now = new Date();

      this.years = now.getFullYear();
      this.months = getZeroPad(now.getMonth() + 1);
      this.days = getZeroPad(now.getDate());

      this.hours = now.getHours();
      this.minutes = getZeroPad(now.getMinutes());
      this.seconds = getZeroPad(now.getSeconds());
      this.hourtime = getHourTime(this.hours);
      this.hours = getZeroPad(this.hours % HOUR || HOUR);
      this.$options.timer = window.setTimeout(this.updateDateTime, SECOND);
    },
  },
};
</script>

<style scoped lang="scss">
.clock-root-div {
  @media (max-width: 1120px) {
    .date {
      border: 0.1rem solid #fff;
      border-radius: 0.5rem;
      margin-bottom: 0.6em;
      margin-right: 0.2em;

      &-year,
      &-month,
      &-day {
        font-size: 1rem;
        font-weight: normal;
        padding: 0.3rem 0.7rem;
      }
    }
  }
}

.date {
  background: #fff;
  border: 0.2rem solid #fff;
  border-radius: 0.5rem;
  display: inline-block;
  margin-bottom: 1em;
  margin-right: 0.5em;

  &-year,
  &-month,
  &-day {
    //background: linear-gradient(to bottom, #26303b 50%, #2c3540 50%);
    background: linear-gradient(to bottom, #17a2b8 50%, #17a2b8 50%);
    display: inline-block;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 0.5rem 1rem;
    text-align: center;
    position: relative;
  }

  &-year {
    border-right: 0.15rem solid #fff;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &-month {
    border-right: 0.15rem solid #fff;
  }

  &-day {
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.clock {
  background: #fff;
  border: 0.2rem solid #fff;
  border-radius: 0.5rem;
  display: inline-block;
  margin-bottom: 1em;

  &-hours,
  &-minutes,
  &-seconds {
    background: linear-gradient(to bottom, #17a2b8 50%, #17a2b8 50%);
    display: inline-block;
    color: #fff;
    font-family: 'Nunito', sans-serif;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 0.5rem 1rem;
    text-align: center;
    position: relative;
  }

  &-hours {
    border-right: 0.15rem solid #fff;
    border-radius: 0.5rem 0 0 0.5rem;
  }

  &-minutes {
    border-right: 0.15rem solid #fff;
  }

  &-seconds {
    border-radius: 0 0.5rem 0.5rem 0;
    width: 60px;
  }

  &-hourtime {
    font-size: 0.7rem;
    position: absolute;
    top: 2px;
    left: 8px;
  }

}
</style>
