<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4">
        <iq-card class-name="iq-user-profile-block1">
          <template v-slot:body>
            <div class="user-details-block">
              <div class="user-profile text-center">
                <div style="width: 150px; height: 150px; margin: 0 auto;">
                  <img :src="customerInfo.logoUrl" alt="profile-img" class="avatar-round img-fluid" style="border: 1px solid #999999; object-fit: fill;">
                </div>
              </div>
              <div class="text-center mt-3">
                <h4><b>{{customerInfo.name}}</b></h4>
                <p>{{customerInfo.accountName}}</p>
              </div>
              <hr>
              <ul class="doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0">
                <li class="text-center">
                  <h6 class="text-primary">총 회원수</h6>
                  <h3>{{customerInfo.totalUserCount}}<span>명</span></h3>
                </li>
                <li class="text-center">
                  <h6 class="text-primary">어제 운동한 회원 수</h6>
                  <h3>{{customerInfo.fitCountByYesterday}}<span>명</span></h3>
                </li>
              </ul>
            </div>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="8">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">일주일 간 회원 운동 현황</h4>
          </template>
          <template v-slot:body class="iq-card-body">
            <div class="canvas">
              <MultiLineChart
                v-if="loaded"
                :chart-data="userFitStatusData"
                :chart-labels="generateChartLabel(7)"
                :title="'운동한 회원 수'"
                :width="500"
                :height="130"
                :yLabel="'명'"
                :stepSize="2"
              ></MultiLineChart>
            </div>
          </template>
        </iq-card>
      </b-col>

    </b-row>

    <b-row>
      <b-col lg="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">운동기구별 누적 운동시간</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="4">
                <apexchart ref="mupt" height="400" :options="machineUsagePartialTotal" :series="machineUsagePartialTotal.series" />
              </b-col>
              <b-col lg="8">
                <apexchart ref="mup" height="400" :options="machineUsagePartial" :series="machineUsagePartial.series"/>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="8">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <!--  운동기구 별 운동시간 -->
          <template v-slot:headerTitle>
            <h4 class="card-title">운동기구 월별 사용시간 비교</h4>
          </template>
          <template v-slot:body>
            <div>
              <apexchart element="avgUsageMachineChart" height="400" ref="aumc" :options="avgUsageMachineOpt" :series="avgUsageMachineOpt.series"/>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">회원의 최근 내역</h4>
          </template>
          <template v-slot:body>
            <ul class="iq-timeline">
              <li v-for="(log, idx) in userRecentLogs" :key="idx">
                <div class="timeline-dots-fill" :class="log.colorClass"></div>
                <h6 class="float-left mb-2 text-dark">{{ log.category }}</h6>
                <small class="float-right mt-1">{{ log.date }}</small>
                <div class="d-inline-block w-100">
                  <p>{{ log.name + '님이 ' + log.action }}</p>
                </div>
              </li>
            </ul>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">운동기구별 평균 소모 칼로리</h4>
          </template>
          <template v-slot:body>

            <b-row>
              <b-col md="6" lg="2" v-for="item in deviceAvgKcalList" :key="item.id">
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="rounded" :body-class="item.bodyClass">
                  <template v-slot:body>
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-bicycle"></i></div>
                      <div class="text-right">
                        <h5 class="">{{ item.name }}</h5>
                        <h6 class="mb-0"><span class="counter">{{ item.kcal }}Kcal</span></h6>
                      </div>
                    </div>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>

      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
          <template v-slot:headerTitle>
            <h4 class="card-title">이번 달의 사용자 순위 ({{rankOrder}})</h4>
          </template>
          <template v-slot:headerAction>
            <div class="iq-card-header-toolbar d-flex align-items-center">
              <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <b-button pill variant="outline-dark" class="mb-0 mr-1" style="display: flex; align-items: center;"><i class="ri-arrow-drop-down-line"></i><div>정렬</div></b-button>
                </template>
                <b-dropdown-item href="#" @click="switchUserRank('TIME')">운동시간</b-dropdown-item>
                <b-dropdown-item href="#" @click="switchUserRank('KCAL')">칼로리</b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
          <template v-slot:body class="d-flex flex-wrap align-items-center p-0 slick-initialized slick-slider">
            <Slick :option="slickOptions" id="user-list-slide">
              <div class="doctor-list-item-inner rounded text-center" v-for="(user, idx) in userAwardList" :key="user.id">
                <div class="doctor-profile">
                  <img :src="user.img" class="img-fluid rounded-circle" alt="user-img">
                </div>
                <div class="doctor-detail mt-3">
                  <h4>{{user.name}}</h4>
                  <h6>{{ (idx + 1) + '위'}}</h6>
                </div>
                <hr>
                <div class="user-rank-description">
                  <p class="mb-0 text-center pl-2 pr-2">총 누적 운동시간 {{user.totalFitHour}} 분</p>
                  <p class="mb-0 text-center pl-2 pr-2">총 누적 소모 칼로리 {{user.totalUseKcal}} KCal</p>
                </div>
              </div>
            </Slick>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row v-if="showNoticeList">
      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
        <template v-slot:headerTitle>
          <h4 class="card-title">라온핏 공지사항</h4>
        </template>
        <template v-slot:body>
          <b-col sm="12">
            <div class="row">
              <b-col lg="4" v-for="(item, idx) in noticeList" :key="idx">
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" style="background-color: #8888881F">
                  <template v-slot:body class="iq-card-body">
                    <div style="display: flex; flex-direction: column;">
                      <div style="color: #089bab">{{ $moment(item.writeDateTime, 'YYYYMMDDHHmmss').format('YYYY-MM-DD')  }}</div>
                      <h4 class="mb-2">{{ item.title | longTitle }}</h4>
                      <p style="flex-basis: 80px;" class="contents-pre">{{ item.contents }}</p>
                      <a style="color: #089bab; align-self: flex-end;" @click="$bvModal.show('notice-detail-modal' + item.id)">더 보기 <i class="ri-arrow-right-s-line"></i></a>
                      <b-modal style="width: 600px;" :id="'notice-detail-modal' + item.id" centered scrollable :title="item.title" ok-title="닫기" ok-only>
                        <textarea class="my-4" cols="64" rows="20" v-html="item.contents" readonly disabled></textarea>
                      </b-modal>
                    </div>
                  </template>
                </iq-card>
              </b-col>
            </div>
          </b-col>
        </template>
      </iq-card>
    </b-row>
  </b-container>
</template>

<script>
import { xray } from '@/config/pluginInit'
import IqCard from '../../components/xray/cards/iq-card'
import MultiLineChart from '@/views/Raonfit/template/MultiLineChart'

import jwt from 'jsonwebtoken'
import _ from "lodash";
import {commons} from "@/Utils/Commons";

export default {
  name: 'Raonfit-Dashboard',
  components: { MultiLineChart, IqCard },
  mounted () {
    xray.index();
    const authToken = window.sessionStorage.getItem('Authorization');
    const adminInfo = commons.parseAuthTokenToAdminInfo(authToken);
    this.customerInfo.logoUrl = adminInfo.logoUrl ? adminInfo.logoUrl : process.env.VUE_APP_BASE_LOGO;
    this.customerInfo.customerName = adminInfo.customerName;
    this.customerInfo.siteName = adminInfo.siteName;
    this.getUserFitStatus();
    this.getDashboardData();
    this.getUsersRank();
    this.getUserRecentLogs();
    this.getRecentNoticeList();
  },
  data () {
    return {
      customerInfo: {
        name: '',
        accountName: '',
        totalUserCount: 0,
        fitCountByYesterday: 0,
        profileImage: '',
        logoUrl: '',
      },

      /*
      회원수 / 일주일 간 회원 운동 현황
       */
      userFitStatusData: [
        {
          label: '인원(명)',
          fill: true,
          backgroundColor: '#36A2EB3F',
          borderColor: '#36A2EB',
          // data: [],
          data: [],
          labels: ''
        }
      ],
      loaded: false,

      slickOptions: {
        centerMode: false,
        centerPadding: '10px',
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        responsive: [{
          breakpoint: 992,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30',
            slidesToShow: 3
          }
        }, {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '10',
            slidesToShow: 1
          }
        }],
        nextArrow: '',
        prevArrow: ''
      },

      rankOrder: '운동시간순',
      rankOrderType: 'TIME',
      userAwardList: [
        { name: '', img: '', totalFitHour: 0, totalUseKcal: 0 },
        { name: '', img: '', totalFitHour: 0, totalUseKcal: 0 },
        { name: '', img: '', totalFitHour: 0, totalUseKcal: 0 },
        { name: '', img: '', totalFitHour: 0, totalUseKcal: 0 },
        { name: '', img: '', totalFitHour: 0, totalUseKcal: 0 },
      ],


      avgUsageMachineOpt: {
        chart: {
          height: 400,
          type: 'bar',
          sparkline: {
            show: false

          },
          toolbar: {
            show: false
          }
        },
        colors: ['#089bab', '#fc9f5b'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: false,
          width: 5,
          colors: ['#ffffff']
        },
        xaxis: {
          categories: []
        },
        series: [{
          name: '지난달 평균 사용시간',
          data: []
        }, {
          name: '이번달 평균 사용시간',
          data: []
        }],
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + ' 분'
            }
          }
        }
      },


      machineUsagePartialTotal: {
        series: [{
          data: []
        }],
        chart: {
          type: 'bar',
          height: 400,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          },
        },
        title: {
          text: '주간 합계 운동시간'
        },
        colors: ['#C12592', '#0A71D5', '#5200AE', '#00AE8F', '#C8D65B', '#FFBB30'],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            distributed: true,
            dataLabels: {
              position: 'bottom',
            }
          }
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#FFFFFF']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ": " + val + '분';
          },
          offsetX: 0,
        },
        stroke: {
          width: 1,
          color: ['#fff'],
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function() {
                return '';
              }
            }
          }
        }
      },

      machineUsagePartial: {
        noData: {
          text: '데이터 로딩 중..'
        },
        series: [],
        colors: ['#C125929F', '#0A71D59F', '#5200AE9F', '#00AE8F9F', '#C8D65B9F', '#FFBB309F'],
        chart: {
          type: 'bar',
          height: 400,
          stacked: false,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
        },
        title: {
          text: '일별 누적 운동시간'
        },
        responsive: [{
          options: {
            legend: {
              position: 'bottom',
              offsetX: 10,
              offsetY: 0
            },
          }
        }],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
          }
        },
        tooltip: {
          enabled: true,
          x: {
            format: 'MM월 dd일'
          },
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              let total = 0;
              series.forEach(s => {
                total += s[dataPointIndex];
              });

              return value + '분 / 총 운동시간 ' + total + '분';
            },
          },
          style: {
            fontSize: '15px',
            fontFamily: 'NanumSquareR',
          },
        },
        xaxis: {
          // type: 'datetime',
          categories: this.generateChartLabel(),
          labels: {
            formatter: function (value) {
              const convert = new Date(value);
              let date = convert.getDate();
              let month = convert.getMonth() + 1;
              let fullYear = convert.getFullYear();
              return month + '월 ' + date + '일';
            }
          }
        },
        // legend: {
        //   position: 'right',
        //   offsetY: 40
        // },
        dataLabels: {
          enabled: false
        },
      },

      deviceAvgKcalList: [],
      userRecentLogs: [],

      showNoticeList: false,
      noticeList: [],
    }
  },
  methods: {
    generateData (count, yrange) {
      var i = 0
      var series = []
      while (i < count) {
        var x = Math.random() * (50 - 1 + 1) + 1
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
        var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15

        series.push([x, y, z])
        i++
      }

      return series
    },
    generateChartLabel(beforeDate = 7) {
      const dateArr = [];
      for (let i = 1; i <= beforeDate; i++) {
        dateArr.push(this.$moment().subtract(beforeDate - i, 'days').format('YYYY-MM-DD'));
      }
      return dateArr;
    },
    generateChartData(min, max, size) {
      const dataArr = [];

      for (let i = 0; i < size; i++) {
        const randomNumber = Math.floor(Math.random() * max) + min;
        dataArr.push(randomNumber);
      }

      return dataArr;
    },

    switchUserRank(type) {
      let array = [];
      this.rankOrderType = type;

      if (type === 'KCAL') {
        // array = _.sortBy(this.userAwardList, ['totalUseKcal', 'totalFitHour']).reverse();
        this.rankOrder = '소모 칼로리순';
      } else if (type === 'TIME') {
        // array = _.sortBy(this.userAwardList, ['totalFitHour', 'totalUseKcal']).reverse();
        this.rankOrder = '운동시간순';
      }

      this.getUsersRank();

      // this.userAwardList = array;
    },

    /**
     * API
     */
    getUserFitStatus() {
      const start = this.$moment().subtract(6, 'days').format('YYYYMMDD');
      const end = this.$moment().format('YYYYMMDD');
      const yesterday = this.$moment().subtract(1, 'days').format('YYYYMMDD');

      this.$axios.get(`/fitData/members/movement/status/${start}/${end}`)
        .then(result => {
          let labelArr = [];
          let dataArr = [];
          const responseData = result.data;

          this.loaded = true;

          responseData.userMovementData.forEach(d => {
            const formatDate = d.latestPlayDate.substring(0, 4)
                                .concat('-')
                                .concat(d.latestPlayDate.substring(4, 6))
                                .concat('-')
                                .concat(d.latestPlayDate.substring(6));

            labelArr.push(formatDate);
            dataArr.push(d.count);

            if (d.latestPlayDate === yesterday) {
              this.customerInfo.fitCountByYesterday = d.count;
            }
          });

          this.userFitStatusData[0].data = dataArr;
          this.customerInfo.totalUserCount = responseData.totalUserCount;

        })
        .catch(error => {
          console.log('get UserFitStatus ', error);
        });
    },

    getDashboardData() {
      const today = this.$moment().format('YYYYMMDD');
      this.$axios.get(`/fitData/dashboard/${today}`)
      .then(result => {
        const data = result.data;

        // 주간 합계 운동시간
        let weekTotalUsages = data.weekTotalUsages;
        const dataArr1 = [];
        const categoryArr1 = [];

        weekTotalUsages.forEach(wtu => {
          categoryArr1.push(wtu.machineName);
          dataArr1.push(wtu.totalUsage);
        });


        this.$refs.mupt.updateOptions({
          series: [
            {
              data: dataArr1
            }
          ],
          xaxis: {
            categories: categoryArr1,
          },
        });

        // 일별 누적 운동시간
        let weekUsagePerDay = data.weekUsagePerDay;
        let keyDates = Object.keys(weekUsagePerDay);
        const weekUsagePerDayArr = [];
        data.machineNames.forEach(m => {
          weekUsagePerDayArr.push({
            name: m,
            data: [],
          });
        })

        keyDates.forEach(date => {
          const arr = weekUsagePerDay[date];

          arr.forEach(a => {
            weekUsagePerDayArr.forEach(w => {
              if (a.machineName === w.name) {
                w.data.push(a.usage);
              }
            })
          })
        });
        this.$refs.mup.updateSeries(weekUsagePerDayArr);

        // 운동기구 월별 사용시간 비교
        const monthUsageCategoryArr = [];
        const lastMonthUsageSeriesArr = [];
        const thisMonthUsageSeriesArr = [];
        if (data.monthUsages && data.monthUsages.length > 0) {
          data.monthUsages.forEach(u => {
            monthUsageCategoryArr.push(u.deviceName);
            lastMonthUsageSeriesArr.push(u.lastMonthUsage);
            thisMonthUsageSeriesArr.push(u.thisMonthUsage);
          })
        }


        this.$refs.aumc.updateOptions({
          series: [
            {
              name: '지난달 평균 사용시간',
              data: lastMonthUsageSeriesArr,
            }, {
              name: '이번달 평균 사용시간',
              data: thisMonthUsageSeriesArr,
            }
          ],
          xaxis: {
            categories: monthUsageCategoryArr,
          },
        });

        // 운동기구별 평균 소모 칼로리
        const deviceAvgKcalArr = [];
        const bodyClass = ['iq-bg-primary rounded', 'iq-bg-secondary rounded', 'iq-bg-info rounded'];
        data.deviceAvgKcalList.forEach((item, idx) => {
          deviceAvgKcalArr.push({
            id: item.deviceCategoryId,
            name: item.deviceName,
            kcal: item.avgKcal,
            bodyClass: bodyClass[(idx + 1) % 3],
          })
        });

        this.deviceAvgKcalList = deviceAvgKcalArr;


      })
      .catch(error => {

      })

    },


    getUsersRank() {
      this.$axios.get(`/fitData/users/rank/${this.rankOrderType}`)
      .then(result => {

        if (result.data && result.data.length > 0) {
          const rankList = result.data;
          /* Template Image */
          const imgArr = [require('@/assets/images/user/15.jpg'),
            require('@/assets/images/user/16.jpg'),
            require('@/assets/images/user/14.jpg'),
            require('@/assets/images/user/06.jpg'),
            require('@/assets/images/user/09.jpg')];

          const arr = [];
          rankList.forEach((r, idx) => {
            arr.push({
              name: r.userName,
              img: imgArr[idx],
              totalFitHour: r.sumExerciseTime,
              totalUseKcal: r.sumKcal,
            });
          })

          this.userAwardList = arr;
        }
      })
      .catch(error => {
        console.log(error);
      })
    },

    getUserRecentLogs() {
      this.$axios.get('/user/recent/logs')
      .then(result => {
        const data = result.data;
        if (data.length > 0) {
          data.forEach((item, idx) => {
            let action = '';
            let category = '';
            if (item.logType === 'SIGNED') {
              category = '회원가입';
              action = '신규 가입하였습니다';
            } else if (item.logType === 'INBODY') {
              category = '인바디 측정';
              action = '인바디를 측정하였습니다';
            } else if (item.logType === 'EXERCISE') {
              category = '운동'
              action = item.action + '를 이용하였습니다';
            }

            const colorClass = ['bg-danger', 'bg-success', 'bg-warning'];
            const l = {
              date: this.$moment(item.logDate, 'YYYYMMDDHHmmss').add(9, 'h').format('YYYY-MM-DD HH:mm:ss'),
              name: item.userName,
              category: category,
              action: action,
              colorClass: colorClass[(idx + 1) % 3]
            };

            this.userRecentLogs.push(l);
          })

        }
      })
    },

    getRecentNoticeList() {
      this.$axios.get('/notice/recent/list')
      .then(result => {
        const data = result.data;
        if (data.total > 0) {
          this.showNoticeList = true;
          this.noticeList = data.noticeList;
        }
      })
      .catch(error => {
        console.log(error);
      });
    },
  },
  filters: {
    longText: function(str) {
      const maxLength = 180;
      if (str.length < maxLength) {
        return str;
      } else {
        return str.substring(0, maxLength) + "...";
      }
    },
    longTitle: function(str) {
      const maxLength = 21;
      if (str.length < maxLength) {
        return str;
      } else {
        return str.substring(0, maxLength) + "...";
      }
    }
  }
}
</script>

<style scoped lang="scss">
.user-rank-description {
  color: black;
}

.contents-pre {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

}
</style>
