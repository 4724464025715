<template>
  <b-container fluid>
    <iq-card class-name="iq-card-block iq-card-height">
      <template v-slot:headerTitle>
        <h4 class="card-title">공지사항 {{ noticeInfo.newNotice ? '추가' : '수정' }}</h4>
      </template>

      <template v-slot:body>
        <div class="form-box">
          <b-form>
            <b-row style="display: flex; align-items: center;">
              <b-col lg="1">
                <div class="detail-form-label-div">
                  <label for="titleValid" class="detail-form-label">제목</label>
                </div>
              </b-col>
              <b-col lg="7">
                <b-form-input id="titleValid" v-model.trim="noticeInfo.title" :state="titleValidation"
                              required></b-form-input>
                <b-form-invalid-feedback id="titleValid" :state="titleValidation">공지사항 제목은 50자 이내로 반드시 입력해야 합니다
                </b-form-invalid-feedback>
                <b-form-valid-feedback id="titleValid" :state="titleValidation"></b-form-valid-feedback>
              </b-col>

              <b-col lg="2">
                <div class="detail-form-label-div">
                  <label class="detail-form-label">게시여부</label>
                </div>
              </b-col>
              <b-col lg="2">
                <b-form-select
                  v-model="noticeInfo.enabled"
                  :options="enableOptions"
                  id="selectEnabled"
                ></b-form-select>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="1">
                <div class="detail-form-label-div">
                  <label class="detail-form-label">공지내용</label>
                </div>
              </b-col>
              <b-col lg="11">
                <b-form-textarea
                  id="textarea-rows"
                  placeholder="공지사항의 내용을 입력하세요."
                  rows="8"
                  v-model="noticeInfo.contents"
                ></b-form-textarea>
                <!--
                <div class="editor">
                  <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
                    <div class="menubar">
                      <button class="menubar__button" @click.prevent="showLinkMenu(getMarkAttrs('link'))"
                              :class="{ 'is-active': isActive.link() }">
                        <i class="fas fa-link"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.bold() }"
                              @click.prevent="commands.bold">
                        <i class="fas fa-bold"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.italic() }"
                              @click.prevent="commands.italic">
                        <i class="fas fa-italic"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.strike() }"
                              @click.prevent="commands.strike">
                        <i class="fas fa-strikethrough"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.underline() }"
                              @click.prevent="commands.underline">
                        <i class="fas fa-underline"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.code() }"
                              @click.prevent="commands.code">
                        <i class="fas fa-code"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.code_block() }"
                              @click.prevent="commands.code_block">
                        <i class="fas fa-file-code"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }"
                              @click.prevent="commands.paragraph">
                        <i class="fas fa-paragraph"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                              @click.prevent="commands.heading({ level: 1 })"> H1
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                              @click.prevent="commands.heading({ level: 2 })"> H2
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                              @click.prevent="commands.heading({ level: 3 })"> H3
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }"
                              @click.prevent="commands.bullet_list">
                        <i class="fas fa-list-ul"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }"
                              @click.prevent="commands.ordered_list">
                        <i class="fas fa-list-ol"/>
                      </button>
                      <button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }"
                              @click.prevent="commands.blockquote">
                        <i class="fas fa-quote-left"/>
                      </button>
                      <button class="menubar__button" @click.prevent="commands.horizontal_rule">
                        <i class="fas fa-window-minimize"/>
                      </button>
                      <button class="menubar__button" @click.prevent="commands.undo">
                        <i class="fas fa-undo"/>
                      </button>
                      <button class="menubar__button" @click.prevent="commands.redo">
                        <i class="fas fa-redo"/>
                      </button>
                    </div>
                  </editor-menu-bar>
                  <editor-content class="editor__content" :editor="editor"/>
                </div>
                -->
              </b-col>
            </b-row>

            <hr>

            <div style="display: flex; justify-content: flex-end;">
              <b-button variant="primary" class="button-style" @click="upsertNotice()">저장</b-button>
              <b-button variant="none" class="iq-bg-danger ml-3 button-style" @click="goToNoticeList()">취소</b-button>
            </div>
          </b-form>
        </div>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
// import {Editor, EditorContent, EditorMenuBar} from 'tiptap'
// import {
//   Blockquote,
//   CodeBlock,
//   HardBreak,
//   Heading,
//   HorizontalRule,
//   OrderedList,
//   BulletList,
//   ListItem,
//   TodoItem,
//   TodoList,
//   Bold,
//   Code,
//   Italic,
//   Link,
//   Strike,
//   Underline,
//   History
// } from "tiptap-extensions"

import Swal from "sweetalert2";

export default {
  name: "NoticeDetail",
  components: {
    // EditorMenuBar,
    // EditorContent,
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      const params = this.$route.params;
      this.noticeInfo = { newNotice: false, ...params };
    }
  },
  mounted() {
    // this.editor = new Editor({
    //   editable: true,
    //   extensions: [new Blockquote(), new BulletList(), new CodeBlock(), new HardBreak(), new Heading({levels: [1, 2, 3]}), new Link({
    //     rel: "",
    //     target: "_blank"
    //   }), new HorizontalRule(), new ListItem(), new OrderedList(), new TodoItem(), new TodoList(), new Bold(), new Code(), new Italic(), new Strike(), new Underline(), new History()],
    //   content: this.description,
    //   onUpdate: ({getHTML}) => {
    //     console.log('onUpdate ', getHTML());
    //   }
    // })
  },
  data() {
    return {
      editor: null,
      noticeInfo: {
        newNotice: true,
        id: '',
        title: '',
        enabled: true,
        contents: '',
      },

      description: null,
      linkUrl: null,
      linkMenuIsActive: false,

      enableOptions: [{text: '게시', value: true}, {text: '게시안함', value: false}],
    }
  },
  computed: {
    titleValidation() {
      if (this.noticeInfo.title) {
        return this.noticeInfo.title.length > 1;
      } else {
        return false;
      }
    },
  },
  methods: {
    showLinkMenu(attr) {
      this.linkUrl = attr.href;
    },

    goToNoticeList() {
      this.$router.push({name: 'raonfit.super.noticeList'});
    },

    upsertNotice() {
      if (!this.noticeInfo.title || !this.noticeInfo.contents) {
        Swal.fire({
          text: '모든 필드를 입력해야 합니다',
          icon: 'warning',
          confirmButtonColor: '#089BAB',
          confirmButtonText: '확인',
        }).then(result => {

        });

        return;
      }

      Swal.fire({
        text: '공지사항을 저장합니다',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#089BAB',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: '저장',
        cancelButtonText: '취소',
      }).then(result => {
        if (result.value) {
          this.$axios.put('/notice/upsert', this.noticeInfo)
            .then(result => {
              Swal.fire('', '공지사항을 저장하였습니다', 'success')
                .then(t => {
                  this.goToNoticeList();
                })
                .catch(c => {
                  this.goToNoticeList();
                });
            })
            .catch(error => {
              console.log(error.response, error.request, error.message);
              let errorText = '';
              if (error.message === '4000') {
                errorText = '정보를 정확히 입력해주세요';
              } else if (error.message === '4008') {
                errorText = '중복된 ID입니다. 다른 ID로 등록해주세요.';
              } else {
                errorText = '일시적인 오류가 발생했습니다. 잠시 후 다시 시도해 주세요';
              }

              Swal.fire('사용자 정보 저장 오류', errorText, 'error');
            });
        }
      })
    },
  },
  beforeDestroy() {
    // this.editor.destroy();
  },
}
</script>

<style scoped lang="scss">
$black: #111111;
$white: #FFFFFF;
$gray-400: #666666;

.editor {
  position: relative;
  // max-width: 30 rem;
  margin: 0 auto 5rem auto;

  .menubar {
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding: 0.2rem 0;
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

    &
    .is-hidden {
      visibility: hidden;
      opacity: 0;
    }

    &
    .is-focused {
      visibility: visible;
      opacity: 1;
      transition: visibility 0.2s, opacity 0.2s;
    }

    &
    __button {
      font-weight: bold;
      display: inline-flex;
      background: transparent;
      border: 0;
      color: $black;
      padding: 0.2rem 0.5rem;
      margin-right: 0.2rem;
      border-radius: 3px;
      cursor: pointer;

      &
      :hover {
        background-color: rgba($black, 0.05);
      }

      &
      .is-active {
        background-color: rgba($black, 0.1);
      }

    }

    span #{&} __button {
      font-size: 13.3333px;
    }

  }

  .editor__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    .ProseMirror {
      min-height: 10rem;
      padding: 0.5rem;

      &
      :focus {
        outline: none;
      }

      pre {
        padding: 0.7rem 1rem;
        border-radius: 5px;
        background: $black;
        color: $white;
        font-size: 0.8rem;
        overflow-x: auto;

        code {
          display: block;
        }

      }

      p code {
        padding: 0.2rem 0.4rem;
        border-radius: 5px;
        font-size: 0.8rem;
        font-weight: bold;
        background: rgba($black, 0.1);
        color: rgba($black, 0.8);
      }

      ul, ol {
        padding-left: 1rem;
      }

      li > p, li > ol, li > ul {
        margin: 0;
      }

      a:not(.btn) {
        color: #888888;
        text-decoration: underline;
      }

      blockquote {
        border-left: 3px solid rgba($black, 0.1);
        color: rgba($black, 0.8);
        padding-left: 0.8rem;
        font-style: italic;

        p {
          margin: 0;
        }

      }

      img {
        max-width: 100%;
        border-radius: 3px;
      }

      table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        overflow: hidden;

        td, th {
          min-width: 1em;
          border: 2px solid $gray-400;
          padding: 3px 5px;
          vertical-align: top;
          box-sizing: border-box;
          position: relative;

          > * {
            margin-bottom: 0;
          }

        }

        th {
          font-weight: bold;
          text-align: left;
        }

        .selectedCell:after {
          z-index: 2;
          position: absolute;
          content: "";
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: rgba(200, 200, 255, 0.4);
          pointer-events: none;
        }

        .column-resize-handle {
          position: absolute;
          right: -2px;
          top: 0;
          bottom: 0;
          width: 4px;
          z-index: 20;
          background-color: #adf;
          pointer-events: none;
        }

      }

      .tableWrapper {
        margin: 1em 0;
        overflow-x: auto;
      }

      .resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
      }

    }
  }
}

.button-style {
  width: 5vw;
  height: 5vh;
}
</style>
