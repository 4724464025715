<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar" style=" border-radius: 25px;">
    <div class="iq-navbar-custom" :class="horizontal ? 'd-flex align-items-center justify-content-between' : ''">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="homeURL">
            <img :src="logo" class="img-fluid" alt="logo">
          </router-link>
        </div>
      </div>
      <div class="iq-menu-horizontal" v-if="horizontal">
        <div class="iq-sidebar-menu">
          <List :items="items" class="d-flex"></List>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0" style="background-color: #17a2b84f; border-radius: 25px;">
        <div class="clock-div">
          <Clock :showTime="showTime"/>
        </div>
        <div v-if="!showTime" style="margin-left: 17px;">
          <span class="message-style">
            {{ getMessage() }}
          </span>
        </div>
<!--        <b-navbar-toggle target="nav-collapse">-->
<!--          <i class="ri-menu-3-line"></i>-->
<!--        </b-navbar-toggle>-->
        <div class="iq-menu-bt align-self-center" style="top: 10px; left: 20px;">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-more-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
          </div>
        </div>
        <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight"/>
        </b-collapse>
        <slot name="right"/>
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import List from '../menus/ListStyle1'
import SideBarItems from '../../../FackApi/json/SideBar'
import Clock from "@/views/Raonfit/template/Clock";

export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: {type: Object, default: () => ({name: 'raonfit.dashboard'})},
    title: {type: String, default: 'Dashboard'},
    logo: {type: String, default: require('../../../assets/images/logo.png')},
    horizontal: {type: Boolean, default: false},
    items: {type: Array}
  },
  created() {
    const adminState = this.$store.getters['Auth/adminState'];
    this.daysOfJoining = adminState.daysOfJoining;
    if (adminState.role === 'SUPER') {
      this.showTime = true;
    } else {
      this.showTime = false;
    }
  },
  mounted() {
    this.updateClock();
  },
  components: {
    List,
    Clock,
  },
  computed: {},
  data() {
    return {
      date: '',
      time: '',
      clockTimeout: null,
      sidebar: SideBarItems,
      globalSearch: '',
      showSearch: false,
      showMenu: false,
      topMessage: '라온핏에 가입한 지 {}일째입니다',
      daysOfJoining: 0,
      showTime: true,
    }
  },
  methods: {
    getMessage() {
      return this.topMessage.replaceAll('{}', this.daysOfJoining);
    },
    miniSidebar() {
      this.$emit('toggle')
    },
    updateClock() {
      clearTimeout(this.clockTimeout);
      this.updateTime();
      this.clockTimeout = setTimeout(this.updateClock, 1000);
    },
    updateTime() {
      let cd = new Date();
      this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
      this.date = this.zeroPadding(cd.getFullYear(), 4) + '년 ' + this.zeroPadding(cd.getMonth() + 1, 2) + '월 ' + this.zeroPadding(cd.getDate(), 2) + '일';
    },
    zeroPadding(num, digit) {
      let zero = '';
      for (let i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
  }
}
</script>

<style scoped lang="scss">
#clock {
  font-family: nanumsquareR, sans-serif;
  padding-top: 10px;
  width: 300px;
  color: #666666;
  margin-left: 20px;

  .date {
    font-size: 1.5em;
  }
}

.clock-div {
  @media (max-width: 999px) {
    margin: 10px 0 0 80px;
  }

  @media (min-width: 1000px) {
    margin: 10px 0 0 20px;
  }
}

.message-style {
  @media (max-width: 1120px) {
    font-size: 15px;
  }

  font-size: 20px;
  color: #089bab;
  font-weight: bold;
  font-family: 'nanumsquareR', serif;
}
</style>
