import { render, staticRenderFns } from "./CustomerDetail.vue?vue&type=template&id=94487836&scoped=true&"
import script from "./CustomerDetail.vue?vue&type=script&lang=js&"
export * from "./CustomerDetail.vue?vue&type=script&lang=js&"
import style0 from "./CustomerDetail.vue?vue&type=style&index=0&id=94487836&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94487836",
  null
  
)

export default component.exports